var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"b-select-checkbox",staticClass:"dropdown noselect",class:[{ disabled: _vm.disabled }, _vm.className],staticStyle:{"margin-right":"20px"},attrs:{"tabindex":"1"}},[_c('div',{staticClass:"select",class:[
      _vm.showDropdown ? 'open' : '',
      _vm.selectedVal,
      _vm.overrideClass,
      _vm.capitalizeText ? '' : 'no-capitalize',
    ],attrs:{"id":_vm.componentId},on:{"click":function($event){_vm.showDropdown = !_vm.showDropdown}}},[_c('div',{staticClass:"selected-opt-wrapper tooltip"},[_c('span',{staticClass:"selected-opt-header"},[_vm._v(_vm._s(_vm.header ? `${_vm.header}: ` : ''))]),(_vm.selectedTextIcon)?_c('font-awesome-icon',{staticClass:"selected-icon",attrs:{"icon":[_vm.selectedTextIconStyle, _vm.selectedTextIcon],"size":"1x"}}):_vm._e(),_c('span',{staticClass:"selected-opt"},[_vm._v(_vm._s(_vm.selectedVal))]),(_vm.showToolTip)?_c('span',{staticClass:"tooltiptext"},[_vm._v(_vm._s(_vm.selectedVal))]):_vm._e()],1),_c('font-awesome-icon',{staticClass:"droparw",attrs:{"icon":['far', _vm.showDropdown ? 'chevron-up' : 'chevron-down']}})],1),(_vm.showDropdown)?_c('ul',{staticClass:"dropdown-menu"},_vm._l((_vm.options),function(option,i){return _c('li',{key:i,class:[option.showStar ? 'rollup-option' : 'norm-option', option.name || option],on:{"click":function($event){_vm.$emit('change', option.id || option, option.name || option);
        _vm.showDropdown = false;}}},[(option.showStar)?_c('div',{staticClass:"roll-preset"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'star']}})],1):_vm._e(),(option.icon)?_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":[option.iconStyle || 'far', option.icon],"size":"1x"}}):_vm._e(),_vm._v(" "+_vm._s(option.name || option)+" "),(option.infoText)?_c('span',{staticClass:"intip4",attrs:{"data-tool-tip":option.infoText}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'info-circle']}})],1):_vm._e(),(option.children && option.children.length)?_c('div',_vm._l((option.children),function(child,ci){return _c('span',{key:ci,staticClass:"psub"},[_vm._v(_vm._s(child))])}),0):_vm._e()],1)}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }