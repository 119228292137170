<template>
  <div class="bdc_main_wrap whero">
    <div id="performanceAnalysis"></div>
    <div class="bdc_panel full-width expan def-box" :class="isCampaignTableExpanded ? 'expd' : ''">
      <report-builder
        section-title="Table View"
        :dimension-options="rptDimensionOptions"
        :metric-options="rptMetricOptions"
        :meta-data="rptMetaData"
        :rows="rptRows"
        :loading="rptLoading || filtersAppliedLoading"
        :is-table-expanded="isCampaignTableExpanded"
        @on-dimension-change="handleReportDimensionChange"
        @on-metrics-change="handleReportMetricsChange"
        @on-table-expand="handleTableExpand"
        @on-download="handleDownloadClick"
      />
    </div>
  </div>
</template>
<script>
import _ from 'underscore';
import { get } from 'vuex-pathify';
import { uuid } from '@/helpers/global/misc-helpers';
import { getCorrectLabels, downloadCSVFile } from '@/util/utility-functions';
import {
  ACCOUNT_SPECIFIC_REPORT_DIMENSION_TITLES,
  REPORT_DIMENSION_OPTIONS,
  REPORT_METRIC_OPTIONS,
  REPORT_DIMENSION_COLUMN,
  REPORT_DIMENSION_TITLES,
  ACCOUNT_SPECIFIC_REPORT_DIMENSION_OPTIONS,
} from '@/search-constant';
import { VIA_ACCOUNT_ID } from '@/constant';
import ReportBuilder from '~/components/report-builder.vue';

import appConsoleDataLayer from '~/api/app-console-data';
import { formatDateForAPI } from '~/util/apiDateFormat';

export default {
  components: {
    ReportBuilder,
  },
  props: {
    activeSolutionId: {
      type: Number,
      required: false,
      default: 0,
    },
    isCampaignTableExpanded: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const initialDimension = 'overall';
    const rptDimensionOptions = REPORT_DIMENSION_OPTIONS;
    if (initialDimension) {
      rptDimensionOptions.forEach((o) => {
        if (o.id === 'zipcode') {
          o.id = 'zip';
        }
        o.checked = o.id === initialDimension;
      });
    }

    const rptMetricOptions = [...REPORT_METRIC_OPTIONS];
    rptMetricOptions.forEach((o) => {
      o.id = o.key;
      o.checked = true;
    });

    return {
      rptDimensionOptions,
      rptMetricOptions,
      selectedDimension: initialDimension,
      selectedMetrics: [],
      isAllMetricsSelected: true,
      rptRequestId: '',
      rptMetaData: [],
      rptRows: [],
      rptLoading: false,
      oldPayload: null,
      isMounted: false,
    };
  },
  computed: {
    account: get('common/account'),
    advertiser: get('common/advertiser'),
    dates: get('dashboard/dates'),
    selectedCampaignIds: get('dashboard/GET_SELECTED_CAMPAIGN_IDS'),
    selectedAdGrpOptStr: get('dashboard/GET_SELECTED_AD_GROUP_STR'),
    selectedMediaTypes: get('dashboard/GET_SELECTED_MEDIATYPES'),
    selectedCreatives: get('dashboard/GET_SELECTED_CREATIVES'),
    selectedAudiences: get('dashboard/GET_SELECTED_AUDIENCES'),
    campaignOptions: get('dashboard/filters@campaignOptions'),
    adGroupOptions: get('dashboard/filters@adGroupOptions'),
    filtersApplied: get('dashboard/filtersApplied'),
    filtersAppliedLoading: get('dashboard/filtersAppliedLoading'),

    advertiserId() {
      return this.advertiser ? this.advertiser.id : 0;
    },
    payload() {
      return {
        advertiser: this.advertiser ? this.advertiser.name : '',
        client: this.account ? this.account.name : '',
        campaignIds: this.selectedCampaignIds,
        adGroups: this.selectedAdGrpOptStr,
        startDate: formatDateForAPI(this.dates.startDate),
        endDate: formatDateForAPI(this.dates.endDate),
        mediaTypes: this.selectedMediaTypes,
        audiences: this.selectedAudiences,
        creatives: this.selectedCreatives,
      };
    },
  },
  watch: {
    advertiser: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          if (!this.isMounted) {
            return;
          }
          await this.loadAllData();
        }
      },
    },

    dates: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          if (!this.filtersAppliedLoading) {
            if (!this.isMounted) {
              return;
            }
            await this.loadAllData();
          }
        }
      },
    },
    filtersApplied: {
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          if (!this.isMounted) {
            return;
          }
          await this.loadAllData();
          this.$store.set('dashboard/filtersApplied', false);
          this.$store.set('dashboard/filtersAppliedLoading', false);
        }
      },
    },
  },
  beforeMount() {
    this.rptDimensionOptions = getCorrectLabels(
      REPORT_DIMENSION_OPTIONS,
      ACCOUNT_SPECIFIC_REPORT_DIMENSION_OPTIONS[this.account.id]
    );
    if (this.account.id === VIA_ACCOUNT_ID) {
      this.rptMetricOptions = this.rptMetricOptions.filter(
        (option) => option.id !== 'spend' && option.id !== 'cpc'
      );
    }
  },
  async mounted() {
    this.$store.set('dashboard/selectedDashboardTab', 'report');
    if (_.isEqual(this.payload, this.oldPayload)) {
      return;
    }
    await this.loadAllData();
    this.isMounted = true;
  },
  methods: {
    handleDownloadClick(fileName, csvData) {
      try {
        downloadCSVFile(csvData, fileName);
      } catch (err) {
        console.error(`error downloading report ${fileName} file ->`, err);
        throw err;
      }
    },
    async loadAllData() {
      if (!this.advertiser) {
        return;
      }
      if (_.isEqual(this.payload, this.oldPayload)) {
        return;
      }
      const apiFuncs = [this.loadPerformanceData()];
      await Promise.all(apiFuncs);
      this.$store.set('dashboard/filtersAppliedLoading', false);
      this.oldPayload = this.payload;
    },
    handleTableExpand(val) {
      this.$emit('on-expand-campaign-table', val);
    },
    async loadPerformanceData() {
      this.rptRequestId = uuid();
      this.rptLoading = true;
      this.rptMetaData = this.fetchReportMetaData(this.selectedDimension);
      const response = await appConsoleDataLayer.fetchSearchDimensionReport(
        this.rptRequestId,
        this.advertiserId,
        this.activeSolutionId,
        this.selectedDimension,
        this.payload
      );
      if (this.rptRequestId === response.requestId) {
        this.rptRows = response.data;
        this.rptLoading = false;
        return;
      }
      setTimeout(() => {
        this.rptLoading = false;
      }, 200);
    },
    fetchReportMetaData(dimension) {
      let list = [REPORT_DIMENSION_COLUMN];
      list[0].value =
        getCorrectLabels(
          REPORT_DIMENSION_TITLES,
          ACCOUNT_SPECIFIC_REPORT_DIMENSION_TITLES[this.account.id]
        )[dimension] || 'Name';
      list[0].formatter = {};
      if (dimension === 'day') {
        list[0].formatter = { type: 'datetime', format: 'M/D/YYYY' };
      }
      list.push(...REPORT_METRIC_OPTIONS);

      if (this.account.id === VIA_ACCOUNT_ID) {
        list = list.filter((option) => option.key !== 'spend' && option.key !== 'cpc');
      }
      if (this.isAllMetricsSelected) {
        return list;
      }

      const selectedKeys = this.selectedMetrics.filter((m) => m.checked).map((m) => m.id);
      const finalList = list.filter((c) => c.key === 'name' || selectedKeys.includes(c.key));

      return finalList;
    },
    async handleReportDimensionChange(dimension) {
      this.rptLoading = true;
      this.selectedDimension = dimension;
      await this.loadPerformanceData();
    },
    handleReportMetricsChange(metrics, isSelectAllClicked) {
      this.selectedMetrics = metrics;
      this.isAllMetricsSelected = isSelectAllClicked;
      this.rptMetaData = this.fetchReportMetaData(this.selectedDimension);
    },
  },
};
</script>
<style lang="scss" scoped>
#performanceAnalysis {
  scroll-margin: 75px;
}
.def-box {
  padding: 20px 4px 10px 25px;
  max-height: 570px;
  z-index: 10;
  position: relative;
  min-height: 150px;
}
.expan {
  &.expd {
    height: calc(100vh - 90px) !important;
    max-height: calc(100vh - 90px) !important;
  }
}
.bdc_main_wrap.whero {
  margin-top: 15px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.body-content.fullmax .thw_content {
  max-width: 1600px;
}
.thw_content {
  max-width: 1270px;
  margin: auto;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding: 0px;
  position: relative;
  z-index: 11;
}
.sopened .thw_content {
  max-width: 1160px;
}
.internal-sub-nav {
  display: inline-block;
  float: none;
  margin: 11px 20px 31px 5px;
  display: block;
}
.isn-item {
  display: inline-block;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 600;
  color: var(--primarydark2);
  position: relative;
  cursor: pointer;
}

.isn-item.active {
  color: var(--primarydark);
}

.isn-item.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.isn-item.active::before {
  content: '';
  width: 100%;
  height: 2px;
  margin-top: 25px;
  position: absolute;
  background-color: var(--primarycolor);
}

.isn-item:hover {
  color: var(--primarydark);
}

.isn-item:hover::before {
  content: '';
  width: 100%;
  height: 2px;
  margin-top: 25px;
  position: absolute;
  background-color: var(--primarydark2);
}

.bdc_panel {
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 10px 30px 0px rgba(0, 40, 60, 0.07);
  box-shadow: 0px 14px 35px -12px rgba(0, 40, 100, 0.17);
  background-color: #fff;
  margin-bottom: 30px;
  border: 1px solid rgb(165 173 178 / 22%);
}
@media (max-width: 900px) {
  .bdc_panel.two-thirds {
    width: 100% !important;
  }
}
.bdc_panel.two-thirds {
  width: calc(65% - 15px);
  display: inline-block;
}
@media (max-width: 900px) {
  .bdc_panel.one-thirds {
    width: 100% !important;
  }
}

.bdc_panel.one-thirds {
  width: calc(35% - 15px);
  display: inline-block;
}

.bdc_panel.last {
  float: right;
}

.bdc_panel .dropdown {
  border: 1px solid #c8d3e4;
}
.height430 {
  height: 430px;
}
.height495 {
  height: 495px;
}
</style>
