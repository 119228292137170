<template>
  <div style="z-index: 10;margin: 0px;">
    <transition name="expand">
      <div v-if="open" class="email-alert">
        <span>Email To:</span>
        <span
          ref="excludeButton"
          class="close-btn"
          data-tool-tip="Close"
          @click="open = false"
          @click.stop
        >
          <font-awesome-icon :icon="['far', 'times']" class="check-on checkbox-active" />
        </span>

        <div class="email-container" @click="focusInput">
          <div v-for="(email, index) in emails" :key="index" class="email-chip">
            {{ email }}
            <span class="remove" @click="removeEmail(index)">&times;</span>
          </div>
          <textarea
            ref="textareasched"
            v-model="input"
            placeholder="Type emails..."
            class="email-input"
            rows="1"
            @keydown.enter.prevent="addEmail"
            @keydown.space.prevent="addEmail"
            @keydown.backspace="handleBackspace"
            @keydown="handleKeydown"
            @input="adjustHeight"
          ></textarea>
        </div>

        <div style="padding-top: 10px;">
          <k-button :size="4" label="Save" @click="saveEmailRecipients" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    emails: {
      required: false,
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      open: false,
      input: '',
      isListenerAdded: false,
    };
  },
  watch: {
    open(n) {
      if (n) {
        this.emails = [];
        if (!this.isListenerAdded) {
          document.addEventListener('click', this.handleClickOutside);
          this.isListenerAdded = true;
        }
      } else if (this.isListenerAdded) {
        document.removeEventListener('click', this.handleClickOutside);
        this.isListenerAdded = false;
      }
    },
  },
  mounted() {
    window.addEventListener('click', this.onClickOutside, true);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.onClickOutside, true);
  },
  methods: {
    onClickOutside(e) {
      if (!this.$el.contains(e.target)) {
        this.open = false;
      }
    },
    handleClickOutside(event) {
      const textarea = this.$refs.textareasched;
      const button = this.$refs.excludeButton;

      // Skip if click is inside the textarea or the button
      if (!textarea?.contains(event.target) && !button?.contains(event.target)) {
        // Trigger any action you want here
        this.addEmail();
        // this.adjustHeightSched();
      }
    },
    saveEmailRecipients() {
      this.addEmail();
      this.$emit('save-email-recipients', this.emails);
    },
    openClosePopup() {
      this.open = !this.open;
    },
    focusInput() {
      this.$refs.textareasched.focus();
    },
    removeEmail(index) {
      this.emails.splice(index, 1);
      this.adjustHeightSched();
      this.$emit('emails-changed', this.emails);
    },
    adjustHeightSched() {
      this.$nextTick(() => {
        this.$refs.textareasched.style.height = 'auto';
        this.$refs.textareasched.style.height = `${this.$refs.textareasched.scrollHeight}px`;
      });
    },
    addEmail() {
      const trimmed = this.input.trim();
      if (!trimmed || this.emails.includes(trimmed)) {
        this.input = '';
        return;
      }
      if (this.validateEmail(trimmed)) {
        this.emails.push(trimmed);
        this.input = '';
        this.adjustHeight();
        this.$emit('emails-changed', this.emails);
      }
    },
    validateEmail(email) {
      return /\S+@\S+\.\S+/.test(email);
    },
    handleBackspace() {
      if (this.input === '' && this.emails.length) {
        this.emails.pop();
        this.adjustHeightSched();
      }
    },
    handleKeydown(event) {
      if (event.key === ',') {
        event.preventDefault();
        this.addEmail();
        this.adjustHeightSched();
      }
    },
    adjustHeight(event) {
      const textarea = event ? event.target : this.$refs.textarea;
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.errorBox {
  border: 1px solid #fc4242 !important;
}
.error {
  padding: 10px;
  font-size: 13px;
  color: rgba(252, 66, 66);
  letter-spacing: 0.9px;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.close-btn {
  float: right;
  padding-bottom: 10px;
}
.ex-dl-btn {
  color: #303335;
}
.expand-enter-active,
.expand-leave-active {
  -webkit-transition: top 0.3s ease, opacity 0.3s ease;
  -moz-transition: top 0.3s ease, opacity 0.3s ease;
  -ms-transition: top 0.3s ease, opacity 0.3s ease;
  -o-transition: top 0.3s ease, opacity 0.3s ease;
  transition: top 0.3s ease, opacity 0.3s ease;
}

.expand-enter,
.expand-leave-to {
  top: 100% !important;
  opacity: 0 !important;
}

.progress100 {
  width: 100% !important;
}

svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  font-size: inherit;
  vertical-align: -0.125em;
}
.svg-inline--fa.fa-w-12 {
  width: 0.75em;
}

@media screen {
  * {
    box-sizing: border-box;
  }
  * {
    outline: 0;
  }
  .email-alert {
    position: absolute;
    top: 21%;
    left: 78.9%;
    width: 300px;
    padding: 16px 30px;
    color: #fff;
    // text-align: center;
    background-color: rgba(29, 33, 37, 0.95);
    border-radius: 5px;
    transform: translateX(-20%);
    z-index: 10;

    .success {
      font-size: 13px;
      color: rgba(107, 207, 24);
    }
    .fail {
      font-size: 13px;
      color: rgba(252, 66, 66);
    }

    input[type='email'] {
      width: 230px;
      margin: 0.5rem 0;
      font-size: 14px;
      line-height: 33px;
      border-radius: 0.15rem;
    }
  }
  .email-alert-on-saved-rep {
    position: absolute;
    top: 21%;
    left: 29%;
    width: 300px;
    padding: 16px 30px;
    color: #fff;
    // text-align: center;
    background-color: rgba(29, 33, 37, 0.95);
    border-radius: 5px;
    transform: translateX(-20%);
    z-index: 10;

    .success {
      font-size: 13px;
      color: rgba(107, 207, 24);
    }
    .fail {
      font-size: 13px;
      color: rgba(252, 66, 66);
    }

    input[type='email'] {
      width: 230px;
      margin: 0.5rem 0;
      font-size: 14px;
      line-height: 33px;
      border-radius: 0.15rem;
    }
  }
  .flp-btn {
    position: relative;
    display: inline-block;
    padding: 15px 26px;
    font-size: 12px;
    font-weight: 400;
    color: var(--buttontext) !important;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.07em;
    cursor: pointer;
    user-select: none;
    background-color: var(--primarycolor) !important;
    -webkit-border-radius: 0;
    border-radius: 3px;
    transition: all 0.6s ease;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-touch-callout: none;
  }
  .btn-size2 {
    min-width: 20px;
    padding: 11px;
    padding-left: 17px;
    font-size: 12px;
    font-weight: 600;
    &:hover {
      color: #fff !important;
      background-color: #424242 !important;
      border: none !important;
    }
  }
  .flp-btn-icon-size2 {
    position: relative;
    top: 2px;
    padding: 5px 4px 4px 12px;
    margin: 0 0 0 13px;
    font-size: 18px;
    border-left: 1px solid rgba(0, 0, 0, 0.15);
  }
  .hide {
    display: none !important;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(111, 111, 111, 0.2);
    border: 0 #fff;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #525965;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #525965;
  }
  ::-webkit-scrollbar-track {
    background: 0 0;
    border: 0 #fff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: 0 0;
  }
  ::-webkit-scrollbar-track:active {
    background: 0 0;
  }
  ::-webkit-scrollbar-corner {
    background: 0 0;
  }
}

.fa-arrow-to-bottom:before {
  content: '\f33d';
}

.alt-loader {
  position: relative;
  display: block;
  width: 100%;
  height: 4px;
  margin: 11px 0px 2px 0px;
}
.alt-loader-bg {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.1);
}
.alt-loader-progress {
  position: absolute;
  z-index: 3;
  width: 1%;
  height: 4px;
  background-color: #00abb7;
  -webkit-transition: width 1s ease;
  -moz-transition: width 1s ease;
  -ms-transition: width 1s ease;
  -o-transition: width 1s ease;
  transition: width 1s ease;
}
.fp-text-link {
  font-size: 12px;
  color: #707e8a;
  display: inline-block;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  & p {
    text-decoration: underline;
    cursor: pointer;
  }
  & p:hover {
    color: var(--primarycolor) !important;
  }
  & span {
    text-decoration: none;
    margin-left: 8px;
    color: #212325 !important;
    .advance-filter-clear-icon:hover {
      color: var(--primarycolor) !important;
    }
  }
  & span:hover {
    color: #cad1d6;
  }
}
.custom-btn {
  border-radius: 6px !important;
  padding: 7px 12px 8px !important;
}
.txt-center {
  text-align: center;
  z-index: 99 !important;
  max-width: 20px !important;
  padding-right: 100px !important;
  padding-bottom: 50px !important;
  max-height: 10px !important;
}

.email-container {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: text;
  min-height: 40px;
  width: 250px;
}

.email-chip {
  align-items: center;
  background-color: var(--primarycolor);
  padding: 5px 12px;
  border-radius: 20px;
  margin: 3px;
  font-size: 14px;
  color: var(--buttontext);
  word-wrap: break-word;
  max-width: calc(100% - 20px); /* Reserve space for remove button */
  white-space: normal;
  overflow: hidden;
  flex-shrink: 0;
  position: relative; /* Add this to position the remove button */
  text-overflow: ellipsis; /* Display ellipsis when text overflows */
}

.remove {
  margin-left: 5px;
  cursor: pointer;
  font-weight: bold;
  position: absolute; /* Absolute position */
  right: 3px; /* Position it to the right */
  top: 50%;
  transform: translateY(-50%); /* Center it vertically */
}

.email-input {
  flex-grow: 1;
  border: none;
  outline: none;
  font-size: 14px;
  min-width: 100px;
  resize: none;
  overflow: hidden;
  padding-top: 6px;
}
</style>
