<template>
  <div class="exportable-table-wrap">
    <!-- Success and Error Message Slot -->
    <div v-if="showMessage" :class="['message', messageType]" class="message-container">
      <slot name="message">{{ message }}</slot>
    </div>

    <div class="flp-panel">
      <div style="font-weight: 600;font-size: 16px; padding: 5px 0px 10px 10px;">
        Report: {{ templateName }}
      </div>
      <b-table-report-builder
        v-if="!loading && rows.length"
        id="table-report-builder"
        :is-page-view="isPageView"
        :rows="rows"
        :headers="formatTableHeaders()"
        :row-to-scroll-to="scrollToRowIndex"
        :rows-collapsible="selectedReportKind === 'all_touch'"
        :css="reportCss"
        :is-new-report-builder="true"
        @row-click="handleTableRowClick"
      />
      <div v-else-if="!loading && !rows.length" class="table-no-data txt-align-center">
        No data is available to show
      </div>
      <b-loading-spinner
        v-else
        class="txt-align-center table-loader"
        :enable-custom-message="true"
        :custom-message="`Wow, that's a lot of data! We're grabbing it for you now, hang tight`"
      />
      <div v-if="!loading && rows.length" class="pagination">
        {{ showPagerText() }}
        <font-awesome-icon
          v-if="!hidePagination"
          :icon="['fa', 'chevron-left']"
          class="pointer"
          @click="gotoPrev"
        />
        <font-awesome-icon
          v-if="!hidePagination"
          :icon="['fa', 'chevron-right']"
          class="pointer"
          @click="gotoNext"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { isBlank } from 'adready-api/helpers/common';
import moment from 'moment';
import _ from 'underscore';
import { get, sync } from 'vuex-pathify';
import fileDownload from 'js-file-download';
import EventBus from '@/adready-vue/helpers/global/event-bus';
import {
  DEFAULT_METHODOLOGY,
  REPORT_OPTIONS,
  REPORT_BUILDER_METRIC_OPTIONS,
  REPORT_BUILDER_DISABLED_METRICS,
  REPORT_BUILDER_EXTRA_METRICS,
  ACCOUNTS_TO_SHOW,
  MNI_ACCOUNT_ID,
  MNI_DEMO_ACCOUNT_ID,
  USE_PG_API_FOR_REPORT_BUILDER,
  NEW_REPORT_BUILDER_METRIC_OPTIONS,
  NEW_REPORT_OPTIONS,
  ERROR_MESSAGE_GATEWAY_TIMEOUT,
} from '@/constant';
import config from '@/config';
import axios from 'axios';
import advertiserReportsAPI from '~/api/advertiser-reports';
import fetchMediaTypesMethodMixin from '~/components/mixins/fetch-mediatypes-method-mixin';
import fetchIosMethodMixin from '~/components/mixins/fetch-ios-method-mixin';
import fetchEventsMethodMixin from '~/components/mixins/fetch-events-method-mixin';
import { buildQueryString } from '~/helpers/global/url-helpers';
import { formatDateForAPI } from '~/util/apiDateFormat';
import * as util from '~/util/utility-functions';

export default {
  name: 'ReportsTable',
  components: {
    BTableReportBuilder: () =>
      import(
        /* webpackChunkName: "b-table-report-builder" */ '~/components/elements/b-table-report-builder.vue'
      ),
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
  },
  mixins: [fetchMediaTypesMethodMixin, fetchIosMethodMixin, fetchEventsMethodMixin],
  props: {
    isPageView: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    onAllEventsPerformanceSelected: {
      type: Function,
      required: false,
      default: () => {},
    },
    templateName: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      selectedReportKind: 'creative',
      selectedReportType: 'performance',
      rows: [],
      loading: true,
      loadingTouches: false,
      metricsOpts: [],
      metricsOptsDisabled: false,
      totalRowCount: 0,
      rowOffset: 0,
      limitRowCount: 100,
      downloadBtn: {
        preparing: false,
        done: false,
      },
      scrollToRowIndex: '0',
      emailSentStatus: '',
      openEmailPopup: false,
      reportHeaders: [],
      allEventPerformance: false,
      mniAccount: false,
      oldPayload: null,
      isSearchListenerRegistered: false,
      doAllEventSearch: false,
      payload: {},
      isNewReportSearchFromMyReportsListenerRegistered: false,
      isNewReportSearchFromMyReportsWidIDListenerRegistered: false,

      showMessage: false,
      message: '',
      messageType: 'success',

      isNewReportDownload: false,
      newReportDownloadPayload: {},
      isNewReportEmail: false,
      newReportEmailPayload: {},
      epPayloadToBeSave: {},
    };
  },
  computed: {
    advertiser: get('common/advertiser'),
    account: get('common/account'),
    universalPixelId: get('common/universalPixelId'),
    selectedMediaTypes: get('dashboard/GET_SELECTED_MEDIATYPES'),
    selectedPublishers: get('dashboard/GET_SELECTED_PUBLISHERS'),
    selectedCreatives: get('dashboard/GET_SELECTED_CREATIVES'),
    selectedAudiences: get('dashboard/GET_SELECTED_AUDIENCES'),
    selectedIOIds: get('dashboard/GET_SELECTED_IO_IDS'),
    dates: get('dashboard/dates'),
    selectedEvent: get('dashboard/GET_SELECTED_EVENT'),
    showUniques: get('dashboard/filters@showUniques'),
    attribution: get('dashboard/filters@attribution'),
    methodology: get('dashboard/filters@methodology'),
    conversionWindow: get('dashboard/filters@conversionWindow'),
    selectedAdGrpOptStr: get('dashboard/GET_SELECTED_AD_GROUP_STR'),
    selectedExportTableRow: sync('common/selectedExportTableRow'),
    isMniAccount: get('common/isMniAccount'),
    hidePagination() {
      if (this.totalRowCount < 100) {
        return true;
      }
      return false;
    },
    isDisableEmailButton() {
      return (
        (this.selectedReportKind === 'all_touch' || this.selectedReportKind === 'last_touch') &&
        util.isDemoInstance()
      );
    },
    selectedEventLabel() {
      return isBlank(this.selectedEvent.eventLabel) ? 'Event' : this.selectedEvent.eventLabel;
    },
    isCheckoutsEvent() {
      return this.selectedEvent.category?.toUpperCase() === 'CHECKOUT';
    },
    isLeadsEvent() {
      return (
        this.selectedEvent.eventLabel?.toUpperCase() === 'LEAD' ||
        this.selectedEvent.eventLabel?.toUpperCase() === 'LEADS'
      );
    },
    isCheckoutsOrRevenueEvent() {
      return this.selectedEvent.isRevenueEvent || this.isCheckoutsEvent;
    },
    isCheckoutsOrLeadsEvent() {
      return this.isLeadsEvent || this.isCheckoutsEvent;
    },
    reportCss() {
      let css = '';
      if (this.selectedReportType === 'rollup') {
        css = this.selectedEvent.isRevenueEvent ? 'sales-rollup' : 'nonsales-rollup';
      } else {
        css = 'performance-report';
      }
      return css;
    },
    selectedCategoriesLabel() {
      let label = `${this.selectedEventLabel}`;
      if (
        !isBlank(this.selectedEvent.subCategories) &&
        !this.selectedEvent.allSubCategoriesSelected
      ) {
        label = this.selectedEvent.subEventLabels.join(', ');
      }
      return this.isMniAccount && label === 'Checkouts' ? 'Conversions' : label;
    },
    getReportTypeOptions() {
      let reportOptions = [...REPORT_OPTIONS];
      if (!ACCOUNTS_TO_SHOW.includes(this.account?.id)) {
        reportOptions = [];
        reportOptions = REPORT_OPTIONS.filter((item) => item.id !== 'channel');
      }
      if (this.isMniAccount) {
        reportOptions = REPORT_OPTIONS.filter((item) => item.id !== 'audience');
        reportOptions.splice(5, 0, { id: 'audience', name: 'Placement', type: 'performance' });
      }
      let result = [...reportOptions];
      if (!this.selectedEvent.isFalseEvent) {
        result = [
          {
            id: 'all_touch',
            name: `${this.selectedCategoriesLabel} All Touch Report`,
            type: 'rollup',
          },
          {
            id: 'last_touch',
            name: `${this.selectedCategoriesLabel} Last Touch Report`,
            type: 'rollup',
          },
          ...reportOptions,
        ];
      }
      return result;
    },
    headers() {
      let opts = JSON.parse(JSON.stringify(this.metricsOpts));
      if (this.selectedReportType !== 'rollup') {
        const reportOption = REPORT_OPTIONS.find((opt) => opt.id === this.selectedReportKind);
        if (reportOption) {
          const extraOption = {
            key: 'name',
            name: reportOption.name,
            value: reportOption.name,
            width: '241px',
            checked: true,
          };
          if (this.selectedReportKind === 'daypart') {
            extraOption.formatter = { type: 'hour' };
          }
          opts = [extraOption, ...opts];
        }
      } else {
        const hideHeaders = [];
        if (!ACCOUNTS_TO_SHOW.includes(this.account?.id)) {
          hideHeaders.push('channel');
        }
        if (!this.selectedEvent.isRevenueEvent) {
          hideHeaders.push('revenue');
          if (!this.isCheckoutsOrLeadsEvent) {
            hideHeaders.push('uniqueId');
          }
        }
        if (this.isCheckoutsOrRevenueEvent) {
          hideHeaders.push('subCategory');
        }
        opts.forEach((header) => {
          if (hideHeaders.includes(header.key)) {
            header.checked = false;
          }
        });
      }
      return opts.filter((mopt) => mopt.checked && mopt.name !== 'Incremental Reach');
    },
    newReportHeaders() {
      const newOpts = JSON.parse(JSON.stringify(NEW_REPORT_BUILDER_METRIC_OPTIONS));
      let interval = this.newReportDownloadPayload?.filters?.interval?.toLowerCase();
      if (interval === 'hourly') {
        interval = 'dayPart';
      }
      const selectedInterval = newOpts.find((item) => interval === item.key);
      const metricOpts =
        this.newReportDownloadPayload?.filters?.reportMetrics.map((item) => item.metrics) || [];
      let opts = [];

      if (selectedInterval) {
        opts.push(JSON.parse(JSON.stringify(selectedInterval)));
      }

      const selectedMetrics = newOpts.filter((item) => metricOpts.includes(item.key));
      opts.push(...JSON.parse(JSON.stringify(selectedMetrics)));

      const isHourlyOrDaily = ['hourly', 'day'].includes(
        this.newReportDownloadPayload?.filters?.interval?.toLowerCase()
      );
      this.newReportDownloadPayload?.filters?.dimensions.forEach((dimension) => {
        // remove overall if dimension is overall and interval is either hourly or daily
        if (dimension === 'overall' && isHourlyOrDaily) {
          return;
        }
        const reportOption = NEW_REPORT_OPTIONS.find((opt) => opt.id === dimension);
        if (reportOption) {
          const extraOption = {
            key: this.verifyIds(reportOption?.id),
            name: reportOption?.name,
            value: reportOption?.name,
            width: reportOption?.width || '241px',
            checked: true,
          };
          opts = [extraOption, ...opts];
        }
      });
      return opts;
    },
  },
  watch: {},
  created() {
    this.registerEventListeners();
  },
  async mounted() {
    this.initializeMniAccount();
    this.loadStoredDate();
    window.addEventListener('click', this.onClickOutside);
    this.$emit('update-save-template-visibility', { status: this.templateName.length < 1 });
  },
  beforeDestroy() {
    this.unregisterEventListeners();
    window.removeEventListener('click', this.onClickOutside);
  },
  methods: {
    registerEventListeners() {
      if (!this.isSearchListenerRegistered) {
        EventBus.listenerReady = true;
        EventBus.$on('do-new-report-search', this.handleSearch);
        this.isSearchListenerRegistered = true;
      }

      if (!this.isNewReportSearchFromMyReportsListenerRegistered) {
        EventBus.myReportsListenerReady = true;
        EventBus.$on('do-new-report-search-from-my-reports', this.handleMyReportsSearch);
        this.isNewReportSearchFromMyReportsListenerRegistered = true;
      }

      if (!this.isNewReportSearchFromMyReportsWidIDListenerRegistered) {
        EventBus.myReportsWithIDListenerReady = true;
        EventBus.$on(
          'do-new-report-search-from-my-reports-with-id',
          this.handleMyReportsWithoutIDSearch
        );
        this.isNewReportSearchFromMyReportsWidIDListenerRegistered = true;
      }

      EventBus.$on('table-row-click', this.handleRowClick);
    },
    unregisterEventListeners() {
      EventBus.listenerReady = false;
      EventBus.myReportsListenerReady = false;
      EventBus.myReportsWithIDListenerReady = false;

      if (this.isSearchListenerRegistered) {
        EventBus.$off('do-new-report-search', this.handleSearch);
        this.isSearchListenerRegistered = false;
      }
      EventBus.$off('table-row-click', this.handleRowClick);

      if (this.isNewReportSearchFromMyReportsListenerRegistered) {
        EventBus.$off('do-new-report-search-from-my-reports', this.handleMyReportsSearch);
        this.isNewReportSearchFromMyReportsListenerRegistered = false;
      }

      if (this.isNewReportSearchFromMyReportsWidIDListenerRegistered) {
        EventBus.$off(
          'do-new-report-search-from-my-reports-with-id',
          this.handleMyReportsWithoutIDSearch
        );
        this.isNewReportSearchFromMyReportsWidIDListenerRegistered = false;
      }
    },
    initializeMniAccount() {
      if (this.account?.id === MNI_ACCOUNT_ID || this.account?.id === MNI_DEMO_ACCOUNT_ID) {
        this.mniAccount = true;
        this.$store.dispatch('common/setIsMniAccount', true);
      } else {
        this.mniAccount = false;
        this.$store.dispatch('common/setIsMniAccount', false);
      }
    },
    loadStoredDate() {
      const storedDate = sessionStorage.getItem('selectedDates');
      if (storedDate) {
        this.$store.set('dashboard/dates', JSON.parse(storedDate));
      }
    },
    showSuccessMessage(msg) {
      this.message = msg;
      this.messageType = 'success';
      this.displayMessage();
    },
    showErrorMessage(msg) {
      this.message = msg;
      this.messageType = 'error';
      this.displayMessage();
    },
    displayMessage() {
      this.showMessage = true;
      setTimeout(() => {
        this.showMessage = false;
      }, 5000);
    },
    preparePayload(payload) {
      return {
        advertiser: this.advertiser?.name || '',
        client: this.account?.name || '',
        xandrId: this.advertiser?.xandrAdvertiserId || '',
        pixel: this.universalPixelId,
        mediaType: payload?.filters?.mediaTypes,
        ioIds: payload?.filters?.campaignIds,
        startDate: formatDateForAPI(payload?.filters?.startDate),
        endDate: formatDateForAPI(payload?.filters?.endDate),
        adGroups: payload?.filters?.adGroups || '',
        audience: payload?.filters?.audiences,
        publisher: payload?.filters?.publisher,
        creative: payload?.filters?.creatives,
        conversionWindow:
          payload?.filters?.conversionWindowUnit === 'DAYS'
            ? payload?.filters?.conversionWindow * 24
            : payload?.filters?.conversionWindow || 744,
        showUniques: payload?.filters?.showUniques,
        methodology: payload?.filters?.methodology?.toLowerCase() || DEFAULT_METHODOLOGY,
        attribution: this.attribution,
        event: this.selectedEventLabel,
        category: isBlank(this.selectedEvent.category) ? 'none' : this.selectedEvent.category,
        subCategory: this.selectedEvent.subCategories,
        interval: payload?.filters?.interval || 'CUMULATIVE',
        reportBuilderV2: true,
        templateName: payload?.name ?? '',
        dateRangeType: payload?.filters?.dateRangeType ?? '',
      };
    },
    verifyIds(id) {
      const idMap = {
        mediatype: 'mediaType',
        device: 'deviceType',
        zip: 'zipCode',
        adgroup: 'adGroup',
        overall: 'overAll',
      };
      return idMap[id] || id;
    },
    handleRowClick(val) {
      if (val) {
        this.doReportSearch();
      }
    },
    handleSearch(payload) {
      this.selectedReportKind =
        payload?.filters?.template?.toLowerCase() ||
        payload?.filters?.dimensions[0]?.toLowerCase() ||
        DEFAULT_METHODOLOGY;
      this.allEventPerformance = this.isEpSelected(payload);
      if (this.allEventPerformance) {
        this.epPayloadToBeSave = payload;
      }
      this.payload = this.preparePayload(payload);
      if (this.advertiser) {
        if (_.isEqual(this.payload, this.oldPayload)) {
          return;
        }
        this.doReportSearch();
        this.oldPayload = this.payload;

        if (payload.exportNSend) {
          this.emailReport(payload.exportNSendEmails, false);
        }
      }
    },
    isEpSelected(payload) {
      return (
        payload?.filters?.reportMetrics.length === 1 &&
        payload.filters.reportMetrics[0].metrics === 'ep'
      );
    },
    async doReportSearch() {
      const reportOption = this.getReportTypeOptions.find((o) => o.id === this.selectedReportKind);
      this.selectedReportType =
        this.selectedReportKind === 'all_touch' || this.selectedReportKind === 'last_touch'
          ? 'rollup'
          : reportOption?.type || 'performance';
      this.setMetricOpts();
      this.rowOffset = 0;
      this.scrollToRowIndex = '0';

      try {
        await this.loadTableData(this.payload);
        this.$emit('update-view-report-loading', { isLoading: false });
      } catch (err) {
        console.error('Error in updating daily view chart ->', err.message, err);
        this.$emit('update-view-report-loading', { isLoading: false });
        if (window.$sentry && !err._reported) {
          window.$sentry.captureException(
            `Error in updating daily view chart [watch: payload] ->${err}`
          );
          err._reported = true;
        }
      }
    },
    async handleMyReportsWithoutIDSearch(payload) {
      this.rowOffset = 0;
      this.isNewReportDownload = true;
      this.newReportDownloadPayload = payload;
      this.isNewReportEmail = true;
      this.newReportEmailPayload = payload;
      this.doNewReportSearch(payload);
    },
    doNewReportSearch(payload) {
      payload.offset = this.rowOffset;
      payload.limit = this.limitRowCount;

      const url = `${config.ADREADY_URL}/api/ac/advertisers/${payload.advertiserId}/reportTemplate/run`;
      const apiCall = axios.post(url, payload, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json' },
      });
      this.loading = true;
      apiCall
        .then((response) => {
          if (response?.data?.status === 200) {
            this.reportHeaders = [];
            this.rows = response?.data?.result?.data || [];
            this.loading = false;
            this.$emit('update-view-report-loading', { isLoading: false });
          }
          this.totalRowCount = parseInt(response.data.result.total || 0, 10);
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.message || 'An unknown error occurred';
          console.error('Error:', errorMessage);
          this.loading = false;
          this.$emit('update-view-report-loading', { isLoading: false });
          this.showErrorMessage(errorMessage);
        });
    },
    saveTemplate(templateName) {
      this.$emit('update-save-template-visibility', { status: true });
      this.$emit('show-spinner-while-saving-template', { status: true });

      // setting to true to fetch updated report list
      EventBus.fetchSavedReports = true;

      const payload = this.allEventPerformance
        ? this.epPayloadToBeSave
        : this.newReportDownloadPayload;
      payload.name = templateName;

      const url = `${config.ADREADY_URL}/api/ac/advertisers/${payload.advertiserId}/reportTemplate`;
      const apiCall = axios.post(url, payload, { withCredentials: true });

      apiCall
        .then((response) => {
          if (response?.data?.status === 200) {
            this.showSuccessMessage(`Template ${templateName} has been saved.`);
            this.$emit('update-save-template-visibility', { status: false });
            this.$emit('show-spinner-while-saving-template', { status: false });
            this.$nextTick(() => {
              this.templateName = templateName;
              this.$emit('update-template-name', templateName);
            });
          }
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.message || 'An unknown error occurred';
          this.showErrorMessage(errorMessage);
          this.$nextTick(() => {
            payload.name = '';
            this.templateName = '';
            this.$emit('update-template-name', '');
          });
          this.$emit('update-save-template-visibility', { status: true });
          this.$emit('show-spinner-while-saving-template', { status: false });
        });
    },
    async handleMyReportsSearch(payload) {
      this.rowOffset = 0;
      this.isNewReportDownload = true;
      this.isNewReportEmail = true;
      const retainPayload = { ...payload };
      this.newReportDownloadPayload = retainPayload;
      this.newReportEmailPayload = retainPayload;

      this.doNewReportSearch(payload);
    },
    onClickOutside(e) {
      const refArray = ['metricOptionsCont'];
      if (e?.composedPath()?.some((p) => p?.id === 'metricOptionsCont')) {
        this?.$refs.ioOptionDropdown?.closeDropdown();
        return;
      }
      refArray.forEach((ref) => {
        if (
          !(e.target.parentNode?.className === 'norm-option') &&
          !(e.target.parentNode?.className === 'dropdown-menu') &&
          !(e.target?.nodeName === 'path')
        ) {
          this?.$refs[ref]?.closeDropdown();
        }
      });
    },
    formatEventTableHeader() {
      const repheader = JSON.parse(JSON.stringify(this.reportHeaders));

      for (let i = 0; i < repheader.length; i++) {
        repheader[i] = {
          key: repheader[i],
          name: repheader[i],
          value: repheader[i],
          width: '',
          formatter: {
            type: '',
            format: '',
          },
        };
        if (repheader[i].key === 'name') {
          let reportKind = this.selectedReportKind;
          if (this.isMniAccount && reportKind === 'audience') reportKind = 'placement';
          repheader[i].name = reportKind.charAt(0).toUpperCase() + reportKind.slice(1);
          repheader[i].width = '260px';
        } else {
          repheader[i].formatter.format =
            repheader[i].key.includes('_CPA') || repheader[i].key.includes('spend')
              ? '0,0.00'
              : '0,0.[00]';
          repheader[i].formatter.type = 'number';
          repheader[i].width = repheader[i].key === 'spend' ? '100px' : '180px';
          repheader[i].name = repheader[i].key === 'spend' ? '($)Spend' : repheader[i].name;
        }
      }
      return repheader;
    },
    formatTableHeaders() {
      if (this.isNewReportDownload || this.isNewReportEmail) {
        const tempHeaders = [...this.newReportHeaders];
        if (this.isMniAccount) {
          tempHeaders.forEach((item) => {
            if (item.name === 'Audience') {
              item.name = 'Placement'; // Replace 'Audience' with 'Placement'
            }
          });
        }
        return tempHeaders;
      }

      if (!isBlank(this.reportHeaders)) {
        return this.formatEventTableHeader();
      }
      let tempHeaders = [...this.headers];
      tempHeaders = tempHeaders.map((item) => {
        if (item.key === 'videoCompletionRate') item.name = `(%)Completion Rate`;
        if (item.key === 'videoCompletions') item.name = `Completions`;
        return item;
      });
      const isSelectedTypeAudience = tempHeaders.find((item) => item.name === 'Audience');
      if (this.isMniAccount && isSelectedTypeAudience) {
        tempHeaders = tempHeaders.filter((item) => item.name !== 'Audience');
        tempHeaders.unshift({
          key: 'name',
          name: 'Placement',
          value: 'Audience',
          width: '241px',
          checked: true,
        });
      }
      const stickyHeaderIndex = tempHeaders.findIndex((header) => header.stickyColumnAnchor);
      let stickyWidth = this.selectedReportKind === 'last_touch' ? 0 : 26;
      for (let i = 0; i <= stickyHeaderIndex; i++) {
        tempHeaders[i] = {
          ...tempHeaders[i],
          isSticky: true,
          stickyWidth,
        };
        const tempWidth = tempHeaders[i]?.width ? tempHeaders[i].width.split('px')[0] : 84;
        stickyWidth += Number(tempWidth);
      }
      return tempHeaders;
    },
    validatePerformanceReportParams(payload, advertiser) {
      const advertiserId = advertiser?.id;
      if (isBlank(advertiserId)) {
        return false;
      }
      const keys = [
        'kind',
        'client',
        'advertiser',
        'xandrId',
        'pixel',
        'category',
        'startDate',
        'endDate',
      ];
      return !util.isAnyBlank(payload, keys);
    },
    validateRollupReportParams(payload, advertiser) {
      const advertiserId = advertiser?.id;
      if (isBlank(advertiserId)) {
        return false;
      }
      const keys = ['client', 'advertiser', 'xandrId', 'pixel', 'category', 'startDate', 'endDate'];
      return !util.isAnyBlank(payload, keys);
    },
    async emailReport(email = '', showEmailPopup = true) {
      const payload = { ...this.payload, emailAddr: email, onceNow: true, emailIt: true };

      if (showEmailPopup) {
        this.openEmailPopup = true;
        this.$emit('update-open-email-popup', { openEmailPopup: true });
        this.$emit('show-spinner-while-emailing', { status: true });
      }

      if (this.isNewReportEmail) {
        const payLoad = {
          ...this.newReportEmailPayload,
          emailTo: email,
          onceNow: true,
          emailIt: true,
          email: true,
        };
        this.downloadOrEmailReport(payLoad, 'email');
        return;
      }

      try {
        await this.loadTableData(payload, 'email');
        this.emailSentStatus = 'success';
        this.$emit('update-email-sent-status', { emailSentStatus: 'success' });
        this.showSuccessMessage(`Report has been sent to the email id: ${email} successfully.`);
        this.$emit('show-spinner-while-emailing', { status: false });
      } catch (err) {
        this.emailSentStatus = 'error';
        const errorMessage =
          err.response?.message ||
          'Due to internal error, email has not been sent now. Please try later!';
        this.showErrorMessage(errorMessage);
        this.$emit('update-email-sent-status', { emailSentStatus: 'error' });
        this.$emit('show-spinner-while-emailing', { status: false });
      } finally {
        setTimeout(() => {
          this.emailSentStatus = '';
          this.openEmailPopup = false;
          this.$emit('update-email-sent-status', { emailSentStatus: '' });
          this.$emit('update-open-email-popup', { openEmailPopup: false });
          this.$emit('show-spinner-while-emailing', { status: false });
        }, 2000);
      }
    },
    setMetricOpts(isMounted = false) {
      if (this.selectedExportTableRow) {
        this.selectedReportKind = this.selectedExportTableRow;
      }
      const key = `${this.selectedReportType}`;
      let opts = JSON.parse(JSON.stringify(REPORT_BUILDER_METRIC_OPTIONS[key] || []));
      if (this.selectedReportKind !== 'publisher' && this.selectedReportKind !== 'overall') {
        opts = opts.filter((opt) => opt.key !== 'incrementalReach');
      }
      if (this.selectedEvent.isFalseEvent) {
        opts = opts.filter((opt) => !['checkouts', 'metric', 'pm'].includes(opt.key));
      }
      const disabledOptions = REPORT_BUILDER_DISABLED_METRICS[this.selectedReportKind] || [];
      const isAllEvnOptSelected = this.metricsOpts.filter((op) => op.key === 'ep')?.[0]?.checked;
      if (isAllEvnOptSelected && key !== 'rollup') {
        this.allEventPerformance = true;
        return;
      }
      opts = opts.map((item) => {
        if (item.key === 'videoCompletionRate') item.value = `(%)Completion Rate`;
        if (item.key === 'videoCompletions') item.value = `Completions`;
        return item;
      });
      opts.forEach((opt) => {
        const isOptionDisabled = disabledOptions.includes(opt.key);
        opt.checked = !isOptionDisabled;
        opt.disabled = isOptionDisabled;

        if (this.selectedExportTableRow) {
          if (['reach', 'frequency'].includes(opt.key)) {
            opt.checked = false;
          } else if (this.selectedEvent.isFalseEvent) {
            if (['videoCompletions', 'videoCompletionRate'].includes(opt.key)) {
              opt.checked = this.selectedEvent.isVcrEvent;
            }
            if (['clicks', 'clickThroughRate'].includes(opt.key)) {
              opt.checked = this.selectedEvent.isCtrEvent;
            }
          } else if (
            ['videoCompletions', 'videoCompletionRate', 'clicks', 'clickThroughRate'].includes(
              opt.key
            )
          ) {
            opt.checked = false;
          }
        }
        if (key === 'performance' && opt.key === 'checkouts') {
          opt.key = this.selectedEvent.isRevenueEvent ? 'revenue' : 'checkouts';
          opt.name = this.selectedEvent.isRevenueEvent ? '($)Rev' : this.selectedEventLabel;
          opt.value = this.selectedEventLabel;
          opt.formatter.format = this.selectedEvent.isRevenueEvent ? '0,0.00' : '0,0.[00]';
        } else if (key === 'performance' && opt.key === 'metric') {
          opt.checked =
            isMounted ||
            this.metricsOpts.filter((oa) => oa.key === 'cpa' || oa.key === 'roas')?.[0]?.checked;
          if (this.selectedEventLabel === 'Incremental Reach') {
            opt.key = 'incrementalReachPercentage';
            opt.name = 'Inc. Reach %';
            opt.formatter.format = '0';
          } else {
            const unit = this.selectedEvent.isRevenueEvent ? '%' : '$';
            opt.key = this.selectedEvent.isRevenueEvent ? 'roas' : 'cpa';
            opt.name = `(${unit})${this.selectedEvent.metric}`;
            opt.formatter.format = this.selectedEvent.isRevenueEvent ? '0' : '0,0.00';
          }

          opt.infinityKey = `${opt.key}Infinity`;
          opt.value = this.selectedEvent.metric;
        }
        if (opt.key === 'ep') {
          opt.checked = false;
        }
      });
      if (this.selectedReportKind !== 'publisher' && this.selectedReportKind !== 'overall') {
        opts = opts.filter((opt) => opt.key !== 'incrementalReachPercentage');
      }
      if (this.selectedReportKind === 'overall') {
        REPORT_BUILDER_EXTRA_METRICS.forEach((rbem) => {
          opts.push({ ...rbem, checked: false });
        });
      }
      if (this.selectedEventLabel === 'Incremental Reach') {
        opts.forEach((op) => {
          if (op.key === 'incrementalReachPercentage') {
            op.value = 'Inc. Reach %';
            op.checked = true;
          }
          return op;
        });
      }

      this.metricsOpts = opts.filter((opt) => opt.value && opt.name !== 'Incremental Reach');
    },

    async onChangeReportType(opt) {
      this.allEventPerformance = false;
      this.selectedExportTableRow = '';
      if (this.onChangeReportKind === opt) {
        return;
      }
      this.selectedReportKind = opt;
      const reportOption = this.getReportTypeOptions.find((o) => o.id === this.selectedReportKind);
      this.selectedReportType = reportOption?.type || 'performance';
      this.metricsOptsDisabled = this.selectedReportType === 'rollup';
      this.setMetricOpts();
      this.rowOffset = 0;
      this.scrollToRowIndex = '0';
      try {
        await this.loadTableData(this.payload);
      } catch (err) {
        console.error('error loading report builder data on select group by option ->', err);
        throw err;
      }
    },

    showPagerText() {
      const startRowIndex = this.rowOffset + 1;
      let endRowIndex = this.rowOffset + this.limitRowCount;
      endRowIndex = endRowIndex > this.totalRowCount ? this.totalRowCount : endRowIndex;
      return `${startRowIndex} - ${endRowIndex} of ${this.totalRowCount}`;
    },

    async gotoNext() {
      if (this.rowOffset + this.limitRowCount >= this.totalRowCount) {
        return;
      }
      try {
        this.rowOffset += this.limitRowCount;

        if (this.isNewReportDownload || this.isNewReportEmail) {
          this.doNewReportSearch(this.newReportDownloadPayload);
          return;
        }

        await this.loadTableData(this.payload);
      } catch (err) {
        console.error('error going to next page on report builder ->', err);
        throw err;
      }
    },

    async gotoPrev() {
      if (this.rowOffset <= 0) {
        return;
      }
      try {
        this.rowOffset -= this.limitRowCount;
        if (this.isNewReportDownload || this.isNewReportEmail) {
          this.doNewReportSearch(this.newReportDownloadPayload);
          return;
        }
        await this.loadTableData(this.payload);
      } catch (err) {
        console.error('error going to prev page on report builder ->', err);
        throw err;
      }
    },

    async loadTableData(payload, action = 'display') {
      try {
        if (this.allEventPerformance) {
          delete payload.interval;
          await this.fetchReportEventPerformance(
            payload,
            this.rowOffset,
            this.limitRowCount,
            action
          );
          this.$emit('update-view-report-loading', { isLoading: false });
          return;
        }
        if (this.selectedReportType === 'rollup') {
          delete payload.interval;
          await this.fetchReportRollup(payload, this.rowOffset, this.limitRowCount, action);
          this.$emit('update-view-report-loading', { isLoading: false });
          return;
        }
        // await this.fetchReportPerformance(payload, this.rowOffset, this.limitRowCount, action);
        this.$emit('update-view-report-loading', { isLoading: false });
      } catch (err) {
        this.rows = [];
        console.error('error loading report builder data ->', err);
        this.$emit('update-view-report-loading', { isLoading: false });
        throw err;
      }
    },

    async handleTableRowClick(payload) {
      if (this.loadingTouches) {
        return;
      }
      this.rows[payload.index].open = !this.rows[payload.index].open;
      if (!this.rows[payload.index].open) {
        this.rows[payload.index].subRowLoading = false;
        this.rows.splice(payload.index + 1, payload.data.timesExposed - 1);
        return;
      }
      try {
        await this.fetchReportRollupTouches(
          {
            ...this.payload,
            ip: payload.data.ip,
            ts: payload.data.pixelTimestampMillis,
          },
          payload.index
        );
      } catch (err) {
        console.error('error fetching rollup touches ->', err);
      }
    },
    async onSelectMetricOptionsDropdownClosed() {
      if (this.doAllEventSearch) {
        await this.fetchReportEventPerformance(
          this.payload,
          this.rowOffset,
          this.limitRowCount,
          'display'
        );
      } else {
        await this.fetchReportPerformance(
          this.payload,
          this.rowOffset,
          this.limitRowCount,
          'display'
        );
      }
    },
    async onSelectMetricOptions(options, isSelectAllClicked = false, index = -1) {
      const opts = JSON.parse(JSON.stringify(options));
      if (isSelectAllClicked) {
        opts.forEach((opt) => {
          if (opt.key === 'ep') {
            opt.checked = false;
          }
        });
      } else if (index === 0) {
        if (this.epSelected(opts)) {
          opts.forEach((opt) => {
            if (opt.key !== 'ep') {
              opt.checked = false;
            }
          });
        }
      } else if (opts.filter((op) => op.key !== 'ep').filter((o) => o.checked).length > 0) {
        opts.forEach((opt) => {
          if (opt.key === 'ep') {
            opt.checked = false;
          }
        });
      }

      this.metricsOpts = opts.filter((opt) => opt.value);
      if (this.epSelected(opts)) {
        try {
          this.allEventPerformance = true;
          this.doAllEventSearch = true;
        } catch (err) {
          this.rows = [];
          console.error('error loading report builder data ->', err);
          throw err;
        }
      } else {
        try {
          this.allEventPerformance = false;
          this.doAllEventSearch = false;
        } catch (err) {
          this.rows = [];
          console.error('error loading report builder data ->', err);
          throw err;
        }
      }
    },

    epSelected(options) {
      const opts = JSON.parse(JSON.stringify(options));
      let flag = false;
      opts.forEach((opt) => {
        if (opt.key === 'ep' && opt.checked === true) {
          flag = true;
        }
      });
      this.$emit('on-all-events-performance-selected', flag);
      return flag;
    },

    allSelected(options) {
      const opts = JSON.parse(JSON.stringify(options));
      let flagChecked = false;
      opts.forEach((opt) => {
        if (opt.checked === true) {
          flagChecked = true;
        }
      });
      return flagChecked;
    },

    deSelecAll(options) {
      const opts = JSON.parse(JSON.stringify(options));
      opts.forEach((opt) => {
        if (opt.key !== 'ep') {
          opt.checked = false;
        } else {
          opt.checked = true;
        }
      });
      return opts;
    },

    genNewReportCSVFileName(payload) {
      const date = moment(new Date()).format('yyyy-MM-DD');
      return payload.name && payload.name.length > 0
        ? `${payload.name}-${date}.csv`
        : `${payload.filters.dimensions.join('-').toLowerCase()}-${date}.csv`;
    },

    downloadOrEmailReport(payload, action = 'download') {
      const url = `${config.ADREADY_URL}/api/ac/advertisers/${payload.advertiserId}/reportTemplate/run`;
      if (action === 'download') {
        payload.export = true;
        delete payload.offset;
        delete payload.limit;
        const apiCall = axios.post(url, payload, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'text/csv',
          },
          responseType: 'blob',
        });
        apiCall
          .then((response) => {
            if (response?.status === 200) {
              this.saveCSV(response.data, this.genNewReportCSVFileName(payload));
              this.$emit('update-download-status', { preparing: false, done: true });
            }
          })
          .catch((error) => {
            // Check if the error is a response error
            if (error.response) {
              if (error.response.status === 504) {
                // Handle 504 Gateway Timeout specifically
                this.showErrorMessage(ERROR_MESSAGE_GATEWAY_TIMEOUT);
              } else {
                // Handle other response errors
                const errorMessage = error.response.data?.message || 'An unknown error occurred';
                this.showErrorMessage(errorMessage);
              }
            } else if (error.request) {
              // The request was made but no response was received
              this.showErrorMessage(ERROR_MESSAGE_GATEWAY_TIMEOUT);
            } else {
              // Something happened in setting up the request that triggered an Error
              this.showErrorMessage(
                `An error occurred while setting up the request: ${error.message}`
              );
            }
          })
          .finally(() => {
            setTimeout(() => {
              this.downloadBtn.preparing = false;
              this.downloadBtn.done = false;
              this.$emit('update-download-status', { preparing: false, done: false });
            }, 1000);
          });
      } else if (action === 'email') {
        delete payload.offset;
        delete payload.limit;
        const apiCall = axios.post(url, payload, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
          respondHeaders: true,
        });
        apiCall
          .then((response) => {
            if (response?.status === 200) {
              this.emailSentStatus = 'success';
              this.$emit('update-email-sent-status', { emailSentStatus: 'success' });
              this.showSuccessMessage(
                `Report has been sent to the email id: ${payload.emailTo} successfully.`
              );
              this.$emit('show-spinner-while-emailing', { status: false });
            }
          })
          .catch((error) => {
            this.emailSentStatus = 'error';
            this.$emit('update-email-sent-status', { emailSentStatus: 'error' });
            this.$emit('show-spinner-while-emailing', { status: false });

            // Check if the error is a response error
            if (error.response) {
              if (error.response.status === 504) {
                // Handle 504 Gateway Timeout specifically
                this.showErrorMessage(ERROR_MESSAGE_GATEWAY_TIMEOUT);
              } else {
                // Handle other response errors
                const errorMessage = error.response.data?.message || 'An unknown error occurred';
                this.showErrorMessage(errorMessage);
              }
            } else if (error.request) {
              // The request was made but no response was received
              this.showErrorMessage(ERROR_MESSAGE_GATEWAY_TIMEOUT);
            } else {
              // Something happened in setting up the request that triggered an Error
              this.showErrorMessage(
                `An error occurred while setting up the request: ${error.message}`
              );
            }
          })
          .finally(() => {
            setTimeout(() => {
              this.emailSentStatus = '';
              this.openEmailPopup = false;
              this.$emit('update-email-sent-status', { emailSentStatus: '' });
              this.$emit('update-open-email-popup', { openEmailPopup: false });
              this.$emit('show-spinner-while-emailing', { status: false });
            }, 1000);
          });
      }
    },

    async download() {
      this.downloadBtn.preparing = true;
      this.$emit('update-download-status', { preparing: true, done: false });

      if (this.isNewReportDownload) {
        this.downloadOrEmailReport(this.newReportDownloadPayload, 'download');
        return;
      }

      try {
        await this.loadTableData(this.payload, 'download');
        this.downloadBtn.done = true;
        this.$emit('update-download-status', { preparing: false, done: true });
      } catch (err) {
        console.error('error downloading file ->', err);
        throw err;
      } finally {
        setTimeout(() => {
          this.downloadBtn.preparing = false;
          this.downloadBtn.done = false;
          this.$emit('update-download-status', { preparing: false, done: false });
        }, 1000);
      }
    },

    onSelect(val) {
      this.selectedOpt = val;
    },

    async fetchReportPerformance(payload, offset = 0, limit = 100, action = 'display') {
      const fieldData = this.headers.map((header) => {
        if (header.key === 'vcr') {
          return 'videoCompletionRate';
        }
        if (header.key === 'completions') {
          return 'videoCompletions';
        }
        return header.key;
      });
      if (action === 'display') {
        this.loading = true;
      }
      payload = {
        ...payload,
        kind: this.selectedReportKind,
        dimension: this.selectedReportKind,
        pixel: this.universalPixelId,
        fields: fieldData,
        offset,
        limit,
      };
      try {
        const dataValidated = this.validatePerformanceReportParams(payload, this.advertiser);
        let res = {};
        if (dataValidated) {
          const opts = {
            headers: {
              Accept: 'application/json',
            },
            respondHeaders: true,
          };
          if (action === 'download') {
            opts.headers.Accept = 'text/csv';
            opts.responseType = 'blob';
          }
          if (USE_PG_API_FOR_REPORT_BUILDER) {
            res = await advertiserReportsAPI.reportsPerformance(
              this.advertiser.id,
              buildQueryString(payload),
              opts
            );
          } else {
            res = await advertiserReportsAPI.fetchBrandDimensionReport(
              [this.advertiser.id, 1],
              buildQueryString(payload),
              opts
            );
          }

          if (action !== 'display') {
            if (action === 'download') {
              this.saveCSV(res.data, this.genCSVFileName());
            }
            return;
          }
        }
        if (res.headers) {
          this.totalRowCount = dataValidated ? parseInt(res.headers['x-total-count'], 10) : 0;
        }

        res.result = res?.result || [];
        res.result.forEach((item) => {
          item.open = false;
          if (this.selectedReportKind === 'publisher' && item.name === '') {
            item.name = 'Other Publishers';
          }
        });
        this.reportHeaders = [];
        this.rows = res?.result || [];
      } catch (err) {
        console.error('error in fetching report table ->', err);
        if (window.$sentry && !err._reported) {
          window.$sentry.captureException(
            `error in fetching report table [fetchReportPerformance] -> ${err}`
          );
          err._reported = true;
        }
      } finally {
        this.loading = false;
      }
    },

    async fetchReportEventPerformance(payload, offset = 0, limit = 100, action = 'display') {
      const fieldData = this.headers.map((header) => header.key);
      if (action === 'display') {
        this.loading = true;
      }
      payload = {
        ...payload,
        kind: this.selectedReportKind,
        pixel: this.universalPixelId,
        fields: fieldData,
        offset,
        limit,
      };
      try {
        const dataValidated = this.validatePerformanceReportParams(payload, this.advertiser);
        let res = {};
        if (dataValidated) {
          const opts = {
            headers: {
              Accept: 'application/json',
            },
            respondHeaders: true,
          };
          if (action === 'download') {
            opts.headers.Accept = 'text/csv';
            opts.responseType = 'blob';
          }
          res = await advertiserReportsAPI.reportsEventPerformance(
            this.advertiser.id,
            buildQueryString(payload),
            opts
          );
          if (action !== 'display') {
            if (action === 'download') {
              this.saveCSV(res.data, this.genCSVFileName());
            }
            return;
          }
        }
        if (res.headers) {
          this.totalRowCount = dataValidated ? parseInt(res.headers['x-total-count'], 10) : 0;
        }
        res.result = res?.result || [];

        this.rows = res?.result?.records || [];
        this.reportHeaders = res?.result?.headers || [];
      } catch (err) {
        console.error('error in fetching report event table ->', err);
        if (window.$sentry && !err._reported) {
          window.$sentry.captureException(
            `error in fetching report event table [fetchReportPerformance] -> ${err}`
          );
          err._reported = true;
        }
      } finally {
        this.loading = false;
      }
    },

    async fetchReportRollup(payload, offset = 0, limit = 100, action = 'display') {
      if (action === 'display') {
        this.loading = true;
      }
      payload = { ...payload, offset, limit };
      delete payload.showUniques;
      delete payload.attribution;
      delete payload.methodology;
      payload.kind = this.selectedReportKind;
      payload.includeChannel = !!ACCOUNTS_TO_SHOW.includes(this.account?.id);

      if (this.selectedEvent.allSubCategoriesSelected) {
        delete payload.subCategory;
      }

      const opts = {
        headers: {
          Accept: 'application/json',
        },
        respondHeaders: true,
      };
      if (action === 'download') {
        opts.headers.Accept = 'text/csv';
        opts.responseType = 'blob';
      }
      try {
        const dataValidated = this.validateRollupReportParams(payload, this.advertiser);
        let res = {};
        if (dataValidated) {
          res = await advertiserReportsAPI.reportsRollup(
            this.advertiser.id,
            buildQueryString(payload),
            opts
          );
          if (action !== 'display') {
            if (action === 'download') {
              this.saveCSV(res.data, this.genCSVFileName());
            }
            return;
          }
        }
        if (res.headers) {
          this.totalRowCount = parseInt(res.headers['x-total-count'], 10);
        }
        this.reportHeaders = [];
        res.result = res.result || [];
        res.result.forEach((item) => {
          item.open = false;
          item.subRowLoading = false;
        });
        this.rows = res.result;
      } catch (err) {
        console.error('error in fetching report rollup table ->', err);
        if (window.$sentry && !err._reported) {
          window.$sentry.captureException(
            `error in fetching report rollup table [fetchReportRollup] -> ${err}`
          );
          err._reported = true;
        }
      } finally {
        this.loading = false;
      }
    },

    async fetchReportRollupTouches(payload, insertFromIndex) {
      this.loadingTouches = true;
      delete payload.showUniques;
      delete payload.attribution;
      delete payload.methodology;
      try {
        this.rows[insertFromIndex].subRowLoading = true;
        const res = await advertiserReportsAPI.reportsRollupTouches(
          this.advertiser.id,
          buildQueryString(payload),
          { respondHeaders: true }
        );
        this.reportHeaders = [];
        res.result = res.result || [];
        this.rows[insertFromIndex].subRowLoading = false;
        this.rows[insertFromIndex].open = true;
        res.result.forEach((item) => {
          item.subRow = true;
          insertFromIndex++;
          this.rows.splice(insertFromIndex, 0, item);
        });
        this.scrollToRowIndex = `${insertFromIndex - res.result.length}`;
      } catch (err) {
        console.error('error in fetching report rollup table ->', err);
        if (window.$sentry && !err._reported) {
          window.$sentry.captureException(
            `error in fetching report rollup table [fetchReportRollupTouches] -> ${err}`
          );
          err._reported = true;
        }
      } finally {
        this.loadingTouches = false;
      }
    },

    saveCSV(data, fileName = 'file.csv') {
      fileDownload(data, fileName);
    },

    genCSVFileName() {
      let { selectedReportKind } = this;
      if (this.isMniAccount && selectedReportKind.toLowerCase() === 'audience') {
        selectedReportKind = 'placement';
      }
      return `${
        this.selectedReportType === 'rollup'
          ? `${this.selectedCategoriesLabel.toLowerCase()}-${this.selectedReportKind}`
          : selectedReportKind
      }-${moment(new Date()).format('yyyy-MM-DD')}.csv`;
    },
  },
};
</script>

<style lang="scss" scoped>
.pointer {
  margin: 0 0.25rem;
  cursor: pointer;
}
.pointer:hover {
  color: var(--primarycolor);
}
.light-theme .pagination {
  color: #555;
}
.pagination {
  margin-top: 0.3rem;
  font-size: 13px;
  color: #aab7bc;
  text-align: right;
}
.b-select {
  width: auto;
  min-width: 200px;
  max-width: 200px;
  margin-right: 20px !important;
  ::v-deep.select {
    margin-top: 0;
    .selected-opt {
      display: block;
      width: 164px !important;
    }
  }
}

::v-deep .dropdown {
  max-width: 200px;
  background-color: #30323a;
  transition: unset !important;
  .select {
    height: 40px;
  }
  .dropdown-menu {
    background-color: #30323a;
    li {
      display: flex;
      padding: 10px 12px !important;
      font-size: 13px !important;
      &:first-child {
        text-transform: capitalize !important;
      }
    }
  }
}
::v-deep .table-wrap {
  .tbl thead {
    background-color: var(--primarycolor);
    z-index: 2;
  }
  th {
    color: var(--buttontext);
  }
  tr.sub-row {
    background-color: #191919;
    border-bottom: 0px;
  }
  table.tbl-export td.short-column {
    svg:hover {
      color: var(--primarycolor);
    }
  }
  td.sub-row {
    &.short-column {
      background-color: #212429;
      border-bottom: 0px;
      border-right: 2px solid var(--primarycolor);
    }
  }
}
.dropdown-menu .exportable-table-wrap {
  height: 400px;
  margin-bottom: 35px;
  overflow: hidden;
  -webkit-transition: height 0.3s ease;
  -moz-transition: height 0.3s ease;
  -ms-transition: height 0.3s ease;
  -o-transition: height 0.3s ease;
  transition: height 0.3s ease;
}
.exportable-table-wrap .flp-panel-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.exportable-table-wrap .dboard-select {
  width: 12rem;
  min-width: 150px;
  height: 42px;
  margin-top: -10px;
  margin-left: 1rem;
  font-size: 14px;
  color: #cad1d6;
  background-color: #30323a;
  border-radius: 4px;
}

.exportable-table-wrap .flp-panel-top .flp-panel-title {
  width: 175px;
  text-align: left;
}
.exportable-table-wrap .flp-controls {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  ::v-deep .btn-size2 {
    padding: 10px;
    //margin-top: 2px !important;
  }
}
.overflowshow {
  overflow: visible;
}
.light-theme .flp-panel {
  color: #212325;
  background-color: #fff;
}

.flp-panel {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  padding: 10px 10px 10px 10px;
  overflow-x: auto;
  overflow-y: hidden;
  color: #fff;
  background-color: #212429;
  border-radius: 3px;
  transition: background-color 0.3s ease;
}
.flp-panel-title {
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.1em;
}
.light-theme {
  .flp-panel-title {
    color: #536176;
    font-weight: 600;
  }
}
.ddselect {
  width: 200px;
  height: 40px;
  margin-left: 1rem;
  background-color: #474a56;
  border-radius: 4px;
}
.light-theme .flp-panel-top {
  margin-bottom: unset;
  border-bottom: unset;
}
.flp-panel-top {
  padding-bottom: 20px;
  margin-bottom: 14px;
  border-bottom: 1px solid #212429;
}
.dboard-select {
  width: 12rem;
  min-width: 150px;
  margin-top: -10px;
  margin-left: 1rem;
  background-color: #474a56;
  border-radius: 4px;
}
.fright {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.ex-close {
  padding: 0 2px 0 8px;
}
.ex-close:hover {
  color: var(--primarycolor);
}
.intip4 {
  position: relative;
  top: 0;
}
.intip4[data-tool-tip]::after {
  position: absolute;
  bottom: 100%;
  left: 0;
  z-index: 99;
  display: block;
  width: 100px;
  padding: 10px 12px;
  margin-left: -50px;
  font-size: 12px;
  line-height: 1.2em;
  color: #5b5b5b;
  text-align: center;
  pointer-events: none;
  content: attr(data-tool-tip);
  background-color: #fdea85;
  border-radius: 3px;
  transition: ease 0.3s;
  transform: scale(0);
  transform-origin: bottom center;
}
::v-deep table.tbl-export.performance-report th,
::v-deep table.tbl-export.performance-report td,
::v-deep table.tbl-export.sales-rollup th,
::v-deep table.tbl-export.sales-rollup td {
  width: 80px !important;
  padding-right: 10px !important;
}
::v-deep table.tbl-export.nonsales-rollup th,
::v-deep table.tbl-export.nonsales-rollup td {
  padding-right: 10px !important;
}
::v-deep tr {
  border-bottom: 1px solid rgba(74, 101, 131, 0.2) !important;
}

.intip4[data-tool-tip]::after {
  width: 140px;
  height: 34px;
  margin-left: -105px;
  font-size: 12px;
  color: #222;
  pointer-events: none;
  background-color: rgba(245, 245, 250, 0.9);
}
.intip4[data-tool-tip]:hover::after {
  bottom: -30px;
  transform: scale(1);
}
.txt-align-center {
  width: 100%;
  font-size: 13px;
  color: #bac0c5;
  text-align: center;
}
.close-btn-container {
  color: #b5b5b5;
  font-size: 24px;
}
.loading {
  pointer-events: none;
  opacity: 0.5;
}
::v-deep .table-wrap {
  .table-scroll {
    width: 100%;
    max-height: 36.5rem !important;
    overflow: auto;
  }
}
.message-container {
  margin-bottom: 10px;
  padding: 10px;
  text-align: center;
  border-radius: 4px;
}
.message.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}
.message.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}
</style>
