var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"tb-expand"},[_c('span',{staticClass:"expd-open",on:{"click":_vm.handleTableExpand}},[_c('font-awesome-icon',{staticClass:"exd-icon",attrs:{"icon":[
          'far',
          `${
            _vm.isTableExpanded
              ? 'arrow-down-left-and-arrow-up-right-to-center'
              : 'arrow-up-right-and-arrow-down-left-from-center'
          }`,
        ]}})],1)]),_c('div',{staticClass:"pnl_title_right_table"},[_c('div',{staticClass:"fright",staticStyle:{"margin-top":"-8px"}},[_c('b-dropdown',{staticClass:"dimension-select",attrs:{"options":_vm.dimensionOptions,"component-id":"dimensionOptionDropdown"},on:{"on-change":_vm.onSelectDimension}}),_c('b-dropdown',{staticClass:"metric-select",attrs:{"options":_vm.metricOptions,"component-id":"metricOptionDropdown","allow-multi-select":true,"show-all":true},on:{"selected-options":_vm.onSelectMetrics}}),_c('k-download-btn',{staticClass:"dwlbtn",class:{ loading: _vm.loading },attrs:{"preparing":_vm.downloadBtn.preparing,"done":_vm.downloadBtn.done},nativeOn:{"click":function($event){return _vm.download()}}})],1)]),_c('div',{staticClass:"pnl_itm_title"},[(!_vm.hasTabs)?_c('div',{staticClass:"pnl_itm_title_txt active"},[_vm._v(_vm._s(_vm.sectionTitle))]):_vm._l((_vm.tabOptions),function(tab,i){return _c('div',{key:i,staticClass:"pnl_itm_title_txt",class:{ active: tab.id === _vm.selectedTab },on:{"click":function($event){return _vm.onTabClick(tab)}}},[_vm._v(" "+_vm._s(tab.value)+" ")])})],2),_c('div',{ref:"table-scroll",staticClass:"panel_scroll_wrap table-scroll rpt-builder",class:_vm.isTableExpanded ? 'reactive-ht' : 'height499',staticStyle:{"overflow-x":"scroll"}},[(!_vm.loading && _vm.rows.length)?_c('div',{staticClass:"tg-wrap"},[_c('table',{staticClass:"tg hm_table",attrs:{"id":"tg-wqhPh"}},[_c('thead',[_c('tr',_vm._l((_vm.metaData),function(col,j){return _c('th',{key:j,ref:`${j}_metadata`,refInFor:true,staticClass:"tg-ul38 th-title-tt",class:{
                'tg-sort-header': _vm.allowSorting,
                firstCol: j === 0,
                firstColExpanded: j === 0 && _vm.isTableExpanded,
                rt: col.align === 'right',
                ctr: col.align === 'center',
                'tg-sort-asc': _vm.allowSorting && _vm.sortBy === col.key && _vm.sortDirection === 'desc',
                'tg-sort-desc': _vm.allowSorting && _vm.sortBy === col.key && _vm.sortDirection === 'asc',
                sticky: j < _vm.stickyColumnCount, // Apply sticky class dynamically
                'last-sticky': j === _vm.stickyColumnCount - 1,
              },style:(j < _vm.stickyColumnCount
                  ? {
                      left: `${j === 0 ? 0 : 274 + (j - 1) * 100}px`,
                      minWidth: j === 0 ? '274px' : '100px',
                      maxWidth: j === 0 ? '274px' : 'auto',
                    }
                  : {}),on:{"click":function($event){return _vm.clickHandler(col)},"mouseover":(e) => _vm.handleMouseOver(e, col.value, `${j}_metadata`, true, true),"mouseleave":_vm.handleMouseLeave}},[_vm._v(" "+_vm._s(col.value)+" ")])}),0)]),_c('tbody',_vm._l((_vm.sortedRows),function(row,i){return _c('tr',{key:i,class:{ rowhover: i === _vm.hoveredRowIndex },style:({ position: 'relative' }),attrs:{"id":i},on:{"mouseover":function($event){return _vm.handleMouseOverRow(i)},"mouseleave":_vm.handleMouseLeaveRow}},_vm._l((_vm.metaData),function(col,j){return _c('td',{key:j,ref:`${i}_${j}_metadata`,refInFor:true,staticClass:"tg-0lax",class:{
                firstCol: j === 0,
                firstColExpanded: j === 0 && _vm.isTableExpanded,
                rt: col.align === 'right',
                sticky: j < _vm.stickyColumnCount, // Apply sticky class dynamically
                'last-sticky': j === _vm.stickyColumnCount - 1,
              },style:(j < _vm.stickyColumnCount
                  ? {
                      left: `${j === 0 ? 0 : 274 + (j - 1) * 100}px`,
                      minWidth: j === 0 ? '274px' : '100px',
                      maxWidth: j === 0 ? '274px' : 'auto',
                    }
                  : {}),on:{"mouseover":(el) =>
                  _vm.handleMouseOver(
                    el,
                    _vm.formattedColumnValue(row, col),
                    `${i}_${j}_metadata`,
                    j === 0
                  ),"mouseleave":_vm.handleMouseLeave}},[(col.formatter && col.formatter.type === 'pacing')?_c('pacing-chart',{key:row[col.key],attrs:{"pacing":row[col.key]}}):(col.formatter && col.formatter.type === 'status')?_c('div',{key:row[col.key],staticClass:"tgs-status",class:`tgs-${row[col.key].toLowerCase()}`},[_vm._v(" "+_vm._s(row[col.key])+" ")]):[_vm._v(" "+_vm._s(_vm.formattedColumnValue(row, col))+" ")]],2)}),0)}),0)])]):_vm._e(),_c('b-tooltip',{attrs:{"tooltip-text":`${_vm.activeEl.text}`,"visible-style":{ ..._vm.activeEl.style, zIndex: 9999 }}}),(!_vm.loading && !_vm.rows.length)?_c('div',{staticClass:"txt-center",staticStyle:{"margin-top":"13px","font-size":"14px !important"}},[_vm._v(" No data is available to show ")]):_vm._e(),_c('div',{staticClass:"spinner"},[(_vm.loading)?_c('b-loading-spinner',{staticClass:"txt-center"}):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }