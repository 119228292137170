<template>
  <b-flip-modal
    :header="true"
    :header-text="customHeading"
    :width="width"
    :is-self-serve-modal="true"
    @close="onClickCancel"
  >
    <div slot="modal-body" class="modal-wrapper-box">
      <div class="modal-container">
        <div class="file-form-group">
          <div class="file-uploader-base">
            <div
              v-if="!fileToUpload"
              :class="{ dragging: dragging, error: fileError.fileToUpload }"
              class="file-uploader-container"
              @drop.capture.prevent.stop="onFileChange($event, 'fileToUpload')"
              @dragenter.prevent="dragging = true"
              @dragend.prevent="dragging = false"
              @dragleave.prevent="dragging = false"
              @dragover.prevent
            >
              <label class="file-input-label" for="file-input"
                ><font-awesome-icon :icon="['far', 'cloud-upload-alt']" />&nbsp;&nbsp;
                <span class="browse-txt">Drag and drop here&nbsp;&nbsp;or&nbsp;&nbsp;</span>
                <span class="browse-btn">BROWSE</span><br /><br />Recommended size is 380 x 60
                (pixels width by height)<br /><br />
                PNG/Transparent Background only</label
              >
              <input
                id="file-input"
                class="file-input"
                type="file"
                name="test"
                @change="onFileChange($event, 'fileToUpload')"
              />
            </div>
            <div v-if="!!fileError.fileToUpload" class="error file-error-msg">
              {{ fileError.fileToUpload }}
            </div>
            <div v-if="!!fileToUpload" class="img-preview-container">
              <div class="img-container">
                <div
                  v-if="!loading"
                  class="cross-icon-container"
                  @click.stop="handleFileReset('fileToUpload')"
                >
                  <font-awesome-icon class="fa-icon-table" :icon="['fal', 'times']" size="1x" />
                </div>
                <img :src="fileToUpload.src" />
                <div v-if="uploadPercentage.fileToUpload" class="upload-overlay">
                  <div class="progress" :style="`width: ${uploadPercentage.fileToUpload}%;`"></div>
                  <div
                    class="rest"
                    :style="`width: ${100 - uploadPercentage.fileToUpload}%;`"
                  ></div>
                  <div class="text">{{ uploadPercentage.fileToUpload }}%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div slot="modal-footer" class="action-ftr">
      <div class="actions-btn">
        <k-button v-if="isRemove" :size="3" label="SAVE" class="mr-20" @click="onClickSaveFile" />
        <k-button v-if="isCancel" :size="3" label="CLOSE" class="mr-20" @click="onClickCancel" />
      </div>
    </div>
  </b-flip-modal>
</template>

<script>
import forklift from '~/components/mixins/forklift-mixin';

export default {
  name: 'FileUploadModal',
  components: {
    BFlipModal: () =>
      import(/* webpackChunkName: "b-flip-modal" */ '~/components/elements/b-flip-modal.vue'),
  },
  mixins: [forklift],
  props: {
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: false,
      default: () => '700px',
    },
    customHeading: {
      type: String,
      required: false,
      default: () => '',
    },
    isCancel: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRemove: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      fileToUpload: null,
      fileError: {},
      dragging: false,
      loading: false,
      uploadPercentage: {},
    };
  },
  methods: {
    handleFileReset(fileType) {
      this[fileType] = null;
    },
    onFileChange(ev, fileType) {
      const validImageTypes =
        fileType === 'favIconLogoFile'
          ? ['image/png', 'image/x-icon', 'image/vnd.microsoft.icon']
          : ['image/png'];

      const files = ev.target.files || ev.dataTransfer?.files;
      if (!files || files.length === 0) {
        console.error('No files detected.');
        return;
      }

      const fileToUpload = files[0];
      if (fileToUpload) {
        if (validImageTypes.includes(fileToUpload.type)) {
          this.fileError[fileType] = '';
          this[fileType] = {
            file: fileToUpload,
            src: URL.createObjectURL(fileToUpload),
          };
          // Clear input value for re-upload
          if (ev.target?.files) ev.target.value = '';
        } else {
          this.fileError[fileType] = 'Only PNG files are supported';
        }
      } else {
        this.fileError[fileType] = 'File selection error';
      }
      this.fileError = { ...this.fileError }; // Ensure reactivity
      this.dragging = false; // Reset dragging state
    },
    onClickCancel(ev) {
      this.$emit('cancel', ev);
    },
    onClickSaveFile() {
      this.$emit('save-file', this.fileToUpload);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .btn-outline {
  border: none !important;
}
::v-deep .btn-outline .k-btn.btn-size-3 {
  background-color: transparent;
  border: 1px solid #d6d6d6;
  color: #555 !important;
  span {
    color: #555 !important;
    &:hover {
      color: #fff !important;
    }
  }
}
::v-deep .k-btn.btn-size-3 span.label {
  font-size: 12px !important;
  font-family: 'Manrope';
}
.mr-20 {
  margin-right: 20px;
}
::v-deep .modal-wrapper.selfServBox {
  width: 700px;
}
::v-deep .modal-wrapper.selfServBox .modal-wrapper-box {
  padding-bottom: 100px;
}
::v-deep .selfServBox .modal-container .modal-footer {
  background-color: #eef3f6 !important;
  height: 80px !important;
  text-align: left !important;
  padding: 20px 28px !important;
  border-radius: 0 0 7px 7px !important;
}
::v-deep .cdp-top.selfServeContainer {
  height: 68px !important;
  padding: 20px 28px !important;
  border-bottom: 1px solid #e9eaeb !important;
}

.file-uploader-base {
  flex: 1;
}

.file-uploader-container {
  border: 2px dashed #888;
  flex: 1;
  margin-bottom: 15px;

  &.error {
    border-color: #ff0202;
  }

  &.dragging {
    border: 1px dashed #fff;
    color: #fff;
  }

  .file-input {
    display: none;
  }

  .file-input-label {
    padding: 53px 10px;
    display: block;
    cursor: pointer;

    svg {
      font-size: 20px !important;
    }
  }
}

.file-error-msg {
  text-align: left;
}

.browse-txt {
  font-weight: 600;
  font-size: 14px;
}

.browse-btn {
  padding: 9px 19px;
  line-height: 22px;
  background-color: var(--adminprimarycolor);
  font-size: 12px;
  font-weight: 600;
  color: var(--adminovertext) !important;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  cursor: pointer;
  border-radius: 3px;
  margin: 0 5px;
}

.file-form-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .file-group-label {
    width: 31%;
    display: inline-block;
    font-size: 0.875rem;
    color: #cad1d6;
    letter-spacing: 0.07em;
    padding: 9px;
    line-height: 20px;
  }

  .file-uploader-base {
    flex: 1;
  }

  .file-uploader-container {
    border: 2px dashed #888;
    flex: 1;
    margin-bottom: 15px;

    &.error {
      border-color: #ff0202;
    }

    &.dragging {
      border: 1px dashed #fff;
      color: #fff;
    }

    .file-input {
      display: none;
    }

    .file-input-label {
      padding: 53px 10px;
      display: block;
      cursor: pointer;

      svg {
        font-size: 20px !important;
      }
    }
  }

  .file-error-msg {
    text-align: left;
  }
}

.img-preview-container {
  text-align: left;
  margin-top: 20px;

  .img-container {
    height: 60px;
    display: inline-block;
    position: relative;
    background-color: #fff;

    .cross-icon-container {
      width: 15px;
      height: 15px;
      position: absolute;
      top: -7px;
      right: -7px;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
    }

    img {
      height: 100%;
      width: auto;
    }

    .upload-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: row;

      .progress {
        height: 100%;
        background-color: rgba(256, 256, 256, 0.8);
      }

      .rest {
        height: 100%;
        background-color: rgba(256, 256, 256, 0.2);
      }

      .text {
        font-size: 16px;
        color: #000;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>
