<template>
  <div>
    <div class="pnl_itm_title">
      <div v-if="!hasTabs" class="pnl_itm_title_txt active">{{ sectionTitle }}</div>
      <template v-else>
        <div
          v-for="(tab, i) in tabOptions"
          :key="i"
          class="pnl_itm_title_txt"
          :class="{ active: tab.id === selectedTab }"
          @click="onTabClick(tab)"
        >
          {{ tab.value }}
        </div>
      </template>
      <div class="pnl_title_right">
        <div class="pnl_menu_btn" @click="showOptions = true">
          <font-awesome-icon :icon="['fas', 'arrow-down-to-line']" />
        </div>
        <!-- Options Modal -->
        <div v-if="showOptions" class="download-options-modal">
          <div class="modal-header">
            <button class="close-btn" @click="showOptions = false">X</button>
          </div>
          <div class="options-content">
            <p>Download as:</p>
            <button
              class="option-btn"
              @click="handleDownload('pdf', 'metricDistribution_Container')"
            >
              PDF
            </button>
            <button class="option-btn" @click="handleDownload('csv')">CSV</button>
          </div>
        </div>
      </div>
    </div>
    <div class="sm_pnl_subtop_wrap" style="margin-bottom: 6px">
      <div v-if="!hideDimensionOptions" class="sm_pnl_subtop sbt_left">
        <b-dropdown-flat
          :options="dimensionOptions"
          component-id="dimensionOptionsDropdown"
          @on-change="onSelectDimension"
        />
      </div>
      <div v-if="!hideDimensionOptions" class="sm_pnl_subtop sbt_right">
        <b-dropdown-flat
          :options="metricOptions"
          component-id="metricOptionsDropdown"
          @on-change="onSelectMetric"
        />
      </div>
      <div v-if="hideDimensionOptions" class="sm_pnl_subtop" style="width: 100%">
        <b-dropdown-flat
          :options="metricOptions"
          component-id="metricOptionsDropdown"
          @on-change="onSelectMetric"
        />
      </div>
    </div>
    <horizontal-bar-chart
      v-if="!loading && columns.length && displayChart === 'bar'"
      key="sortedColumns.length"
      ref="horizontalChart"
      :rows="sortedColumns"
      :value-type="valueFormat"
      :height="240"
      :width="629.5"
    />
    <div v-if="!loading && columns.length && displayChart === 'bar'" class="sol_legend">
      <div :id="tooltipId" class="panel_scroll_wrap" style="padding-right: 0px;">
        <div v-for="(row, i) in sortedRows" :key="i" class="sol_leg_item">
          <div class="sol_leg_clr" :style="`background-color: ${COLOR_PATTERN[i]};`"></div>
          <div
            id="metric_sol_leg_title"
            :ref="`${i}_metadata`"
            class="sol_leg_title"
            @mouseover="() => handleMouseOver(i)"
            @mouseleave="handleMouseLeave"
          >
            {{ row.key }}
          </div>
          <div id="metric_sol_leg_value" class="sol_leg_value">
            {{ formatNumber(row.value, valueFormat, decimals) }}
          </div>
        </div>
      </div>
      <b-tooltip
        v-if="!loading && columns.length && displayChart === 'bar'"
        :tooltip-text="`${getFullLegendName(sortedRows[activeEl.index])}`"
        :visible-style="activeEl.style"
      />
    </div>
    <multi-country-map-chart
      v-if="!loading && columns.length && displayChart === 'geo'"
      :map-country="mapCountry"
      :map-type="mapType"
      :map-data="columns"
      :value-key="selectedMetricLabel"
      :value-type="valueFormat"
      :legend-value-format="legendValueFormat"
    />
    <div v-if="!loading && !columns.length" class="txt-center">
      No data is available to show
    </div>
    <b-loading-spinner v-if="loading" class="txt-center" />
  </div>
</template>
<script>
import { get } from 'vuex-pathify';
import { uuid } from '@/helpers/global/misc-helpers';
import { COLOR_PATTERN } from '@/constant';
import { pageExportToPDF } from '@/util/utility-functions';
import BDropdownFlat from '~/components/elements/b-dropdown-flat.vue';
import BTooltip from '~/components/elements/b-tooltip.vue';
import * as util from '~/util/utility-functions';
import formatNumber from '~/util/numeral';

export default {
  components: {
    BDropdownFlat,
    BTooltip,
    HorizontalBarChart: () =>
      import(
        /* webpackChunkName: "horizontal-bar-chart" */ '~/components/charts/horizontal-bar-chart.vue'
      ),
    MultiCountryMapChart: () =>
      import(
        /* webpackChunkName: "multi-country-map-chart" */ '~/components/charts/multi-country-map-chart.vue'
      ),
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
  },
  props: {
    sectionTitle: {
      type: String,
      required: true,
    },
    dimensionOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    dimensionOptionsObj: {
      type: Array,
      required: false,
      default: () => [],
    },
    metricOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    rows: {
      type: Array,
      required: true,
    },
    tabOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    dimension: {
      type: String,
      required: false,
      default: () => '',
    },
    hideDimensionOptions: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    let selectedDimension = '';
    if (this.dimensionOptions?.length) {
      const defaultSelectedDimension = this.dimensionOptions.find((o) => o.selected);
      selectedDimension = defaultSelectedDimension?.id || this.dimensionOptions[0].id;
    }

    const selectedMetric = this.metricOptions?.length
      ? this.metricOptions[0].id || this.metricOptions[0].toLowerCase()
      : '';
    let selectedTab = '';
    if (this.tabOptions?.length) {
      const defaultSelectedTab = this.tabOptions.find((o) => o.selected);
      selectedTab = defaultSelectedTab?.id || this.tabOptions[0].id;
    }

    return {
      decimals: '00',
      selectedDimension,
      selectedMetric,
      selectedTab,
      formatNumber,
      COLOR_PATTERN,
      activeEl: { style: { display: 'none', visibility: 'hidden' } },
      tooltipId: uuid(),
      pageExportToPDF,
      showOptions: false,
    };
  },
  computed: {
    account: get('common/account'),
    hasTabs() {
      return this.tabOptions.length;
    },
    valueFormat() {
      return util.getValueFormat(this.selectedMetric);
    },
    mapCountry() {
      return this.account?.countryCode?.toUpperCase() || 'US';
    },
    mapType() {
      return this.selectedTab === 'country' ? 'world' : this.selectedTab;
    },
    legendValueFormat() {
      let format;
      if (this.selectedMetric === 'ctr') {
        format = '0.[00]%';
      } else if (this.selectedMetric === 'vcr') {
        format = '0.[0]%';
      } else {
        format = '$0.[00]';
      }
      return format;
    },
    displayChart() {
      return ['country', 'state', 'dma'].includes(this.selectedTab) ? 'geo' : 'bar';
    },
    selectedMetricLabel() {
      const metric = this.metricOptions?.find((o) => o.id === this.selectedMetric);
      return metric.value || '';
    },
    columns() {
      const nColumns = [];
      this.rows.forEach((r) => {
        if (['country', 'state', 'dma'].includes(this.selectedTab)) {
          nColumns.push({ location: r.key, tally: r.value });
        } else {
          nColumns.push([r.key, r.value]);
        }
      });
      return nColumns;
    },
    sortedRows() {
      const rows = [...this.rows];
      const sortedRows = rows.sort((a, b) => {
        if (a.value < b.value) {
          return -1;
        }
        if (a.value > b.value) {
          return 1;
        }
        return 0;
      });
      return sortedRows;
    },
    sortedColumns() {
      const nColumns = [];
      this.sortedRows.forEach((r) => {
        nColumns.push([r.key, r.value]);
      });
      return nColumns;
    },
  },
  watch: {
    tabOptions(newTabOptions) {
      this.selectedTab = '';
      if (newTabOptions?.length) {
        const defaultSelectedTab = newTabOptions.find((t) => t.selected);
        this.selectedTab = defaultSelectedTab?.id || newTabOptions[0].id;
      }
    },
    dimensionOptions(newDimensionOptions) {
      this.selectedDimension = '';
      if (newDimensionOptions?.length) {
        const defaultSelectedDimension = newDimensionOptions.find((o) => o.selected);
        this.selectedDimension = defaultSelectedDimension?.id || newDimensionOptions[0].id;
      }
    },
    metricOptions(newMetricOptions) {
      this.selectedMetric = newMetricOptions?.length
        ? newMetricOptions[0].id || newMetricOptions[0].toLowerCase()
        : '';
    },
  },
  methods: {
    getFullLegendName(name) {
      if (name) {
        return name.key;
      }
      return '';
    },
    handleMouseOver(i) {
      const ulElement = document.getElementById(this.tooltipId);
      // const elementPos = this.$refs[refKey][0];
      // if (!(elementPos.offsetWidth < elementPos.scrollWidth)) {
      //   return;
      // }
      this.activeEl = {
        style: {
          top: `${(i - 0.5) * 30 - ulElement.scrollTop + i}px`,
          left: `${50}px`,
          display: 'block',
          opacity: '0.96',
          visibility: 'visible',
          'max-width': '500px',
          width: 'max-content',
        },
        index: i,
      };
    },
    handleMouseLeave() {
      this.activeEl = { style: { display: 'none', visibility: 'hidden' }, index: -1 };
    },
    getDimensionForExport() {
      let dimension = this.selectedDimension;
      if (this.hideDimensionOptions) {
        if (this.hasTabs) {
          dimension =
            this.tabOptions.filter((item) => item.id === this.selectedTab)[0].value ||
            this.selectedTab ||
            '';
        } else {
          const selectedOption = this.dimensionOptionsObj.find(
            (item) => item.id === this.dimension
          );

          dimension = selectedOption
            ? selectedOption.headerTitle || selectedOption.value
            : this.dimension;
        }
      }
      // dimension = METRIC_DISTRIBUTION_DIMENSION_TITLES[dimension] || dimension;
      return dimension;
    },
    adjustChartStylesBeforePDF(removeStyles = true) {
      const chartComponent = this.$refs.horizontalChart;
      if (chartComponent) {
        chartComponent.modifyChartStyles(removeStyles);
      }
    },
    handleDownload(format, elementId = '') {
      this.showOptions = false;
      if (format === 'csv') {
        this.handleExportClick();
      } else {
        // Adjust styles before exporting
        this.adjustChartStylesBeforePDF(true);
        // Get the div with class 'panel_scroll_wrap' and remove max height
        const panelScrollWrap = document.querySelector('.panel_scroll_wrap');
        if (panelScrollWrap) {
          panelScrollWrap.style.maxHeight = 'none';
        }
        this.pageExportToPDF(elementId);
        // Restore styles after exporting
        this.adjustChartStylesBeforePDF(false);
        // Get the div with class 'panel_scroll_wrap' and add max height
        if (panelScrollWrap) {
          panelScrollWrap.style.maxHeight = '160px'; // Adjust the value as per your requirement
        }
      }
    },
    handleExportClick() {
      const dimension = this.getDimensionForExport();
      const header = [dimension, this.selectedMetric];
      const lines = [];
      this.rows.forEach((r) => {
        lines.push([r.key, r.value]);
      });
      const csvData = [header, ...lines];
      const fileName = `export_${this.selectedMetric}_by_${dimension}.csv`;
      this.$emit('on-export', fileName, csvData);
    },
    onSelectDimension(option) {
      this.selectedDimension = option;
      this.$emit(
        'on-options-change',
        this.selectedTab,
        this.selectedDimension,
        this.selectedMetric
      );
    },
    onSelectMetric(option) {
      this.selectedMetric = option;
      this.$emit(
        'on-options-change',
        this.selectedTab,
        this.selectedDimension,
        this.selectedMetric
      );
    },
    onTabClick(tab) {
      this.selectedTab = tab.id;
      this.$emit(
        'on-options-change',
        this.selectedTab,
        this.selectedDimension,
        this.selectedMetric
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.pnl_itm_title {
  border-bottom: 1px solid #e0e5ee;
  padding-bottom: 0px;
  white-space: nowrap;
}

.pnl_itm_title_txt {
  display: inline-block;
  position: relative;
  border-bottom: 0px solid;
  padding-bottom: 15px;
  top: 2px;
  z-index: 9;
  color: var(--primarylight);
  font-size: 1.02em;
  font-weight: 600;
  margin-right: 25px;
  cursor: pointer;
}
.pnl_itm_title_txt:hover {
  color: var(--primarydark);
}
.pnl_itm_title_txt.active {
  display: inline-block;
  position: relative;
  border-bottom: 3px solid var(--primarycolor);
  padding-bottom: 15px;
  top: 2px;
  z-index: 9;
  font-weight: 600;
  color: var(--primarydark);
}

.pnl_title_right {
  display: inline-block;
  width: 250px;
  text-align: right;
  float: right;
  margin-top: -6px;
  position: relative;
}
.one-thirds .pnl_title_right {
  width: 30px;
}

.pnl_menu_btn {
  display: inline-block;
  position: relative;
  vertical-align: top;
  padding: 7px;
  margin-right: -7px;
  font-size: 16px;
  color: var(--primarylight);
}
.pnl_menu_btn:hover {
  color: var(--primarycolor);
}

/*small panel under title dropdown*/
.sm_pnl_subtop {
  margin: 0px;
  vertical-align: top;
}

.sm_pnl_subtop_wrap {
  border-bottom: 1px solid #e4e6ec;
  margin-bottom: 0px;
  white-space: nowrap;
}

::v-deep .sm_pnl_subtop .dropdown,
::v-deep .sm_pnl_subtop .multiselect-dropdown {
  width: 100%;
  max-width: 100%;
  min-width: auto;
  border: 0px;
  border-radius: 0px;
  margin-bottom: 0px;
  vertical-align: bottom;
  font-size: 0.93em;
}
::v-deep .sm_pnl_subtop .dropdown .select,
::v-deep .sm_pnl_subtop .multiselect-dropdown .select {
  padding: 9px 20px 9px 1px;
}
::v-deep .sm_pnl_subtop .dropdown .select:focus-visible,
::v-deep .sm_pnl_subtop .multiselect-dropdown .select:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
  outline: none;
}

::v-deep .sm_pnl_subtop .dropdown:focus-visible,
::v-deep .sm_pnl_subtop .multiselect-dropdown:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
  outline: none;
}

::v-deep .sm_pnl_subtop .droparw {
  right: inherit;
  margin-left: 8px;
}

.sbt_right {
  display: inline-block;
  width: 49%;
}
.sbt_left {
  display: inline-block;
  width: 49%;
}
::v-deep .sm_pnl_subtop .dropdown.rt .select {
  padding: 9px 30px 9px 10px;
}
::v-deep .dropdown.rt .dropdown-menu li,
::v-deep .dropdown.rt .dropdown-menu-multi li {
  padding: 5px 20px 5px 10px;
}

::v-deep .sbt_left .droparw {
  right: inherit;
  margin-left: 8px;
}

::v-deep .sbt_left .top-span {
  margin-right: 10px;
}

::v-deep .sbt_right .dropdown {
  text-align: right;
}

::v-deep .sbt_right .dropdown .select {
  padding: 9px 0px 9px 1px;
}
::v-deep .sbt_right .dropdown .dropdown-menu li,
::v-deep .sbt_right .dropdown .dropdown-menu-multi li {
  padding: 5px 20px 5px 10px;
}

::v-deep .sbt_right .dropdown .dropdown-menu li.dropdown-content-selected .norm-option-item {
  width: 100%;
}

.sbm_i {
  margin-left: 8px;
  margin-right: 4px;
}

::v-deep .sm_pnl_subtop.sbt_right .dropdown .dropdown-menu-multi {
  min-width: 180px;
  margin-left: -54px;
  text-align: left;
}

::v-deep .sm_pnl_subtop.sbt_right .dropdown-menu-multi .mdrp-sub-itms .drop-ck {
  left: 17px;
  top: 11px;
}
.sm_pnl_subtop.sbt_right .mdrp-sub {
  padding: 8px 9px 8px 40px !important;
}

.sm_pnl_subtop .subtop_title_txt {
  font-size: 13px;
  font-weight: 500;
  color: var(--primarydark2);
  padding: 9px 10px;
  vertical-align: top;
}
.sm_pnl_subtop.sbt_right .subtop_title_txt {
  text-align: right;
}

#chart {
  width: 100%;
  margin: auto;
  margin-top: -8px;
  margin-left: 10px;
  left: -9px;
  text-align: center;

  /* background: #2f323a; */
  background: none transparent;
  /* width: 90%; */
  margin: 0px;
  width: 100%;
  left: 3%;
}
.c3-tooltip-container {
  text-align: left;
  line-height: 1.1em;
}

.panel_scroll_wrap {
  max-height: 160px;
  overflow-y: scroll;
  height: 100%;
  padding-right: 10px;
  overflow-x: hidden;
}

.sol_legend {
  position: relative;
  top: 8px;
  padding-bottom: 10px;
}

.sol_leg_item {
  font-size: 13px;
  padding: 5px 0px;
  text-align: justify;
}

.sol_leg_clr {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-color: #ddd;
  border-radius: 4px;
  margin-right: 11px;
  position: relative;
  vertical-align: middle;
}

.sol_leg_title {
  display: inline-block;
  width: calc(100% - 100px);
  font-weight: 700;
  vertical-align: middle;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sol_leg_value {
  display: inline-block;
  font-weight: 700;
  width: 67px;
  text-align: right;
  vertical-align: middle;
}

.download-options-modal {
  position: absolute;
  top: 200%;
  left: 50%;
  transform: translate(-20%, -30%);
  background-color: #fff;
  padding: 8px 14px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 3;
  text-align: justify;
}

.options-content p {
  padding: 4px;
  font-size: 14px;
  color: #333;
}

.options-content button {
  margin: 5px;
  padding: 8px 15px;
  font-size: 14px;
  background-color: #00abb7;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.options-content button:hover {
  background-color: #007b8f;
}
.option-btn {
  padding: 10px;
}
.close-btn {
  background: white;
}

.modal-header {
  text-align: right;
}
</style>
