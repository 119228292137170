/* eslint-disable func-names */
/**
 * Provides Authorization-related functionality, primarily via computed props
 * which define what the current user can do via their roles in the current
 * account.
 *
 * This mixin is just a thin layer on top of the authz module which does all
 * the heavy lifting. It is not meant to contain the actual logic itself, so
 * that it can be refactored later, as necessary.
 */

import { get } from 'vuex-pathify';
import _ from 'underscore';

import authz from 'adready-api/authz';
import {
  AI_ASSISTANT_FULL_PLATFORM,
  AI_ASSISTANT_REPORT_BUILDER,
  REPORT_BUILDER_2,
  SEGMENT_SEARCH_ID,
} from '@/constant';

const computed = {
  currentUser: get('common/currentUser'),

  currentAccountRoles() {
    if (this.currentAccountId) {
      return this.$store.getters['common/getAccountRoles'](this.currentAccountId);
    }
    return null;
  },

  permissions() {
    if (this.currentUser && this.currentUser.role && this.currentUser.role.permissions) {
      return this.currentUser.role.permissions;
    }
    return [];
  },

  currentUserRole() {
    if (this.currentUser && this.currentUser.role) {
      return this.currentUser.role.name;
    }
    return undefined;
  },

  isAdmin() {
    return authz.isAdmin(this.currentUser);
  },

  hasSegmentToolAccess() {
    return this.currentUser?.userApplications?.some(
      (app) => app.applicationId === SEGMENT_SEARCH_ID
    );
  },

  hasReportBuilder2Access() {
    return this.currentUser?.userApplications?.some(
      (app) => app.applicationId === REPORT_BUILDER_2
    );
  },

  hasAIAssistantReportBuilderAccess() {
    return this.currentUser?.userApplications?.some(
      (app) => app.applicationId === AI_ASSISTANT_REPORT_BUILDER
    );
  },

  hasAIAssistantFullPlatformAccess() {
    return this.currentUser?.userApplications?.some(
      (app) => app.applicationId === AI_ASSISTANT_FULL_PLATFORM
    );
  },
};

// Create methods which simply call the same method in the authz package
[
  'isAccountManager',
  'isClientAdmin',
  'isClientManager',
  'isClientUser',
  'isClientFacing',
  'isAccountAdmin',
  'isInternalAdmin',
  'isInternalSubAdmin',
  'isInternalUser',
  'isExternalSubAdmin',
  'isExternalUser',
  'isExternalAdmin',
].forEach(function(method) {
  computed[method] = function() {
    return authz[method](this.currentUserRole);
  };
});

const methods = {
  hasPermission(entity, privilege) {
    if (this.permissions) {
      if (_.has(privilege, 'length')) {
        return this.permissions.some((p) => p.entity === entity && privilege.includes(p.privilege));
      }

      return this.permissions.some(
        (p) => p.entity === entity && p.privilege === privilege.toUpperCase()
      );
    }
    return false;
  },
};

export default {
  computed,
  methods,
};
