<template>
  <b-flip-modal
    :header="true"
    :enable-click-out-side="false"
    :header-text="'Delete Report'"
    :is-self-serve-modal="true"
    :is-new-reports-builder="true"
    @close="onClickCancel"
  >
    <div slot="modal-body" class="modal-wrapper-box">
      <div v-if="!loading" class="modal-container-wrap">
        <div class="modal-description-container">{{ description }}</div>
      </div>
      <b-loading-spinner v-if="loading" class="txt-center" />
    </div>
    <div slot="modal-footer" class="action-ftr">
      <div class="actions-btn" :class="{ disabled: loading }">
        <k-button :size="3" label="YES" class="mr-20" @click="onDeleteTemplate" />
        <k-button :size="3" label="NO" class="mr-20" @click="onClickCancel" />
      </div>
    </div>
  </b-flip-modal>
</template>

<script>
export default {
  name: 'DeleteSavedReportConfirmModal',
  components: {
    BFlipModal: () =>
      import(/* webpackChunkName: "b-modal" */ '@/components/elements/b-flip-modal.vue'),
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '@/components/elements/b-loading-spinner2.vue'
      ),
  },
  props: {
    description: {
      type: String,
      required: true,
    },
    loading: { type: Boolean, default: false },
  },
  methods: {
    onClickCancel(ev) {
      this.$emit('cancel', ev);
    },
    onDeleteTemplate(ev) {
      this.$emit('change', ev);
    },
  },
};
</script>
<style lang="scss" scoped>
.show-delete-template-popup {
  .modal-mask.selfServMask {
    position: fixed;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    z-index: 99999999;
    width: 100%;
    height: 100%;
    background-color: rgba(44, 46, 56, 0.95) !important;
    transition: opacity 0.3s ease-in-out;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px) !important;
  }
}
::v-deep .selfServBox.event-box .modal-container {
  min-height: 30px !important;
  /* min-width: 300px !important; */
  max-width: unset !important;
  min-width: unset !important;
  width: 100% !important;
  color: #212325 !important;
  box-shadow: 0 14px 35px -12px rgba(0, 40, 100, 0.17) !important;
  background-color: #fff !important;
  transition: background-color 0.3s ease !important;
}
::v-deep .selfServBox:not(.event-box) .modal-container {
  min-height: 30px !important;
  /* min-width: 300px !important; */
  max-width: unset !important;
  min-width: unset !important;
  width: 100% !important;
  color: #a1a5af !important;
  background-color: #212429 !important;
}
::v-deep .selfServBox .modal-wrapper-box {
  display: flex;
  align-items: center;
  padding-bottom: 0;
  padding: 0px 28px 45px !important;
  text-align: center !important;
}
.modal-description-container {
  font-size: inherit;
}
::v-deep .k-btn {
  display: inline-block;
  min-width: 75px;
  font-weight: 600;
  color: #303335 !important;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  background-color: var(--primarycolor);
  border-radius: 3px;
  appearance: none;
  margin-bottom: 25px;
}
::v-deep .btn-outline {
  border: none !important;
}
::v-deep .btn-outline .k-btn.btn-size-3 {
  background-color: transparent;
  border: 1px solid #d6d6d6;
  color: #555 !important;
  span {
    color: #555 !important;
    &:hover {
      color: #fff !important;
    }
  }
}
::v-deep .k-btn.btn-size-3 span.label {
  font-size: 12px !important;
}

.mr-20 {
  margin-right: 20px;
}
::v-deep .selfServBox .modal-container .modal-footer {
  height: 80px !important;
  padding: 20px 28px !important;
  border-radius: 0 0 7px 7px !important;
  background-color: unset !important;
  text-align: center !important;
}
.actions-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  .k-btn {
    .label {
      font-weight: 600;
    }
  }
  .cancel-btn {
    .k-btn {
      &:hover {
        .label {
          color: #fff !important;
          border-color: #48525c !important;
        }
      }
      border: 1px solid var(--primarycolor) !important;
      background-color: transparent;
      .label {
        color: var(--primarycolor);
      }
    }
  }
}
.modal-container-wrap {
  max-width: 350px !important;
  min-width: 350px !important;
}
.txt-center {
  text-align: center;
  max-width: 350px !important;
}
::v-deep .k-btn {
  color: white !important;
}
</style>
