<template>
  <div>
    <div>
      <div v-if="selectedMenuOption !== LAYOUT_TYPES.ADMIN">
        <div class="container trans-effect" :class="showFilters ? 'sideopen' : ''">
          <b-filter-top-bar
            v-if="!isMniDashboard"
            :hide-report-builder-popup="false"
            :is-all-events-performance-selected="isAllEventsPerformanceSelected"
            :is-advance-filter-in-overview="isAdvanceFilterInOverview"
          />
          <ExportTable
            id="report-builder-table"
            :is-page-view="true"
            :class="isMniDashboard ? 'mni-report-content' : ''"
            @on-all-events-performance-selected="onAllEventsPerformanceSelected"
          />
          <!-- <b-filter-left-section /> -->
        </div>
        <div v-if="showEventChangePopup.visible" class="show-event-change-popup">
          <EventConfirmModal
            :description="showEventChangePopup.description"
            @cancel="onCloseEventChangePopup"
            @change="onChangeEventChangePopup"
          />
        </div>
      </div>
      <Admin v-if="selectedMenuOption === LAYOUT_TYPES.ADMIN" />
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';

import EventConfirmModal from './EventConfirmModal.vue';
import { getQueryParams } from '~/helpers/global/url-helpers';
import { LAYOUT_TYPES } from '~/constant';

export default {
  components: {
    ExportTable: () =>
      import(
        /* webpackChunkName: "dash-export-table" */ '~/components/dash-panels/export/table.vue'
      ),
    EventConfirmModal,
    BFilterTopBar: () =>
      import(
        /* webpackChunkName: "b-filter-top-bar" */ '~/components/elements/b-filter-top-option-bar.vue'
      ),
    // BFilterLeftSection: () =>
    //   import(
    //     /* webpackChunkName: "b-filter-left-section" */ '~/components/elements/b-filter-left-option-section.vue'
    //   ),
    Admin: () => import(/* webpackChunkName: "Admin" */ '~/pages/Admin.vue'),
  },

  props: {
    isMniDashboard: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAdvanceFilterInOverview: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      LAYOUT_TYPES,
      showEventChangePopup: { visible: false, payload: {} },
      isAllEventsPerformanceSelected: false,
    };
  },
  computed: {
    selectedMenuOption: get('common/selectedMenuOption'),
    showFilters: get('common/showFilters'),
    embedTitle() {
      const params = getQueryParams(window.location.href);
      const { embedTitle } = params;
      return embedTitle || 'Report Builder';
    },
  },

  created() {
    this.$on('on-change-selected-menu-option', this.changeSelectedMenuOption);
  },
  beforeDestroy() {
    this.$off('on-change-selected-menu-option', this.changeSelectedMenuOption);
  },
  methods: {
    onCloseEventChangePopup() {
      this.showEventChangePopup = { visible: false, payload: {} };
    },
    onChangeEventChangePopup() {
      const { payload } = this.showEventChangePopup;
      const { newSelectedOptions } = payload;
      this.$store.set('dashboard/filters@eventOptions', newSelectedOptions);
      this.showEventChangePopup = { visible: false, payload: {} };
    },
    onAllEventsPerformanceSelected(val = false) {
      this.isAllEventsPerformanceSelected = val;
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen {
  .page-loader {
    position: relative;
    top: 250px;
  }
  * {
    box-sizing: border-box;
  }
  * {
    outline: 0;
  }
  .report-builder-page-loader {
    position: relative;
    top: 250px;
  }
  .filter-details {
    display: inline-block;
    margin-right: 0.2rem;
    cursor: pointer;
    // &:hover {
    //   background-color: rgba(0, 0, 0, 0.2);
    // }

    .filter {
      display: inline-block;
      padding: 0 4px 0 4px;
      margin-right: 8px;
      text-align: left;

      &:last-child {
        margin-left: 10px;
        border-right: 1px dashed #232222;
        div {
          margin-right: 5px;
        }
      }

      div {
        font-size: 12px;

        &.main {
          padding: 2px 0 2px 0;
          font-weight: 500;
          color: #4c5461;
        }

        &.sub {
          font-weight: 600;
          color: #667184;
        }
      }
    }
  }
  .filter-details.lookup {
    position: relative;
    display: inline-block;
    padding: 8px;
    color: #717482;
    vertical-align: text-bottom;
    cursor: pointer;
    span.main {
      font-size: 11px;
      font-weight: 300;
      color: #717482;
      span.sub {
        display: inline-block;
        width: 70px;
        margin-top: -5px;
        overflow: hidden;
        font-size: 12px;
        font-weight: 400;
        color: #a1a5af;
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: top;
        cursor: pointer;
      }
      svg {
        position: relative;
        display: inline-flex;
        align-items: center;
        font-size: 10px;
        color: #ccc;
      }
    }
  }
  ::v-deep .table-wrap .table-scroll {
    max-height: 25rem;
  }
}

@media screen {
  * {
    box-sizing: border-box;
  }
  .light-theme .flp-panel {
    color: #212325;
    box-shadow: 0px 14px 35px -12px rgba(0, 40, 100, 0.17);
    background-color: #fff;
    border: 1px solid rgb(165 173 178 / 22%);
    border-radius: 8px;
  }
  .flp-panel {
    position: relative;
    display: inline-block;
    padding: 30px;
    color: #fff;
    background-color: #2f323a;
    border-radius: 3px;
    -webkit-transition: background-color 0.3s ease;
    -moz-transition: background-color 0.3s ease;
    -ms-transition: background-color 0.3s ease;
    -o-transition: background-color 0.3s ease;
    transition: background-color 0.3s ease;
  }
  .flp-panel-title {
    display: inline-block;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.1em;
  }
  * {
    outline: 0;
  }
  .exportable-table-wrap {
    min-height: 30rem;
    max-height: 38rem;
    margin-bottom: 35px;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  .flp-panel-top {
    padding-bottom: 20px;
    margin-bottom: 14px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .intip4 {
    position: relative;
    top: 0;
  }
  .intip4[data-tool-tip]::after {
    position: absolute;
    bottom: 100%;
    left: 0;
    z-index: 99;
    display: block;
    width: 100px;
    padding: 10px 12px;
    margin-left: -50px;
    font-size: 12px;
    line-height: 1.2em;
    color: #5b5b5b;
    text-align: center;
    pointer-events: none;
    content: attr(data-tool-tip);
    background-color: #fdea85;
    border-radius: 3px;
    transition: ease 0.3s;
    transform: scale(0);
    transform-origin: bottom center;
  }
  .intip4[data-tool-tip]::after {
    width: 155px;
    margin-left: -70px;
    font-size: 12px;
    font-weight: 400;
    color: #222;
    pointer-events: none;
    background-color: rgba(245, 245, 250, 0.9);
  }
  .intip4[data-tool-tip]:hover::after {
    bottom: -145%;
    transform: scale(1);
  }
}
.expand-table-enter-active,
.expand-table-leave-active {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.expand-table-enter,
.expand-table-leave-to {
  transform: translateX(-100%) !important;
  opacity: 0 !important;
}

// Top Area CSS - START
::v-deep .b-select .select .selected-opt {
  display: block;
  width: 164px !important;
}
.top-area {
  margin-bottom: 15px;
  color: #fff;
}
.logo {
  display: inline-block;
  width: 250px;
  height: auto;
}
.logo img {
  width: 100%;
  height: auto;
}
.top-area-center {
  position: relative;
  top: 0;
  left: -125px;
  display: flex;
  margin: 0;
}
.top-area-center-checkbox {
  position: relative;
  display: inline-block;
  margin-left: 40px;
}
label {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
input[type='checkbox'] {
  display: none;
}
input[type='checkbox'] + label:before {
  display: inline-block;
  font-family: 'FontAwesome';
}
input[type='checkbox'] + label:after {
  display: inline-block;
  font-family: 'FontAwesome';
}
input[type='checkbox'] + label:before {
  position: relative;
  top: -2px;
  left: 2px;
  z-index: 2;
  font-size: 14px;
  font-weight: 600;
  color: var(--primarycolor);
  letter-spacing: 10px;
  content: '\f00c';
  opacity: 0;
}
input[type='checkbox']:checked + label:before {
  font-weight: 600;
  letter-spacing: 10px;
  content: '\f00c';
  opacity: 1;
}
input[type='checkbox'] + label::after {
  position: absolute;
  left: 0px;
  font-size: 18px;
  font-weight: 500;
  color: rgba(150, 150, 150, 0.4);
  content: '\f1db';
}
input[type='checkbox']:checked + label::after {
  color: var(--primarycolor);
}
.input-value {
  position: relative;
  display: inline-block;
  width: 200px;
  min-width: 150px;
  height: 100%;
  padding: 10px 10px;
  font-size: 15px;
  text-align: left;
  border: 0px;
  border-radius: 4px;
}
.intdark {
  color: #fff;
  background-color: #262933;
}

// Download Icon CSS - START

svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  font-size: inherit;
  vertical-align: -0.125em;
}
.svg-inline--fa.fa-w-12 {
  width: 0.75em;
}

.fa-arrow-to-bottom:before {
  content: '\f33d';
}

// Download Icon CSS - END

// Export Table - START

svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  font-size: inherit;
  vertical-align: -0.125em;
}
.svg-inline--fa.fa-w-10 {
  width: 0.625em;
}

.fa-times:before {
  content: '\f00d';
}

.fa-times:before {
  content: '\f00d';
}
.top-last {
  margin-top: 0px;
}
::v-deep .dropdown {
  margin-right: 0 !important;
}

::v-deep .exportable-table-wrap .flp-panel-top .form-input-wrap {
  margin-top: -1% !important;
}
::v-deep .form-input-field {
  background-color: #262933;
  border: none;
  border-radius: 4px;
}
::v-deep .form-input-field.v-select .vs__dropdown-toggle .vs__actions .action-icons svg {
  color: inherit !important;
}
::v-deep .form-input-wrap .form-click .action-icons {
  padding: 0 11px 0 0 !important;
}
::v-deep .form-input-wrap .form-click {
  min-height: 40px;
}
::v-deep .form-input.form-date svg {
  left: 90%;
  z-index: 1;
}
.exportable-table-wrap .flp-panel-top {
  display: flex;
}
.exportable-table-wrap .dboard-select {
  width: 12rem;
  min-width: 150px;
  margin-top: -10px;
  margin-left: 1rem;
  background-color: #474a56;
  border-radius: 4px;
}
.exportable-table-wrap .flp-panel-top .flp-panel-title {
  width: 175px;
}
.exportable-table-wrap .flp-controls {
  display: flex;
  width: 100%;
}
.dboard-select {
  width: 12rem;
  min-width: 182px;
  margin-top: 4px;
  margin-right: 1.2rem;
  margin-left: 0;
  background-color: #474a56;
  border-radius: 4px;
}
::v-deep .k-btn {
  display: inline-block;
  min-width: 75px;
  font-weight: 600;
  color: var(--buttontext) !important;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  background-color: var(--primarycolor);
  border-radius: 3px;
  appearance: none;
  display: flex;
  margin: 0 auto;
}
::v-deep .k-btn.btn-size-3 span.label {
  padding: 9px 22px;
  line-height: 20px;
}
::v-deep .k-btn:hover {
  color: #cad1d6 !important;
  background-color: #424242 !important;
}
::v-deep .email-alert {
  z-index: 2;
}
.no-display {
  display: none !important;
}

.sideopen {
  position: relative;
  left: 12%;
  width: calc(96% - 300px);
  .filter-panel-wrap {
    left: 0px;
  }
}
.trans-effect {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
</style>
<style lang="scss">
@media (max-width: 480px) {
  .image img {
    position: absolute;
    top: 19%;
    left: 21%;
  }
}

.daterangepicker.opensright:before {
  left: 360px !important;
}
.daterangepicker.opensright:after {
  left: 361px !important;
}
.daterangepicker {
  .drp-buttons {
    .btn {
      text-transform: uppercase;
    }
    .applyBtn {
      color: #fff !important;
      background-color: var(--primarycolor) !important;
    }
  }
}
.daterangepicker .drp-buttons .applyBtn:hover,
.daterangepicker .drp-buttons .cancelBtn:hover {
  background-color: #424242 !important;
}
::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: rgba(111, 111, 111, 0.2);
  border: 0px none #ffffff;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #525965;
}
::-webkit-scrollbar-thumb:active {
  background: #525965;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

.checkbox-btn span {
  margin: 0 40px 0 10px !important;
  font-size: 14px !important;
}

.ddselect {
  width: 200px;
  height: 40px;
  margin-left: 1rem;
  background-color: #474a56;
  border-radius: 4px;
}
// .container-chart {
//   text-align: center;
//   svg {
//     display: block;
//     margin: auto;
//   }
// }
.c3 text.c3-empty {
  font-size: 13px;
  fill: #bac0c5 !important;
}
.light-theme {
  .txt-center {
    color: #444;
  }
  .email-alert {
    background-color: #fff !important;
    color: #536176 !important;
    box-shadow: 0 3px 20px rgba(202, 209, 214, 0.8) !important;
    input[type='email'] {
      border: 1px solid rgba(74, 101, 131, 0.2);
    }
  }
  .email-alert-on-saved-rep {
    background-color: #fff !important;
    color: #536176 !important;
    box-shadow: 0 3px 20px rgba(202, 209, 214, 0.8) !important;
    input[type='email'] {
      border: 1px solid rgba(74, 101, 131, 0.2);
    }
  }
}
.txt-center {
  position: absolute;
  width: 740px;
  font-size: 13px;
  color: #bac0c5;
}
.txt-align-center {
  display: inline-grid;
  align-items: center;
  width: 100%;
  min-height: 65px;
  font-size: 13px;
  color: #bac0c5;
  text-align: center;
}
.showIncrBox {
  .mid-area-1 {
    //width: 25% !important;
    padding: 20px 15px !important;
    .stat-cnt {
      width: 100%;
      .mid-area-1-cont {
        margin-right: 0.5rem !important;
        width: auto !important;
        span.big {
          font-size: 36px !important;
        }
      }
    }
  }
  .quick-stat:not(:last-child) {
    margin: 0 20px 0 0 !important;
  }
}
.intermediate-screen-bg {
  background-color: #fff;
  height: calc(100vh - 60px);
}
.readonly-db {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.4;
}
.advance-filter-clear-icon {
  position: relative;
  top: -2px;
  left: 5px;
  cursor: pointer;
  font-size: 13px;
  &:hover {
    color: var(--primarycolor) !important;
  }
}
.light-theme .drp-wrap {
  background-color: transparent !important;
}
.mni-report-content {
  margin-top: 85px;
}
</style>
