<template>
  <div class="app-board" :class="getAppParentClassState()">
    <!-- left nav section -->
    <left-nav-section
      v-if="!leftNavLoading"
      :options="leftNavOptions"
      :is-mni-dashboard="isMniDashboard"
      :is-via-dashboard="isViaDashboard"
      :is-collapsed="isLeftNavCollapsed"
      :is-campaign-table-expanded="isCampaignTableExpanded"
      :default-selected-solution="getDefaultSolution"
      @option-click="handleLeftNavClick"
      @on-left-nav-toggle="handleLeftNavToggle"
    />
    <div v-else class="dashboard-loading-spinner">
      <b-loading-spinner />
    </div>
    <div v-if="noAdvertiserAssined" class="no-advertiser-container">
      Oops! It looks like no advertisers are assigned to your account. Please contact the
      administrator for assistance.
      <div class="logout-button-container">
        <button class="option-btn" @click="handleLogout()">Log Out</button>
      </div>
    </div>
    <!-- When solution handler = EmbeddedDashboard show a url with white theme in IFrame -->
    <div v-if="isSpinnerVisible" class="dashboard-loading-spinner">
      <b-loading-spinner />
    </div>
    <template v-else>
      <!-- Other Solutions, including MNI -->
      <div
        v-if="selectedSolutionHandler !== 'EmbeddedDashboard'"
        :class="[
          isCampaignTableExpanded ? 'shift-top' : '',
          isMniDashboard && isPixelPageVisible
            ? 'mni-body-content-container'
            : 'body-content-container',
        ]"
      >
        <div v-if="showTopFilterSection">
          <top-filter-section
            v-if="!leftNavLoading"
            :hide-filter-bar="solutionLoading || hideFilterBarForTools || hideFilterBar"
            :is-left-nav-collapsed="isLeftNavCollapsed"
            :title="selectedSectionTitle"
            :is-campaign-table-expanded="isCampaignTableExpanded"
            :active-solution-id="selectedSolution.id"
            :filters-to-hide="filtersToHide"
            :class="disableFilterBar ? 'inactive-filter-bar' : ''"
            @show-pixel-page="isPixelPageVisible = $event"
          />
        </div>
        <flip-pixel v-if="isPixelPageVisible && !solutionLoading" />
        <div v-else class="body-content" :class="isCampaignTableExpanded ? 'fullmax' : ''">
          <component
            :is="selectedSolutionHandler"
            v-if="selectedSolutionHandler && !solutionLoading"
            :active-solution-id="selectedSolution.id"
            :active-dashboard="selectedDashboard"
            :is-left-nav-collapsed="isLeftNavCollapsed"
            :is-campaign-table-expanded="isCampaignTableExpanded"
            @on-expand-campaign-table="handleExpandCampaignTable"
          />
        </div>
        <div v-if="solutionLoading" class="solution-loading-spinner">
          <b-loading-spinner />
        </div>
      </div>
      <!-- Advanced Performance Solution -->
      <div v-else>
        <div class="emb-wrap">
          <b-flip-header
            :show-logo="account != null && !isEmbed"
            :show-title="isEmbed"
            :title="embedTitle"
            :show-top-navigation="showTopNavigation"
            :is-left-nav-collapsed="isLeftNavCollapsed"
          />
          <top-filter-section
            :hide-filter-bar="solutionLoading || hideFilterBarForTools || hideFilterBar"
            :is-left-nav-collapsed="isLeftNavCollapsed"
            :title="selectedSectionTitle"
            :is-campaign-table-expanded="isCampaignTableExpanded"
            :active-solution-id="isMniDashboard ? 8 : selectedSolution.id"
            :filters-to-hide="filtersToHide"
            :is-mni-report-dashboard="isMniDashboard"
            :is-advance-filter-in-overview="true"
            :is-hidden="isMniDashboard ? false : true"
            @show-pixel-page="isPixelPageVisible = $event"
          />
        </div>
        <DashboardFlip
          v-if="
            selectedMenuOption === LAYOUT_TYPES.DASHBOARD &&
              selectedDashboard.embedUrlType === 'flip'
          "
          :is-advance-filter-in-overview="true"
        />
        <FlipReportBuilder
          v-if="
            selectedMenuOption === LAYOUT_TYPES.DASHBOARD &&
              selectedDashboard.embedUrlType === 'flip_report' &&
              !loading
          "
          :is-mni-dashboard="isMniDashboard"
          :is-advance-filter-in-overview="true"
        />
        <AccountSetupScreen v-if="selectedMenuOption === LAYOUT_TYPES.ACCOUNT_SETUP && !loading" />
        <flip-pixel v-if="selectedMenuOption === LAYOUT_TYPES.FLIPPIXEL && !loading" />
        <Admin v-if="selectedMenuOption === LAYOUT_TYPES.ADMIN && !loading" />
      </div>
    </template>
  </div>
</template>

<script>
import { get, sync } from 'vuex-pathify';
import { isEqual } from 'lodash';
import { isBlank } from 'adready-api/helpers/common';
import _ from 'underscore';
import {
  MY_REPORTS,
  AGENCY_REPORTS,
  SEARCH_DATA,
  SEGMENTS_SEARCH,
  KEY_DEMO_ADVERTISER_ID,
  ADVANCED_PERFORMANCE_SOLUTION_ID,
  MNI_MOTTO_SOLUTION_ID,
  ACCOUNTS_TO_HIDE_INACTIVE_SOLUTIONS,
  SHOW_ONLY_SOLUTIONS_BY_ACCOUNT,
  MNI_ACCOUNT_ID,
  MNI_DEMO_ACCOUNT_ID,
  VIA_ACCOUNT_ID,
  KEY_DEMO_ACCOUNT_ID,
  PROGRAMMATIC_MEDIA_SOLUTION_ID,
  LAYOUT_TYPES,
  REPORT_BUILDER_TITLE,
  ENABLE_SEGMENT_TOOL,
} from '@/constant';
import EventBus from '@/adready-vue/helpers/global/event-bus';
import { clearAPCacheFilters } from '@/helpers/global/url-helpers';
import { clearSessionCookies } from 'adready-api/helpers/cookie-session-helper';
import axios from 'axios';
import LeftNavSection from '~/components/left-nav-section.vue';
import TopFilterSection from '~/components/top-filter-section.vue';
// import FlipDashboard from '~/layouts/embedded-dashboard.vue';
import PerformanceBrandOverview from '~/layouts/performance-brand-overview.vue';
import PerformanceBrandDelivery from '~/layouts/performance-brand-delivery.vue';
import PerformanceBrandReport from '~/layouts/performance-brand-report.vue';
import ConvergedTvContainer from '~/layouts/converged-tv-container.vue';
import MNIReportingContainer from '~/layouts/mni-reporting-container.vue';
import SearchOverview from '~/layouts/search-overview.vue';
import SearchDelivery from '~/layouts/search-delivery.vue';
import SearchReport from '~/layouts/search-report.vue';
import SocialOverview from '~/layouts/social-overview.vue';
import SocialDelivery from '~/layouts/social-delivery.vue';
import SocialReport from '~/layouts/social-report.vue';
import DoohOverview from '~/layouts/dooh-overview.vue';
import DoohDelivery from '~/layouts/dooh-delivery.vue';
import DoohReport from '~/layouts/dooh-report.vue';
import YoutubeOverview from '~/layouts/youtube-overview.vue';
import YoutubeDelivery from '~/layouts/youtube-delivery.vue';
import YoutubeReport from '~/layouts/youtube-report.vue';
import MyReports from '~/layouts/my-reports.vue';
import SegmentsSearchTool from '~/layouts/segments-search-tool.vue';
import AgencyReports from '~/layouts/agency-reports.vue';
import authzMixin from '~/components/mixins/authz-mixin';
import forklift from '~/components/mixins/forklift-mixin';
import appConsoleDataLayer from '~/api/app-console-data';
import appConfig from '~/config';
import { isDemoInstance, getFiltersToHide, isFlamingoInstance } from '~/util/utility-functions';
import gaEventsMixin from '~/components/mixins/ga-events-mixin';
import campaignMixin from '~/components/mixins/campaign-mixin';
import AccountSetupScreen from './AccountSetupScreen.vue';
import Admin from './Admin.vue';
import DashboardFlip from './DashboardFlip.vue';
import FlipReportBuilder from './FlipReportBuilder.vue';
import ReportsBuilder from '~/pages/report-builder-v2/report-home.vue';

export default {
  components: {
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
    BFlipHeader: () =>
      import(/* webpackChunkName: "b-flip-header" */ '~/components/elements/b-flip-header.vue'),
    FlipPixel: () => import(/* webpackChunkName: "flip-pixel" */ '~/pages/FlipPixel.vue'),
    Admin,
    AccountSetupScreen,
    DashboardFlip,
    FlipReportBuilder,
    LeftNavSection,
    TopFilterSection,
    // EmbeddedDashboard,
    PerformanceBrandOverview,
    PerformanceBrandDelivery,
    PerformanceBrandReport,
    ConvergedTvContainer,
    MNIReportingContainer,
    SearchOverview,
    SearchDelivery,
    SearchReport,
    SocialOverview,
    SocialDelivery,
    SocialReport,
    DoohOverview,
    DoohDelivery,
    DoohReport,
    YoutubeReport,
    YoutubeOverview,
    YoutubeDelivery,
    MyReports,
    AgencyReports,
    SegmentsSearchTool,
    ReportsBuilder,
  },
  mixins: [authzMixin, forklift, gaEventsMixin, campaignMixin],
  data() {
    const listOfFiltersToHide = getFiltersToHide();
    return {
      isPixelPageVisible: false,
      isSpinnerVisible: false,
      filtersToHide: listOfFiltersToHide,
      isLeftNavCollapsed: false,
      isCampaignTableExpanded: false,
      selectedSectionTitle: '',
      showHideTopNavigation: true,
      solutionLoading: false,
      selectedSolutionHandler: undefined,
      selectedDashboard: undefined,
      selectedTool: undefined,
      selectedSolution: {},
      showTopFilterSection: false, // prevent loading it as it conflicts with b-flip-header date picker (refactor me!)
      rootSectionJson: [
        {
          section: '_root_',
          hideSectionTitle: true,
          options: [
            {
              title: 'Home',
              iconName: 'house-chimney',
              nodeType: 'home',
            },
          ],
        },
      ],
      toolsSectionJson: {
        section: 'Tools',
        options: [
          {
            name: MY_REPORTS,
            title: 'Custom Reports',
            iconName: 'folder-heart',
            nodeType: 'tool',
            handler: 'MyReports',
            order: 1,
          },
          {
            name: AGENCY_REPORTS,
            title: 'Agency Reports',
            iconName: 'folder-closed',
            nodeType: 'tool',
            handler: 'AgencyReports',
            hidden: true,
            order: 2,
          },
          {
            name: SEARCH_DATA,
            title: 'Search Data',
            iconName: 'folder-magnifying-glass',
            nodeType: 'tool',
            hidden: true,
            order: 3,
          },
          // {
          //   name: SEGMENTS_SEARCH,
          //   title: 'Segment Search',
          //   iconName: 'folder-magnifying-glass',
          //   nodeType: 'tool',
          //   handler: 'SegmentsSearchTool',
          // },
          // {
          //   name: REPORTS_BUILDER, TODO Phase 2
          //   title: 'Report Builder 2.0',
          //   iconName: 'fa-pencil-ruler',
          //   nodeType: 'tool',
          //   handler: 'ReportsBuilder',
          // },
        ],
      },
      isDemoInstance,
      isFlamingoInstance,
      LAYOUT_TYPES,
      showAccounts: false,
      loading: false,
      canClearPath: false,
      hasReloaded: false,
    };
  },

  computed: {
    account: get('common/account'),
    advertiser: get('common/advertiser'),
    advertiserSolutions: get('common/advertiserSolutions'),
    leftNavLoading: get('common/leftNavLoading'),
    noAdvertiserAssined: get('common/noAdvertiserAssined'),
    selectedDashboardTab: get('dashboard/selectedDashboardTab'),
    isMniAccount: get('common/isMniAccount'),
    demoAdvertisers: get('common/GET_DEMO_ADVERTISER_MAPPING_MAP'),
    getDefaultSolution() {
      let advertiserId = this.advertiser?.id;
      if (isDemoInstance()) {
        advertiserId = parseInt(localStorage.getItem(KEY_DEMO_ADVERTISER_ID), 10);
        const advertiserKeys = Object.keys(this.demoAdvertisers).map((k) => parseInt(k, 10));
        const demoAdvertiserMapping = advertiserKeys.includes(advertiserId)
          ? this.demoAdvertisers[advertiserId]
          : this.demoAdvertisers[1691];
        return demoAdvertiserMapping?.solutionId ? demoAdvertiserMapping?.solutionId : null;
      }
      return 1;
    },
    disableFilterBar() {
      return (
        this.selectedSectionTitle === 'Custom Reports' ||
        this.selectedSectionTitle === 'Agency Reports'
      );
    },
    hideFilterBarForTools() {
      return (
        this.selectedSectionTitle === 'Agency Reports' ||
        this.selectedSectionTitle === 'Segment Search' ||
        this.selectedSectionTitle === 'Custom Reports' ||
        this.selectedSectionTitle === 'Media Now Interactive // Segment Search'
      );
    },
    isAnyExternalUser() {
      return this.isExternalUser || this.isExternalSubUser || this.isExternalAdmin;
    },
    hideFilterBar() {
      if (this.isMniAccount) {
        return (
          this.selectedSectionTitle === 'Media Now Interactive // Report Builder 2.0' ||
          this.selectedSectionTitle === 'Media Now Interactive // Custom Reports'
        );
      }
      return false; // Phase 2: this.selectedSectionTitle === 'Report Builder 2.0';
    },
    advSolutions() {
      return JSON.parse(JSON.stringify(this.advertiserSolutions));
    },
    customAdvSolutions() {
      if (this.isMniDashboard)
        return this.advSolutions.filter((item) => item.id === MNI_MOTTO_SOLUTION_ID);
      return [];
    },
    leftNavOptions() {
      let { advSolutions } = this;
      let list = [];

      // Root section
      // list.push(...this.rootSectionJson);

      let accountId = this.account?.id;
      let advertiserId = this.advertiser?.id;
      if (isDemoInstance()) {
        accountId = parseInt(localStorage.getItem(KEY_DEMO_ACCOUNT_ID), 10);
        advertiserId = parseInt(localStorage.getItem(KEY_DEMO_ADVERTISER_ID), 10);
      }
      if (isDemoInstance() && advertiserId === 1877) {
        advSolutions.map((sol) => {
          if (sol.id === PROGRAMMATIC_MEDIA_SOLUTION_ID) {
            sol.state = 'HIDDEN';
          }
          return sol;
        });
      }

      let customAdvSolutions = [];
      if (this.isMniDashboard) {
        customAdvSolutions = this.customAdvSolutions;
        if (customAdvSolutions.length) advSolutions = customAdvSolutions;
      }

      // Active Solutions section
      const activeSolutionsSectionOptions = this.buildSolutionSectionOptions(
        advSolutions,
        'ACTIVE'
      );
      list.push({
        section: 'Active Solutions',
        options: activeSolutionsSectionOptions,
        hideSectionTitle: ACCOUNTS_TO_HIDE_INACTIVE_SOLUTIONS.includes(accountId),
      });

      // Inactive Solutions section
      if (!ACCOUNTS_TO_HIDE_INACTIVE_SOLUTIONS.includes(accountId)) {
        const inActiveSolutionsSectionOptions = this.buildSolutionSectionOptions(
          advSolutions,
          'DISCOVER'
        );
        list.push({ section: 'Inactive Solutions', options: inActiveSolutionsSectionOptions });
      }

      // Tools Section
      const toolsSection = JSON.parse(JSON.stringify(this.toolsSectionJson));
      let toolOptions = this.toolsSectionJson.options.filter((o) => !o.hidden);

      if (this.isMniDashboard && customAdvSolutions.length) {
        toolOptions = [];
      }
      toolOptions.map((o) => {
        if (o.name === SEARCH_DATA && (this.isInternalAdmin || this.isInternalSubAdmin)) {
          toolsSection.options = toolOptions;
          list.push(toolsSection);
        }
        return o;
      });

      // add agency reports
      const agencyReports = this.toolsSectionJson.options.find((o) => o.name === AGENCY_REPORTS);
      // Enable Agency report for VIA Account (73) and SFM (58)
      if ((accountId === VIA_ACCOUNT_ID || accountId === 58) && !this.isExternalUser) {
        toolOptions.push(agencyReports);
      }
      toolOptions = toolOptions.map((obj) => {
        if (obj.name === MY_REPORTS && !this.advertiser?.customReportsEnabled) {
          return { ...obj, disabled: true };
        }
        return obj;
      });
      //
      // toolOptions = toolOptions.filter(
      //   (tool) =>
      //     !(tool.name === SEGMENTS_SEARCH && (!ENABLE_SEGMENT_TOOL || !this.isInternalAdmin))// this.hasSegmentToolAccess
      // );

      // sort based on the order value
      toolOptions = toolOptions.sort((a, b) => {
        return a.order - b.order;
      });

      toolsSection.options = toolOptions;
      list.push(toolsSection);

      // Show only solutions by account
      const demoAccount = SHOW_ONLY_SOLUTIONS_BY_ACCOUNT.find(
        (item) => item.accountId === accountId
      );
      if (demoAccount && advertiserId !== 1877) {
        const { showOnlySolutions } = demoAccount;
        if (showOnlySolutions.length) {
          const newList = [];
          list.forEach((listItem) => {
            const { options = listItem.options.map((o) => ({ option: o.options })) } = listItem;
            const newOption = [];
            options.forEach((optionItem) => {
              const { title = options.title.map((t) => ({ title: t.title })) } = optionItem;
              if (showOnlySolutions.includes(title)) {
                if (!newOption.find((item) => item.title === title)) newOption.push(optionItem);
                listItem.options = newOption;
                if (!newList.find((item) => item.section === listItem.section))
                  newList.push(listItem);
              }
            });
          });
          if (newList.length) list = newList;
        }
      }

      // Custom Whitelable Dashboards
      if (this.isMniDashboard && customAdvSolutions.length) {
        if (list.length) {
          const newList = [];
          list.forEach((listItem) => {
            const { options = listItem.options.map((o) => ({ option: o.options })) } = listItem;
            const newOption = [];
            options.forEach((optionItem) => {
              if (optionItem.children) {
                const {
                  children = optionItem.children.map((t) => ({ children: t.children })),
                } = optionItem;
                children.forEach((child) => {
                  const { title = child.title.map((t) => ({ title: t.title })) } = child;
                  child.isExpanded = false;
                  if (child.title === 'Custom Reports' && !this.advertiser?.customReportsEnabled) {
                    child.disabled = true;
                  }
                  if (!newOption.find((item) => item.title === title) && !child.disabled) {
                    newOption.push(child);
                  }
                  listItem.options = newOption;
                  listItem.hideSectionTitle = true;
                  if (!newList.find((item) => item.section === listItem.section))
                    newList.push(listItem);
                  // }
                });
              }
            });
          });
          if (newList.length) list = newList;
        }
      }
      return list;
    },
    isMniDashboard() {
      let accountId = this.account?.id;
      if (isDemoInstance()) {
        accountId = parseInt(localStorage.getItem(KEY_DEMO_ACCOUNT_ID), 10);
      }

      if (accountId === MNI_ACCOUNT_ID || accountId === MNI_DEMO_ACCOUNT_ID) {
        this.$store.dispatch('common/setIsMniAccount', true);
        return true;
      }
      this.$store.dispatch('common/setIsMniAccount', false);
      return false;
    },
    isViaDashboard() {
      const accountId = this.account?.id;
      if (accountId === VIA_ACCOUNT_ID) {
        this.$store.dispatch('common/setIsViaAccount', true);
        return true;
      }
      this.$store.dispatch('common/setIsViaAccount', false);
      return false;
    },
    selectedMenuOption: get('common/selectedMenuOption'),
    activeItem: sync('common/activeItem'),

    isEmbed() {
      return true;
    },
    embedTitle() {
      return this.selectedSectionTitle;
    },
    showTopNavigation() {
      return this.showHideTopNavigation;
    },
  },
  watch: {
    account(account) {
      if (account?.organization?.whitelabelEnabled) {
        this.$appConsoleTheme?.setTheme(account?.organization?.theme);
      } else {
        this.$appConsoleTheme?.setDefaultTheme();
      }
    },
    selectedDashboardTab(n) {
      this.filtersToHide = getFiltersToHide(
        this.selectedSolution,
        this.selectedTool,
        this.selectedDashboard,
        n
      );
    },
    selectedTool(n) {
      this.filtersToHide = getFiltersToHide(
        this.selectedSolution,
        n,
        this.selectedDashboard,
        this.selectedDashboardTab
      );
    },
    selectedSolution(n) {
      this.filtersToHide = getFiltersToHide(
        n,
        this.selectedTool,
        this.selectedDashboard,
        this.selectedDashboardTab
      );
    },
    advertiser: {
      immediate: true,
      handler(newValue, oldValue) {
        if (!isEqual(newValue, oldValue) && !newValue?.isAdvUpdatedInAccSetup && newValue) {
          const selectedMenuPage = localStorage.getItem('FLIP_SELECTED_MENU_PAGE');
          if (selectedMenuPage && selectedMenuPage === LAYOUT_TYPES.FLIPPIXEL) {
            this.changeSelectedMenuOption(LAYOUT_TYPES.FLIPPIXEL);
          } else {
            this.changeSelectedMenuOption(LAYOUT_TYPES.DASHBOARD);
          }
        }
      },
    },
  },
  created() {
    EventBus.$on('on-account-settings', this.onAccountSettings);
    EventBus.$on('on-account-settings-triggerd', this.onAccountSettingsTriggerd);
    EventBus.$on('toggle-spinner', (visible) => {
      this.isSpinnerVisible = visible;
    });
    window.addEventListener('scroll', () => {
      const datePickerElement = document.querySelectorAll('[id*="datepicker_"]');
      if (window.pageYOffset >= 1) {
        document.getElementById('sub_filter_bar')?.classList?.add('scroll1');
      } else {
        document.getElementById('sub_filter_bar')?.classList?.remove('scroll1');
      }
      if (window.pageYOffset >= 1) {
        if (datePickerElement) {
          datePickerElement.forEach((elm) => elm.classList.add('scroll-date-picker'));
        }
        document.getElementById('sub_filter_bar')?.classList?.add('scroll2');
        document.getElementById('top-filter-bar')?.classList?.add('topbarscroll');
        document.getElementById('top-filter-bar-container')?.classList?.add('transparentBg');
      } else {
        if (datePickerElement) {
          datePickerElement.forEach((elm) => elm.classList.remove('scroll-date-picker'));
        }
        document.getElementById('sub_filter_bar')?.classList?.remove('scroll2');
        document.getElementById('top-filter-bar')?.classList?.remove('topbarscroll');
        document.getElementById('top-filter-bar-container')?.classList?.remove('transparentBg');
      }
    });
    this.$on('on-edit-item', this.onEditItem);
    this.$on('on-change-selected-menu-option', this.changeSelectedMenuOption);
    this.checkAndAddSegmentTool();
  },
  beforeDestroy() {
    EventBus.$off('on-account-settings', this.onAccountSettings);
    EventBus.$off('on-account-settings-triggerd', this.onAccountSettingsTriggred);
    this.$off('on-edit-item', this.onEditItem);
    this.$off('on-change-selected-menu-option', this.changeSelectedMenuOption);
    EventBus.$off('toggle-spinner');
  },
  methods: {
    fireLogoutGAEvent() {
      if (appConfig.gaEnabled()) {
        const gaEv = {};
        this.fireGAEvent('drsd_auth_logout', gaEv, false);
      }
    },
    async handleLogout() {
      this.fireLogoutGAEvent();
      const redirectTo = appConfig.APP_URL;
      this.$store.dispatch('common/setFilterCacheId', null);
      // resetting the value of event bus
      EventBus.fetchSavedReports = true;
      // clearing cookies to avoid maintaining filter after login
      sessionStorage.clear();
      clearSessionCookies();
      // clearCacheFilters();
      await axios.delete(`${appConfig.ADREADY_URL}/api/token/remove`, { withCredentials: true });
      window.location = redirectTo;
    },
    checkAndAddSegmentTool() {
      const isSegmentSearchToolExists = this.toolsSectionJson?.options.find(
        (tool) => tool.name === SEGMENTS_SEARCH
      );

      if (
        ENABLE_SEGMENT_TOOL &&
        (this.isInternalAdmin || this.hasSegmentToolAccess) &&
        !isSegmentSearchToolExists
      ) {
        this.toolsSectionJson?.options.push({
          name: SEGMENTS_SEARCH,
          title: 'Segment Search',
          iconName: 'folder-magnifying-glass',
          nodeType: 'tool',
          handler: 'SegmentsSearchTool',
          order: 4,
        });
      }
    },
    onAccountSettingsTriggerd() {
      this.solutionLoading = true;
    },
    onAccountSettings() {
      this.solutionLoading = true;
      this.isLeftNavCollapsed = true;
      this.selectedDashboard = { embedUrlType: 'flip_admin_settings' };
      this.selectedSolutionHandler = 'EmbeddedDashboard';
      this.selectedSectionTitle = 'Account Settings';
      this.showTopFilterSection = false;
      setTimeout(() => {
        this.changeSelectedMenuOption(LAYOUT_TYPES.ADMIN);
        this.showHideTopNavigation = false;
      }, 100);
    },
    buildSolutionSectionOptions(advSolutions, stateFilter) {
      if (this.canClearPath) {
        localStorage.removeItem('selectedPath');
      }
      const solutionsSectionOptions = advSolutions
        .filter((s) => s.state === stateFilter)
        .map((s) => {
          const { id, title, description, icon, config, state } = s;
          const iconWithPrefix = this.splitIconAndPrefix(icon);
          const o = { id, title, description, ...iconWithPrefix, isExpanded: false };
          o.disabled = state !== 'ACTIVE';
          o.isExpanded = false;
          o.nodeType = 'solution';
          if (state === 'ACTIVE' && config?.dashboards?.length) {
            o.children = [];
            config.dashboards.sort((a, b) => {
              return a.order - b.order;
            });
            config.dashboards.forEach((t) => {
              const iconDetails = this.splitIconAndPrefix(t.icon);
              o.children.push({
                title: t.title,
                ...iconDetails,
              });
            });
          }
          o.hasChildren = o.children && o.children.length > 0;
          return o;
        });
      return solutionsSectionOptions;
    },
    getAppParentClassState() {
      if (this.isCampaignTableExpanded) {
        if (this.isLeftNavCollapsed) {
          return 'exheader sclosed';
        }
        return 'exheader sopened';
      }
      if (this.isLeftNavCollapsed) {
        return 'colheader sclosed';
      }
      return 'colheader sopened';
    },
    handleExpandCampaignTable(val) {
      if (val) {
        this.isLeftNavCollapsed = true;
      } else {
        this.isLeftNavCollapsed = false;
      }
      this.isCampaignTableExpanded = val;
    },
    handleLeftNavToggle(val) {
      this.isLeftNavCollapsed = val;
    },
    splitIconAndPrefix(iconName) {
      if (!iconName.includes('|')) {
        return { iconName };
      }

      const parts = iconName.split('|');
      return { iconPrefix: parts[0], iconName: parts[1] };
    },
    async handleLeftNavClick(parentOption, childOption) {
      if (this.canClearPath) {
        localStorage.removeItem('selectedPath');
      }
      const savedPath = localStorage.getItem('selectedPath');
      if (savedPath && savedPath.length > 0) {
        try {
          const { parentOption: savedParentOption, childOption: savedChildOption } = JSON.parse(
            savedPath
          );
          parentOption = savedParentOption || parentOption;
          childOption = savedChildOption || childOption;
          this.$nextTick(() => {
            const options = { parentOption, childOption };
            if (!this.isMniAccount) {
              EventBus.$emit('on-refresh-nav-route-change', options);
            }
          });

          this.canClearPath = false;
        } catch (error) {
          console.error('Error parsing saved path:', error);
        }
      }
      if (this.isMniAccount) {
        childOption = undefined;
        localStorage.setItem('selectedPath', JSON.stringify({ parentOption, childOption }));
      }

      this.canClearPath = true;
      if (this.customAdvSolutions.length) {
        childOption = parentOption;
        parentOption = {};
        parentOption.title = this.customAdvSolutions[0].title;
        parentOption.nodeType = 'solution';
      }
      const titles = [];
      this.solutionLoading = true;
      if (parentOption?.title) {
        titles.push(parentOption.title);
      }
      if (childOption?.title) {
        titles.push(childOption.title);

        if (childOption.title === REPORT_BUILDER_TITLE) {
          localStorage.setItem('FLIP_SELECTED_MENU_PAGE', LAYOUT_TYPES.DASHBOARD);
          this.showHideTopNavigation = false;
        } else {
          this.showHideTopNavigation = true;
        }
      }
      const prevParent = this.selectedSectionTitle?.split(' // ')?.[0] || '';
      this.selectedSectionTitle = titles.join(' // ');
      if (parentOption) {
        if (parentOption.nodeType === 'solution') {
          this.selectedSolution = this.advertiserSolutions.find(
            (s) => s.title.toLowerCase() === parentOption.title.toLowerCase()
          );

          if (undefined === this.selectedSolution && !this.hasReloaded) {
            this.hasReloaded = true;
            window.location.reload(true);
          }

          if (
            this.isMniAccount &&
            ENABLE_SEGMENT_TOOL &&
            (this.isInternalAdmin || this.hasSegmentToolAccess)
          ) {
            // TODO, this should come from backend
            const newDashboardEntry = {
              order: 5,
              title: 'Segment Search',
              icon: 'folder-magnifying-glass',
              handler: 'SegmentsSearchTool',
            };
            // Check if the entry already exists based on a unique property, for example, 'title' or 'handler'
            const entryExists = this.selectedSolution?.config?.dashboards.some(
              (dashboard) =>
                dashboard.title === newDashboardEntry.title ||
                dashboard.handler === newDashboardEntry.handler
            );
            // If the entry doesn't exist, add it to the dashboards array
            if (!entryExists) {
              this.selectedSolution?.config?.dashboards.push(newDashboardEntry);
            }
          }

          if (this.isMniAccount && (this.isInternalAdmin || this.hasReportBuilder2Access)) {
            const newDashboardEntry2 = {
              icon: 'fa-cogs',
              order: 4,
              title: 'Report Builder 2.0',
              handler: 'ReportsBuilder',
            };

            // Check if the entry already exists based on a unique property, for example, 'title' or 'handler'
            const entryExists2 = this.selectedSolution?.config?.dashboards.some(
              (dashboard) =>
                dashboard.title === newDashboardEntry2.title ||
                dashboard.handler === newDashboardEntry2.handler
            );

            // If the entry doesn't exist, add it to the dashboards array
            if (!entryExists2) {
              this.selectedSolution?.config?.dashboards.push(newDashboardEntry2);
            }
          }
          this.isLeftNavCollapsed =
            this.selectedSolution.id === ADVANCED_PERFORMANCE_SOLUTION_ID ||
            this.selectedSolution.id === MNI_MOTTO_SOLUTION_ID;
          this.selectedSolutionHandler = this.selectedSolution?.handler;

          if (prevParent !== null && prevParent.length > 0 && parentOption.title !== prevParent) {
            clearAPCacheFilters([
              'filters_cache_filterCacheId',
              'initialFilters',
              'FLIP_SELECTED_MENU_PAGE',
              'flip_pixel_subpage',
            ]);
          } else if (
            prevParent !== null &&
            prevParent.length === 0 &&
            parentOption.title === 'Advanced Performance'
          ) {
            const storageFilter = localStorage.getItem('initialFilters');
            if (storageFilter === null) {
              clearAPCacheFilters(['filters_cache_filterCacheId']);
            }
          }

          await this.$store.dispatch('common/switchSolution', {
            appConsoleDataLayer,
            advertiserId: this.advertiser?.id,
            solutionId: this.isMniAccount ? MNI_MOTTO_SOLUTION_ID : this.selectedSolution.id,
            isChangeFilter: prevParent !== parentOption.title,
          });
          // this is for MNI solution when custom reports tab is configured as child json_config DB
          // this changes are added to make filters to hide same as tools section.
          if (
            childOption?.title === 'Custom Reports' ||
            childOption?.title === 'Search Data' ||
            childOption?.title === 'Report Builder 2.0'
          ) {
            this.selectedTool = this.toolsSectionJson.options.find(
              (o) => o.title.toLowerCase() === childOption.title.toLowerCase()
            );
          } else {
            this.selectedTool = undefined;
          }
        } else if (parentOption.nodeType === 'tool') {
          this.isLeftNavCollapsed = true;
          this.selectedSolution = { id: 0 };
          await this.$store.dispatch('common/switchSolution', {
            appConsoleDataLayer,
            advertiserId: this.advertiser?.id,
            solutionId: this.selectedSolution.id,
            isChangeFilter: prevParent !== parentOption.title,
          });
          this.selectedTool = this.toolsSectionJson.options.find(
            (o) => o.title.toLowerCase() === parentOption.title.toLowerCase()
          );
          this.selectedSolutionHandler = this.selectedTool?.handler;
        }
      }
      if (childOption) {
        const newSelectedDashboard = this.selectedSolution?.config?.dashboards?.find(
          (d) => d.title.toLowerCase() === childOption.title.toLowerCase()
        );
        const newSelectedSolutionHandler = newSelectedDashboard?.handler;
        if (newSelectedDashboard) {
          this.selectedDashboard = newSelectedDashboard;
        }
        if (newSelectedSolutionHandler) {
          this.selectedSolutionHandler = newSelectedSolutionHandler;
        }
      }
      if (!this.isMniAccount) {
        localStorage.setItem('selectedPath', JSON.stringify({ parentOption, childOption }));
      }

      if (
        this.selectedSolutionHandler !== undefined &&
        this.selectedSolutionHandler !== 'EmbeddedDashboard'
      ) {
        this.showTopFilterSection = true;
      } else {
        this.showTopFilterSection = false;
      }
      this.solutionLoading = false;
      this.isCampaignTableExpanded = false;

      // check for hard reload flag
      const hardReload = localStorage.getItem('hardReload');
      if (hardReload) {
        localStorage.removeItem('hardReload');
        window.location.reload(true);
      }
    },
    getDefaultLandingPageForSelfServe(adv) {
      const { accountSetup = {}, pixelSetup = {} } = adv || {};
      const accountSetupComplete =
        accountSetup?.accountInfoAvailable && accountSetup?.paymentInfoAvailable;
      const pixelSetupComplete = pixelSetup?.pixelAdded && pixelSetup?.pixelVerified;
      const selectedPage =
        accountSetupComplete && pixelSetupComplete
          ? LAYOUT_TYPES.CAMPAIGNS
          : LAYOUT_TYPES.ACCOUNT_SETUP;

      return selectedPage;
    },
    getRoleHasAccessToPage(role) {
      const pages = [];
      if (role === 'INTERNAL_USER') {
        pages.push(LAYOUT_TYPES.DASHBOARD);
      } else if (
        role === 'EXTERNAL_USER' ||
        role === 'EXTERNAL_SUB_ADMIN' ||
        role === 'INTERNAL_SUB_ADMIN' ||
        role === 'INTERNAL_ADMIN'
      ) {
        pages.push(LAYOUT_TYPES.DASHBOARD);
      }
      return pages;
    },
    onEditItem(activeLi) {
      this.activeItem = activeLi;
    },
    hasPixelTabAccess() {
      if (isFlamingoInstance()) {
        return false;
      }
      return !this.advertiser?.selfServe;
    },
    hasNewCampaignAccess() {
      return false;
    },
    hasCampaignManagerAccess() {
      return false;
    },
    hasReportingDashboardAccess() {
      return true;
    },
    hideAccountSwitchers() {
      this.showAccounts = false;
    },
    getAdvertisersList() {
      if (this.account) {
        const { advertisers } = this.account;
        if (this.account && !isBlank(advertisers)) {
          return _.sortBy(advertisers, (a) => a.name.toLowerCase());
        }
      }
      return [];
    },
    fireTopLevelMenuSelection(option) {
      if (appConfig.gaEnabled()) {
        const gaEv = {
          item: option,
        };
        this.fireGAEvent('flp_tlpu_selection', gaEv, false);
      }
    },
  },
};
</script>
<style lang="scss">
.shift-top {
  margin-top: 114px !important;
}
#app {
  margin: 0px auto;
}
.div.datepicker {
  top: unset;
}
.b-datepicker-container {
  position: relative;
  bottom: 20px;
  left: 13px;
  .drp-wrap > input {
    color: #595e6b;
    text-align: right;
  }
  .rpt-wrap > input {
    font-size: 13px !important;
    padding: 6px 0px 6px 0px !important;
  }
}
body.theme-default {
  background-color: #fbfcfe !important;
}
/*------scroll bar---------------------*/

::-webkit-scrollbar {
  width: 5px !important;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 2px;
  height: auto;
}
::-webkit-scrollbar-thumb {
  background: rgba(200, 212, 220, 0.1);
  border: 0px none #ffffff;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #536176 !important;
}
::-webkit-scrollbar-thumb:active {
  background: #536176 !important;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
.full-width {
  .panel_scroll_wrap::-webkit-scrollbar-thumb {
    background: rgb(118 128 146 / 90%);
    border: 0px none #ffffff;
    border-radius: 10px;
  }
  .panel_scroll_wrap::-webkit-scrollbar-thumb:hover {
    background: rgb(100 100 126 / 100%);
  }
}
</style>
<style lang="scss" scoped>
.dashboard-loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.solution-loading-spinner {
  position: fixed;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
}
.primarycolor {
  color: var(--primarycolor);
}
.app-board {
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  min-width: 320px;
  background-color: #fbfcfe;
  //font-family: 'Manrope', sans-serif;
  font-size: 14px;
  line-height: 1.4285em;
  color: rgba(0, 0, 0, 0.87);
  font-smoothing: antialiased;
}
.bgh {
  top: 0px;
  left: 0px;
  display: block;
  width: 100%;
  height: 3px;
  background-color: var(--primarycolor);
  position: absolute;
}
.body-content-container {
  display: flex;
  overflow: hidden;
  margin-top: 120px;
  font-family: 'Manrope', sans-serif !important;
}
.mni-body-content-container {
  display: flex;
  overflow: hidden;
  font-family: 'Manrope', sans-serif !important;
}
.body-content {
  padding: 20px 40px 40px 320px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  max-width: 1430px;
  margin: auto;
  //top: 120px;
  position: relative;
  min-height: 500px;
  &.fullmax {
    max-width: 1600px !important;
  }
}
.sclosed {
  .body-content {
    padding-left: 100px;
    margin: auto;
    max-width: 1340px;
    margin-top: 20px;
  }
}
.bdc_top {
  margin-bottom: 30px;
}
.bdc_top_right {
  width: 49%;
  float: right;
  margin-top: -9px;
}
.drp-wrap {
  background-color: transparent;
  min-width: 240px;
  padding-right: 6px;
  .compare-label {
    text-align: right;
  }
}
.rpt-wrap {
  font-size: 13px !important;
  padding: 6px 0px 6px 0px !important;
}
.bdc_panel {
  padding: 20px 4px 10px 25px;
  border-radius: 10px;
  box-shadow: 0px 14px 35px -12px rgba(0, 40, 100, 0.17);
  background-color: #fff;
  margin-bottom: 30px;
  border: 1px solid rgb(165 173 178 / 22%);
}
.sopen {
  .ln_tog {
    &:hover {
      left: 256px;
      left: 256px;
    }
  }
}
.fa-solid,
.fass {
  font-weight: 900;
}
.ui {
  &.visible {
    &.left {
      &.overlay {
        &.sidebar {
          -webkit-transform: translate3d(0%, 0%, 0);
          transform: translate3d(0%, 0%, 0);
        }
      }
    }
    &.sidebar {
      visibility: visible;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  &.left {
    &.overlay {
      &.sidebar {
        -webkit-transform: translate3d(-100%, 0%, 0);
        transform: translate3d(-100%, 0%, 0);
      }
    }
    &.sidebar {
      right: auto;
      left: 0px;
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  &.overlay {
    &.sidebar {
      z-index: 102;
    }
  }
}
.animating.ui.overlay.sidebar,
.ui.visible.overlay.sidebar {
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
}

.title {
  &.item {
    padding: 1.3em 1.14285714em !important;
  }
}

i {
  &.icon {
    display: inline-block;
    opacity: 1;
    margin: 0em 0.25rem 0em 0em;
    width: 1.18em;
    height: 1em;
    font-family: 'Icons';
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    text-align: center;
    speak: none;
    font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
}

.marginlefting {
  margin-left: 60px !important;
}
.displaynone {
  display: none !important;
}
.displayblock {
  display: block !important;
}
.item {
  &.logo {
    padding: 0px !important;
    margin: 30px 25px 20px !important;
  }
}
.ln_dropdown {
  .menu {
    .header {
      padding-top: 3.9px !important;
      padding-bottom: 3.9px !important;
    }
  }
}
.ln-icon {
  color: #868b90;
  font-size: 15px !important;
  position: relative;
  top: 0px;
  left: 0px;
  margin-right: 10px;
  transform: scale(1);
  width: 19px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.active {
  .ln-icon {
    color: #4e5669;
  }
  .droparw {
    transform: rotate(180deg);
  }
  &.brand-grp {
    border-bottom: 0px !important;
  }
}
.active2 {
  .ln-icon {
  }
}
.content {
  .ln-icon {
    opacity: 0.6;
    width: 19px;
    font-size: 12px !important;
    color: #747d94;
  }
}
.ln_txt {
  opacity: 1;
  position: relative;
}
.grow {
  width: 274px !important;
}
.grow2 {
  width: 65px !important;
}
.ln-btn-wrap {
  position: relative;
  display: none;
  float: right;
  top: -4px;
}
.ln-info {
  font-size: 15px !important;
  margin-right: 6px;
  position: relative;
  top: 2px;
  color: #a7b5c2;
  &:hover {
    color: var(--primarycolor);
  }
}
.ln_acct_wrap {
  padding: 32px 30px;
  border-top: 1px solid #eef1f6;
  position: relative;
  white-space: nowrap;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.ln_acct_img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
.ln_acct_img_wrap {
  display: inline-block;
}
.ln_acct_name {
  display: block;
  font-weight: 800;
  font-size: 1.06em;
  margin-top: 2px;
}
.ln_acct_label {
  display: block;
  font-weight: 600;
  font-size: 11px;
  color: #9fabb8;
  line-height: 1em;
}
.ln_acct_txt {
  display: inline-block;
  margin-left: 10px;
  vertical-align: top;
}
.ln_sec_title_wrap {
  margin: 18px 0px 21px 0px;
  position: relative;
}
.ln_sec_line {
  border-bottom: 1px solid #f0f1f3;
  position: relative;
  z-index: 1;
}
.inactive {
  .ln_txt {
    color: #c7d4db;
  }
  .ln-icon {
    color: #c7d4db;
  }
  .ln-btn-wrap {
    display: inline-block;
  }
}
.thin {
  .ln-icon {
    margin-right: 0px;
    transform: scale(1.2);
  }
  .ln_txt {
    display: none;
    position: absolute;
  }
  .ln_dropdown {
    &.icon {
      display: none !important;
    }
  }
  // .ui {
  //   &.accordion {
  //     overflow: hidden !important;
  //     padding: 0px;
  //   }
  // }
  .ln-btn-wrap {
    display: none !important;
  }
  .ln_acct_txt {
    display: none !important;
  }
  .ln_acct_wrap {
    padding: 30px 10px;
  }
}
.z0 {
  z-index: 0 !important;
}
.z100 {
  z-index: 100;
}
.o0 {
  opacity: 0;
}
.o50 {
  opacity: 0.5;
}
.o70 {
  opacity: 0.8;
}
.o80 {
  opacity: 0.8;
}
.o90 {
  opacity: 0.8;
}
.o100 {
  opacity: 1;
}
.nopoint {
  pointer-events: none !important;
}
.inact-color {
  color: #a7b5c2;
}
// ::-webkit-scrollbar {
//   width: 5px;
//   height: 7px;
// }
// ::-webkit-scrollbar-button {
//   width: 0px;
//   height: 0px;
// }
// ::-webkit-scrollbar-thumb {
//   background: rgba(200, 212, 220, 0.6);
//   border: 0px none #ffffff;
//   border-radius: 10px;
//   &:hover {
//     background: #536176; // var(--primarydark2);
//   }
//   &:active {
//     background: #536176; //var(--primarydark2);
//   }
// }
// ::-webkit-scrollbar-track {
//   background: transparent;
//   border: 0px none #ffffff;
//   border-radius: 50px;
//   &:hover {
//     background: transparent;
//   }
//   &:active {
//     background: transparent;
//   }
// }
// ::-webkit-scrollbar-corner {
//   background: transparent;
// }
.ln-btn {
  background-color: var(--primarycolor);
  font-size: 9px;
  padding: 3px 4px;
  color: #fff !important;
  cursor: pointer;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  -moz-appearance: none;
  appearance: none;
  letter-spacing: 0.07em;
  display: inline-block;
  font-weight: 800;
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
  text-align: center;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover {
    background-color: var(--primarydark2);
  }
}
.mdn-btn {
  background-color: var(--primarycolor);
  font-size: 11px;
  padding: 4px 8px;
  color: #fff !important;
  cursor: pointer;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  -moz-appearance: none;
  appearance: none;
  letter-spacing: 0.1em;
  display: inline-block;
  font-weight: 600;
  text-transform: uppercase;
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
  text-align: center;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover {
    background-color: var(--primarydark2);
  }
  &.outline {
    background-color: transparent;
    color: var(--primarydark2) !important;
    border: 1px solid #c5d2d8;
    &:hover {
      background-color: transparent;
      color: var(--primarycolor) !important;
      border: 1px solid var(--primarycolor);
    }
  }
}
.btn-icon {
  font-size: 16px;
  margin-right: 3px;
  margin-left: 4px;
  position: relative;
  top: 2px;
}
.btn-size2 {
  font-size: 12px;
  padding: 8px 14px;
}
.dropdown {
  min-width: 150px;
  max-width: 240px;
  display: inline-block;
  border-radius: 6px;
  transition: all 0.3s ease;
  position: relative;
  font-size: 14px;
  text-align: left;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .select {
    cursor: pointer;
    display: block;
    padding: 8px 20px 8px 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &:hover {
  }
  &:active {
  }
  .dropdown-menu-multi {
    .drp-grp {
      li {
        max-width: 90%;
      }
    }
  }
}
.droparw {
  font-size: 11px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  line-height: 12px;
  position: absolute;
  top: 50%;
  right: 8px;
  margin-top: -7px;
}
.dropdown.active:hover,
.dropdown.active {
  border-radius: 4px 4px 0 0;
}
.darkdrp.active:hover,
.darkdrp.active {
  border-radius: 4px 4px 0 0;
  background-color: #383a44;
  border-color: #131619;
}
.dropdown .dropdown-menu,
.dropdown .dropdown-menu-multi {
  position: absolute;
  background-color: #fff;
  width: calc(100% + 2px);
  margin-left: -1px;
  left: 0;
  margin-top: 0px;
  box-shadow: 0px 5px 3px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0 1px 4px 4px;
  overflow: hidden;
  display: none;
  max-height: 338px;
  overflow-y: auto;
  z-index: 999;
  border-right: 1px solid #c6d2d8;
  border-left: 1px solid #c6d2d8;
  border-bottom: 1px solid #c6d2d8;
  margin-left: -1px;
  padding: 0px 0px 10px 0px;
  list-style: none;
}
.dropdown .dropdown-menu li,
.dropdown .dropdown-menu-multi li {
  padding: 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  font-size: 13px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.dropdown .dropdown-menu li:hover,
.dropdown .dropdown-menu-multi li:hover {
  background-color: rgba(255, 255, 255, 0.04);
}
.dropdown .dropdown-menu li:active,
.dropdown .dropdown-menu-multi li:active {
  background-color: #333;
}
.flp-top-drop {
  border: 1px solid #c5d2d8;
  background-color: transparent;
  position: relative;
  top: 2px;
  &:hover {
    border: 1px solid #8fc2d9;
  }
}
.drop-light {
  background-color: #f4f4f4;
  color: #333;
  border: 1px solid #ddd;
  .dropdown-menu {
    background-color: #f4f4f4;
    box-shadow: 0px 1px 1px 1px rgb(0 0 0 / 15%);
    li {
      &:hover {
        background-color: #ddd;
      }
    }
  }
}
.btn-wraps-txt {
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}
.drop-single-show {
  height: auto;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.3);
  overflow: visible;
}
.drop-single-content {
  margin-top: 0px;
  font-size: 12px;
  letter-spacing: 0.03em;
  width: calc(100% + 2px);
  margin-left: -1px;
  padding: 4px 0px 1px 0px;
  z-index: 999;
  min-width: 160px;
  position: absolute;
  background: rgb(248 248 250 / 98%);
  border-radius: 0px 0px 4px 4px;
  box-shadow: 0px 5px 3px 1px rgba(0, 0, 0, 0.1);
  display: none;
  border-left: 1px solid #c5d2d8;
  border-right: 1px solid #c5d2d8;
  border-bottom: 1px solid #c5d2d8;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.io-drop {
  padding: 1px 0px;
  background-color: #fff;
  background-color: transparent;
  border: 1px solid #c5d2d8;
}
.drop-single-content-show {
  display: block;
}
.drop-single-option {
  border-bottom: 0px solid #eee;
  padding-bottom: 20px;
  padding-top: 20px;
}
.drp-itm {
  padding: 10px 17px;
  text-decoration: none;
  display: block;
  font-size: 13px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  &:hover {
    color: inherit;
    background-color: rgba(255, 255, 255, 0.04);
  }
}
.mdrp-sub {
  padding: 8px 9px 8px 18px !important;
  background-color: rgba(0, 0, 0, 0.05);
  color: var(--primarydark2);
  font-size: 12px;
}
.drp-active {
  .mdrp-sub {
    display: block;
  }
}
.drop-ck {
  float: right;
  color: var(--primarycolor);
  border: 0px;
  box-shadow: inset 0px 0px 1px 1px #2aa4af70;
  padding: 2px;
  position: relative;
  top: 2px;
  background: transparent;
  font-size: 13px;
  border-radius: 30px;
}
.dropdown-menu-multi {
  .drop-ck {
    float: none;
    color: var(--primarycolor);
    border: 0px;
    box-shadow: inset 0px 0px 1px 1px #9b9b9b70;
    padding: 3px;
    position: relative;
    top: 2px;
    background: transparent;
    font-size: 12px;
    border-radius: 30px;
    display: inline-block;
    margin-right: 10px;
    width: 16px;
    height: 16px;
  }
}
.fdrop-label {
  display: inline-block;
  font-size: 0.82em;
  font-weight: 500;
  position: absolute;
  top: -10px;
  background-color: #ffffff;
  padding: 0px 4px;
  margin-left: -4px;
  color: #969caf;
}
.drp-itm-arw {
  font-size: 16px;
  position: absolute;
  right: 10px;
  margin-top: 2px;
}
.check-off {
  color: #ffffff;
}
.mdrp-sub-itms {
  display: none;
}
.hide {
  display: none !important;
}
.brand-arw {
  float: right;
  pointer-events: none;
  transform: rotate(0deg);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 18px;
  position: relative;
  top: 1px;
  margin-right: 5px;
}
.acct-drop-name {
  padding: 6px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  font-weight: 600;
  color: var(--primarydark2);
  &.sub-brand {
    padding: 3px 10px;
    border-bottom: 0px;
    color: #626c83;
  }
  &:hover {
    color: var(--primarycolor);
  }
  &.dp-tvi {
    &.active {
      color: var(--primarycolor);
      font-weight: 600;
      &::before {
        content: '';
        display: inline-block;
        width: 14px;
        height: 30px;
        background-color: var(--primarycolor);
        position: absolute;
        margin: -6px 0px 0px -20px;
      }
    }
  }
}
.sub-brand {
  &:hover {
    color: #eee;
  }
}
.brand-grp {
  &.active {
    .brand-arw {
      transform: rotate(180deg);
    }
  }
}
.mp-nav-brand-link {
  display: inline-block;
  padding: 2px 8px;
  border: 1px solid #bec4d4;
  border-radius: 4px;
  font-weight: 600;
  &:hover {
    background-color: var(--primarycolor);
    border: 1px solid var(--primarycolor);
    color: #fff;
  }
}
.bl-signout {
  float: right;
  &:hover {
    color: var(--primarycolor);
  }
}
.mp-nav-brand-title2 {
  font-weight: 600;
  color: var(--primarydark2);
  opacity: 0.6;
}
.mp-nav-div {
  height: 13px;
  border-bottom: 1px solid rgba(0, 20, 50, 0.1);
  margin-bottom: 10px;
}
.flp-acct-drop {
  position: absolute;
  bottom: 111px;
  background-color: #f9fafc;
  height: 370px;
  left: 0px;
  width: 280px;
  padding: 20px;
  border: 1px solid rgb(30 32 33 / 10%);
  border-top: 2px solid var(--primarycolor);
  display: none;
  &.active {
    display: inline-block !important;
  }
}
.hicon {
  .flp-acct-drop {
    font-size: 0.7em;
    margin-left: -140px;
  }
  .slp-icon {
    width: 29px;
    display: none;
    position: relative;
    top: 6px;
    margin-top: -5px;
  }
}
.brand1a {
  input {
    width: 100% !important;
    padding: 10px !important;
    margin-top: 4px !important;
    border: 1px solid #c6ced8 !important;
    height: 35px;
    border-radius: 3px;
  }
  .frm-arw {
    background-color: var(--primarycolor);
    width: 40px !important;
    height: 34px;
    margin-left: -40px;
    z-index: 2;
    position: absolute;
    text-align: center;
    box-sizing: border-box;
    margin-top: 4px;
    padding: 11px;
    color: #fff;
    cursor: pointer;
    border-radius: 0px 3px 3px 0px;
  }
}
.acct-drop-names {
  margin-top: 10px;
  max-height: 230px;
  overflow: scroll;
}
.ui[class*='very thin'].left.sidebar,
.ui[class*='very thin'].right.sidebar {
  width: 60px;
}
.emb-wrap {
  max-width: 100%;
  margin: auto;
  width: 100%;
  background-color: rgb(251, 252, 254);
  position: fixed;
  bottom: 0;
  z-index: 99 !important;
}
.no-advertiser-container {
  color: #9ca1ac;
  padding: 280px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.log-out-button {
  height: 40px;
  width: 100px;
  display: flex;
  background: #fff;
  align-items: center;
  align-self: center;
}
.logout-button-container {
  margin-top: 20px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.option-btn {
  padding: 10px;
  background: #fff;
  width: 100px;
  height: 42px;
  color: #9ca1ac;
  font-family: 'Manrope', sans-serif;
  font-weight: 600;
  font-size: 14px;
  border-radius: 4px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1); /* Soft shadow */
  transition: box-shadow 0.2s ease-in-out; /* Smooth effect */
}

.option-btn:hover {
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2); /* Slightly stronger on hover */
}
</style>
