<template>
  <div class="radio-tab-group-wrap">
    <b-radio-tab
      v-for="(item, i) in group"
      :key="i"
      :text="item.text"
      :is-active="item.active"
      :is-disabled="isMniAccount ? true : item.disabled"
      :info-text="item.infoText"
      :hide-tooltip-icon="item.hideTooltipIcon"
      @click="handleActive"
    />
  </div>
</template>

<script>
export default {
  components: {
    BRadioTab: () =>
      import(/* webpackChunkName: "b-radio-tab" */ '@/components/elements/b-radio-tab.vue'),
  },
  props: {
    initialGroup: {
      type: Array,
      required: false,
      default: () => [{ text: 'test', active: false }],
    },
    isMniAccount: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      activeIndex: 0,
      group: JSON.parse(JSON.stringify(this.initialGroup)),
    };
  },
  watch: {
    initialGroup(n) {
      this.group = JSON.parse(JSON.stringify(n));
    },
  },
  methods: {
    handleActive(text) {
      this.group.forEach((item) => {
        item.active = text === item.text;
      });

      this.$emit('update', this.group);
    },
  },
};
</script>

<style lang="scss" scoped>
.radio-tab-group-wrap {
  &:not(:first-child) {
    // margin-left: 10px;
  }
}
</style>
