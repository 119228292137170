<template>
  <div
    :style="
      isNewReportBuilder ? { 'z-index': 10, margin: '0px' } : { 'z-index': 10, margin: '0px 10px' }
    "
    :class="{ disabled }"
  >
    <transition name="expand">
      <div v-if="open" :class="showOnSavedReports ? 'email-alert-on-saved-rep' : 'email-alert'">
        <span>Report Run History</span>
        <span class="close-btn" data-tool-tip="Close" @click="open = false">
          <font-awesome-icon :icon="['far', 'times']" class="check-on checkbox-active" />
        </span>

        <div v-if="error" class="error">{{ error }}</div>

        <div class="table-wrap no-wrap-css" style="margin-top: 5px;">
          <div class="parent-wrap">
            <div class="table-scroll my-saved-reports-container">
              <table class="tbl tbl-export">
                <thead class="header-top">
                  <tr>
                    <th
                      v-for="(header, i) in headers"
                      :key="i"
                      :class="[
                        'headers',
                        { 'sticky-column sticky-column-header': headers[0].isSticky },
                      ]"
                    >
                      <span>{{ header.name }}</span>
                    </th>
                  </tr>
                </thead>
                <b-loading-spinner v-if="loading" class="txt-center-full" />
                <tbody v-else-if="rows.length && !loading" class="body-half-screen">
                  <tr v-for="(item, i) in rows" :id="i" :key="i">
                    <td class="saved-reports-cell">
                      <div class="tooltip-container">{{ item.createdAt }}</div>
                    </td>
                    <td class="saved-reports-cell">
                      <div class="tooltip-container">{{ item.status }}</div>
                    </td>
                  </tr>
                </tbody>
                <div v-else class="txt-center-full" style="margin-top: 13px;">
                  Pending
                </div>
              </table>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import config from '@/config';
import axios from 'axios';
import moment from 'moment';
import { isBlank } from 'adready-api/helpers/common';
import { get } from 'vuex-pathify';

const headers = [{ name: 'Date' }, { name: 'Status' }];

export default {
  components: {
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '@/components/elements/b-loading-spinner2.vue'
      ),
  },
  props: {
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    externalOpen: {
      required: false,
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    showOnSavedReports: {
      type: Boolean,
      required: false,
      default: false,
    },
    isNewReportBuilder: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
      email: '',
      errorMsg: null,
      sendingEmail: false,
      sendLabel: 'SEND',

      rows: [],
      loading: false,
    };
  },
  computed: {
    user: get('common/currentUser'),
    error() {
      if (!isBlank(this.errorMsg)) {
        return this.errorMsg;
      }
      return null;
    },
    headers() {
      return headers;
    },
  },
  watch: {
    externalOpen(n) {
      this.open = n;
      if (!n) {
        this.email = '';
      }
    },
  },
  mounted() {
    window.addEventListener('click', this.onClickOutside, true);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.onClickOutside, true);
  },
  methods: {
    onClickOutside(e) {
      if (!this.$el.contains(e.target)) {
        this.open = false;
      }
    },
    openClosePopup(item) {
      this.rows = [];
      this.open = !this.open;
      this.loading = true;

      const url = `${config.ADREADY_URL}/api/ac/advertisers/${item.advertiserId}/reportTemplate/${item.id}/reportGenerationLogs`;
      const apiCall = axios.get(url, { withCredentials: true });

      apiCall
        .then((response) => {
          if (response?.data?.status === 200) {
            this.rows = response.data.result.content.map((r) => ({
              ...r,
              createdAt: r.createdAt ? moment(r.createdAt).format('MM-DD-YYYY HH:mm:ss') : '',
            }));
            this.loading = false;
          }
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
          this.rows = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.errorBox {
  border: 1px solid #fc4242 !important;
}
.error {
  padding: 10px;
  font-size: 13px;
  color: rgba(252, 66, 66);
  letter-spacing: 0.9px;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.close-btn {
  float: right;
}
.ex-dl-btn {
  color: #303335;
}
.expand-enter-active,
.expand-leave-active {
  -webkit-transition: top 0.3s ease, opacity 0.3s ease;
  -moz-transition: top 0.3s ease, opacity 0.3s ease;
  -ms-transition: top 0.3s ease, opacity 0.3s ease;
  -o-transition: top 0.3s ease, opacity 0.3s ease;
  transition: top 0.3s ease, opacity 0.3s ease;
}

.expand-enter,
.expand-leave-to {
  top: 100% !important;
  opacity: 0 !important;
}

.progress100 {
  width: 100% !important;
}

svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  font-size: inherit;
  vertical-align: -0.125em;
}
.svg-inline--fa.fa-w-12 {
  width: 0.75em;
}

@media screen {
  * {
    box-sizing: border-box;
  }
  * {
    outline: 0;
  }
  .email-alert {
    position: absolute;
    top: 21%;
    left: 79%;
    width: 300px;
    padding: 16px 13px;
    color: #fff;
    // text-align: center;
    background-color: rgba(29, 33, 37, 0.95);
    border-radius: 5px;
    transform: translateX(-20%);
    z-index: 10;

    .success {
      font-size: 13px;
      color: rgba(107, 207, 24);
    }
    .fail {
      font-size: 13px;
      color: rgba(252, 66, 66);
    }

    input[type='email'] {
      width: 230px;
      margin: 0.5rem 0;
      font-size: 14px;
      line-height: 33px;
      border-radius: 0.15rem;
    }
  }
  .email-alert-on-saved-rep {
    position: absolute;
    top: 21%;
    left: 29%;
    width: 300px;
    padding: 16px 30px;
    color: #fff;
    // text-align: center;
    background-color: rgba(29, 33, 37, 0.95);
    border-radius: 5px;
    transform: translateX(-20%);
    z-index: 10;

    .success {
      font-size: 13px;
      color: rgba(107, 207, 24);
    }
    .fail {
      font-size: 13px;
      color: rgba(252, 66, 66);
    }

    input[type='email'] {
      width: 230px;
      margin: 0.5rem 0;
      font-size: 14px;
      line-height: 33px;
      border-radius: 0.15rem;
    }
  }
  .flp-btn {
    position: relative;
    display: inline-block;
    padding: 15px 26px;
    font-size: 12px;
    font-weight: 400;
    color: var(--buttontext) !important;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.07em;
    cursor: pointer;
    user-select: none;
    background-color: var(--primarycolor) !important;
    -webkit-border-radius: 0;
    border-radius: 3px;
    transition: all 0.6s ease;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-touch-callout: none;
  }
  .btn-size2 {
    min-width: 20px;
    padding: 11px;
    padding-left: 17px;
    font-size: 12px;
    font-weight: 600;
    &:hover {
      color: #fff !important;
      background-color: #424242 !important;
      border: none !important;
    }
  }
  .flp-btn-icon-size2 {
    position: relative;
    top: 2px;
    padding: 5px 4px 4px 12px;
    margin: 0 0 0 13px;
    font-size: 18px;
    border-left: 1px solid rgba(0, 0, 0, 0.15);
  }
  .hide {
    display: none !important;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(111, 111, 111, 0.2);
    border: 0 #fff;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #525965;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #525965;
  }
  ::-webkit-scrollbar-track {
    background: 0 0;
    border: 0 #fff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: 0 0;
  }
  ::-webkit-scrollbar-track:active {
    background: 0 0;
  }
  ::-webkit-scrollbar-corner {
    background: 0 0;
  }
}

.fa-arrow-to-bottom:before {
  content: '\f33d';
}

.alt-loader {
  position: relative;
  display: block;
  width: 100%;
  height: 4px;
  margin: 11px 0px 2px 0px;
}
.alt-loader-bg {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.1);
}
.alt-loader-progress {
  position: absolute;
  z-index: 3;
  width: 1%;
  height: 4px;
  background-color: #00abb7;
  -webkit-transition: width 1s ease;
  -moz-transition: width 1s ease;
  -ms-transition: width 1s ease;
  -o-transition: width 1s ease;
  transition: width 1s ease;
}
.fp-text-link {
  font-size: 12px;
  color: #707e8a;
  display: inline-block;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  & p {
    text-decoration: underline;
    cursor: pointer;
  }
  & p:hover {
    color: var(--primarycolor) !important;
  }
  & span {
    text-decoration: none;
    margin-left: 8px;
    color: #212325 !important;
    .advance-filter-clear-icon:hover {
      color: var(--primarycolor) !important;
    }
  }
  & span:hover {
    color: #cad1d6;
  }
}
.custom-btn {
  border-radius: 6px !important;
  padding: 7px 12px 8px !important;
}
.txt-center {
  text-align: center;
  z-index: 99 !important;
  max-width: 20px !important;
  padding-right: 100px !important;
  padding-bottom: 50px !important;
  max-height: 10px !important;
}

@media screen {
  * {
    box-sizing: border-box;
  }
  .table-wrap {
    .tbl {
      border-collapse: separate;
      thead {
        z-index: 4;
        background-color: var(--primarycolor) !important;
      }
      tr td {
        word-wrap: break-word;
        white-space: normal;
        overflow-wrap: break-word;
      }
    }
    .table-scroll {
      width: 100%;
      max-height: 36.5rem !important;
      overflow: auto;
    }
    .message-container {
      margin-bottom: 10px;
      padding: 10px;
      text-align: center;
      border-radius: 4px;
    }
    .message.success {
      background-color: #d4edda;
      color: #155724;
      border: 1px solid #c3e6cb;
    }
    .message.error {
      background-color: #f8d7da;
      color: #721c24;
      border: 1px solid #f5c6cb;
    }
  }
  table.tbl-export {
    & thead {
      padding-bottom: unset;
      border-bottom: unset;
    }
    & td.short-column.sticky-column.subRowCollapsible {
      width: 26px !important;
      overflow: unset;
    }
    & th.short-column.sticky-column-header.subRowCollapsible {
      width: 26px !important;
    }
    .sticky-column {
      z-index: 1;
      background-color: #212 429;
      &.subRowCollapsible {
        left: 0px;
      }
      &.sub-row-column-highlight {
        background-color: #191919;
      }
      &-header {
        background: var(--primarycolor);
        z-index: 5;
        &.subRowCollapsible {
          left: 0px;
        }
      }
    }
  }
  .body-half-screen {
    .relative {
      position: relative;
    }
    tr {
      position: relative;
    }
    tr td.highlightBorder {
      border-right: 2px solid var(--primarycolor);
      left: 1px;
      z-index: 3;
      height: 3em;
    }
  }
  .tooltipText {
    position: absolute;
    z-index: 999;
    width: auto;
    font-size: 12px;
    color: #222;
    pointer-events: none;
    background-color: rgba(245, 245, 250, 0.9);
    text-align: center;
    border-radius: 3px;
    transition: ease 0.3s;
    line-height: 1.2em;
    transform-origin: bottom center;
    padding: 5px;
    display: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .tooltipTextSched {
    position: absolute;
    z-index: 999;
    width: auto;
    font-size: 12px;
    color: #222;
    pointer-events: none;
    background-color: rgba(245, 245, 250, 0.9);
    text-align: left;
    border-radius: 3px;
    transition: ease 0.3s;
    line-height: 1.2em;
    transform-origin: bottom center;
    padding: 5px;
    display: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    white-space: pre-line;
  }
  .tooltipTextSched {
    position: absolute;
    z-index: 999;
    width: auto;
    font-size: 12px;
    color: #222;
    pointer-events: none;
    background-color: rgba(245, 245, 250, 0.9);
    text-align: left;
    border-radius: 3px;
    transition: ease 0.3s;
    line-height: 1.2em;
    transform-origin: bottom center;
    padding: 5px;
    display: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    white-space: pre-line;
  }
  .highlightBackgroundParent {
    background-color: #191919;
    border-bottom: none;
    > .sticky-column {
      background-color: #191919;
      &:nth-child(n + 2):nth-child(-n + 5) {
        background-color: #191919;
        &:hover {
          background-color: #373a44;
        }
      }
      &:hover {
        background-color: #373a44;
      }
    }
    &:hover {
      background-color: #373a44;
    }
  }
  .my-saved-reports-container {
    td {
      word-break: break-all;
    }
    .action-icon {
      margin-right: 10px;
    }
    .headers {
      color: white;
      vertical-align: top !important;
    }
    th {
      &:nth-child(1) {
        width: 55%;
      }
      &:nth-child(2) {
        width: 45%;
      }
    }
    tr td {
      &:nth-child(1) {
        width: 55%;
      }
      &:nth-child(2) {
        width: 45%;
      }
    }
    .action-icon {
      cursor: pointer;
    }
  }
  .txt-center {
    text-align: center;
    width: 50%;
  }
  .txt-center-full {
    text-align: center;
    width: 100%;
  }
  .ftop-icons {
    position: relative;
    display: inline-block;
    vertical-align: top;
    color: var(--primarydark2);
    cursor: pointer;
    font-size: 16px;
    margin-right: 3px;
    margin-left: 4px;

    .ttip-wrap {
      position: absolute;
      width: max-content;
      text-align: center;
      transform: translate3d(0px, 0px, 0px);
      padding-top: 0px;
      display: inline-block;
      visibility: hidden;
      opacity: 0;
      font-size: 14px;
      transition: all 0.2s ease;
      z-index: 103;
      pointer-events: none;
      left: -47px;
      top: 20px;

      .ttip {
        display: inline-block;
        position: relative;
        padding: 4px 8px;
        border-radius: 6px;
        background-color: var(--primarydark2);
        color: #fff;
        text-align: center;
        font-size: 0.8em;
        font-weight: 500;
        line-height: 1.1em;
        margin-top: 0px;
      }
      .ttip::before {
        content: '';
        display: inline-block;
        left: 50%;
        top: -5px;
        height: 0px;
        width: 0px;
        border-right: solid 6px transparent;
        border-left: solid 6px transparent;
        border-bottom: solid 6px var(--primarydark2);
        position: absolute;
        margin-left: -3px;
      }
    }
    &:hover .fa-filter {
      color: var(--primarycolor);
    }
    &:hover .ttip-wrap {
      visibility: visible;
      opacity: 1;
      transform: translate3d(0px, 6px, 0px);
    }
  }
}
.saved-reports-cell {
  word-wrap: break-word; /* Allow long words to be broken and wrap onto the next line */
  overflow: hidden; /* Hide overflow content */
  white-space: normal; /* Allow text to wrap */
}
.show-delete-template-popup {
}

.parent-wrap {
  display: flex;
  gap: 15px;
}

.header-container {
  display: flex;
  flex-direction: column;
}

.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header-top {
  border-radius: 5px;
}

.filter-input {
  width: 100%;
  margin-top: 0.5rem;
  box-sizing: border-box;
  padding: 6px 10px;
  font-size: 12px;
  border-radius: 5px;
}

.filter-icon {
  transition: transform 0.3s ease;
}

.filter-icon.rotated {
  transform: rotate(90deg);
}
</style>
