var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`${_vm.showAdvancedFilters ? 'showtopdrp' : ''} ${
      _vm.isMniReportDashboard || _vm.isAdvanceFilterInOverview
        ? 'mni_sub_filters_drop_wrap'
        : 'sub_filters_drop_wrap'
    }`,attrs:{"id":"advance_filters"}},[_c('div',{staticClass:"sub_filters_drop ease"},[_c('div',{staticClass:"sfd_right"},[_c('div',{staticClass:"pnl_itm_title",staticStyle:{"width":"calc(100% - 42px)"}},[_c('div',{staticClass:"pnl_itm_title_txt advfltr-btn active"},[_c('font-awesome-icon',{staticClass:"fa-regular fa-filter sfd_i",attrs:{"icon":['far', 'filter']}}),_vm._v("Advanced Filters ")],1),(!_vm.isAdvanceFilterLoading && _vm.isAdvanceFilterInOverview)?_c('div',{staticClass:"sfd_close",on:{"click":_vm.handleCloseAdvanceFilter}},[_c('div',{staticClass:"sfd_close_icon"},[_c('font-awesome-icon',{attrs:{"icon":['far', 'times']}})],1)]):_vm._e()]),_c('div',{staticClass:"panel_scroll_wrap filter-drop-scroll"},[_c('div',{staticClass:"sub_filters_advanced_content"},[(_vm.showCampaignFilter || _vm.showAdGroupFilter)?_c('div',{staticClass:"io-adgroup-filter-container",staticStyle:{"text-align":"left","margin":"0px 0px 30px 0px"}},[(_vm.showCampaignFilter)?_c('div',{staticClass:"advance_filters_container"},[_c('div',{staticClass:"fltr-label"},[_vm._v("Filter by "+_vm._s(_vm.labels.campaigns))]),_c('dropdown-checkbox-search',{key:"campaign",attrs:{"name":"campaign","label":"Search...","options":!_vm.isAdvanceFilterInOverview ? _vm.filters.campaignOptions : _vm.filters.ioOptions,"fancy":true,"multiple":true,"loading":_vm.isAdvanceFilterLoading && !_vm.isMniAccount,"campaign-options-changed":_vm.campaignOptionsChanged,"label-key":"value","title":"","header":"Campaigns","component-id":"ioOptionDropdown","allow-multi-select":true,"show-advanced-filters":_vm.showAdvancedFilters,"show-all":false,"value":!_vm.isAdvanceFilterInOverview
                    ? _vm.selectedFilters.campaignOptions
                      ? _vm.selectedFilters.campaignOptions
                      : []
                    : _vm.selectedFilters.ioOptions
                    ? _vm.selectedFilters.ioOptions
                    : [],"original-component":"dropdown","class-name":`advance-filter-hover-campaign ${
                    _vm.isAdvanceFilterLoading ? 'loading-dropdown' : ''
                  }`},on:{"input":(val) =>
                    _vm.setSelectedFilters(
                      !_vm.isAdvanceFilterInOverview ? 'campaignOptions' : 'ioOptions',
                      val
                    ),"selected-options":(val) =>
                    _vm.setSelectedFilters(
                      !_vm.isAdvanceFilterInOverview ? 'campaignOptions' : 'ioOptions',
                      val,
                      true
                    ),"fetch-ad-options-from-campaigns":_vm.fetchAdOptionsFromCampaigns}})],1):_vm._e(),(_vm.showAdGroupFilter)?_c('div',{staticClass:"advance_filters_container margin-left"},[_c('div',{staticClass:"fltr-label"},[_vm._v("Filter by "+_vm._s(_vm.labels.adGroup))]),_c('dropdown-checkbox-search',{key:"adGroup",staticClass:"media-select",attrs:{"name":"adGroup","label":"Search...","options":_vm.filters.adGroupOptions,"fancy":true,"multiple":true,"loading":_vm.isAdvanceFilterLoading && !_vm.isMniAccount && _vm.campaignOptionsChanged,"campaign-options-changed":_vm.campaignOptionsChanged,"label-key":"value","title":"","header":"Ad Group","component-id":"adGroupDropdowns","allow-multi-select":true,"show-advanced-filters":_vm.showAdvancedFilters,"show-all":false,"value":// selectedAdGroup
                  _vm.selectedFilters && _vm.selectedFilters.adGroupOptions
                    ? _vm.selectedFilters.adGroupOptions
                    : [],"original-component":"dropdown","class-name":`advance-filter-hover-adgroup ${
                    _vm.isAdvanceFilterLoading ? 'loading-dropdown' : ''
                  }`,"disabled":_vm.isMniAccount},on:{"input":(val) => _vm.setSelectedFilters('adGroupOptions', val),"selected-options":(val) => _vm.setSelectedFilters('adGroupOptions', val, true),"fetch-ad-options-from-campaigns":_vm.fetchAdOptionsFromCampaigns,"toggle-campaign-options-changed":_vm.toggleCampaignOptionsChanged}})],1):_vm._e()]):_vm._e(),(_vm.showMediaTypeFilter)?[_c('div',{staticClass:"fltr-label"},[_vm._v("Filter by Media Type")]),_c('div',{staticClass:"form-group hover-options-dropdown"},[_c('dropdown-checkbox-search',{key:"mediaType",attrs:{"name":"mediaType","label":"Search...","options":_vm.filters.mediaTypeOptions,"fancy":true,"multiple":true,"loading":_vm.isAdvanceFilterLoading,"label-key":"value","original-component":"autoComplete","value":_vm.selectedFilters.mediaTypeOptions ? _vm.selectedFilters.mediaTypeOptions : [],"class-name":`advance-filter-hover-mediatype ${
                    _vm.isAdvanceFilterLoading ? 'loading-dropdown' : ''
                  }`},on:{"input":(val) => _vm.setSelectedFilters('mediaTypeOptions', val)}})],1),(!_vm.isAdvanceFilterLoading)?_c('div',{staticStyle:{"height":"30px"}}):_c('div',{staticStyle:{"height":"65px"}})]:_vm._e(),(_vm.showCreativeFilter)?[_c('div',{staticClass:"fltr-label"},[_vm._v("Filter by Creative")]),(_vm.isAdvanceFilterInOverview)?_c('div',{staticClass:"form-group hover-options-dropdown"},[_c('dropdown-checkbox-search-v2',{key:"creatives",attrs:{"name":"creatives","label":"Search...","options":_vm.filters.creativeOptions,"loading":_vm.isAdvanceFilterLoading,"is-advance-filter-in-overview":_vm.isAdvanceFilterInOverview,"fancy":true,"multiple":true,"label-key":"value","original-component":"autoComplete","value":_vm.selectedFilters.creativeOptions ? _vm.selectedFilters.creativeOptions : [],"class-name":`advance-filter-hover-creatives ${
                    _vm.isAdvanceFilterLoading ? 'loading-dropdown' : ''
                  } `},on:{"input":(val) => _vm.setSelectedFilters('creativeOptions', val)}})],1):_c('div',{staticClass:"form-group hover-options-dropdown"},[_c('dropdown-checkbox-search',{key:"creatives",attrs:{"name":"creatives","label":"Search...","options":_vm.filters.creativeOptions,"loading":_vm.isAdvanceFilterLoading,"is-advance-filter-in-overview":_vm.isAdvanceFilterInOverview,"fancy":true,"multiple":true,"label-key":"value","original-component":"autoComplete","value":_vm.selectedFilters.creativeOptions ? _vm.selectedFilters.creativeOptions : [],"class-name":`advance-filter-hover-creatives ${
                    _vm.isAdvanceFilterLoading ? 'loading-dropdown' : ''
                  } `},on:{"input":(val) => _vm.setSelectedFilters('creativeOptions', val)}})],1),(!_vm.isAdvanceFilterLoading)?_c('div',{staticStyle:{"height":"30px"}}):_c('div',{staticStyle:{"height":"65px"}})]:_vm._e(),(_vm.showPublisherFilter)?[_c('div',{staticClass:"fltr-label"},[_vm._v("Filter by Publisher")]),_c('div',{staticClass:"form-group hover-options-dropdown"},[_c('dropdown-checkbox-search',{key:"publishers",attrs:{"name":"publishers","label":"Search...","options":_vm.filters.publisherOptions,"loading":_vm.isAdvanceFilterLoading,"is-advance-filter-in-overview":_vm.isAdvanceFilterInOverview,"fancy":true,"multiple":true,"label-key":"value","original-component":"autoComplete","value":_vm.selectedFilters.publisherOptions ? _vm.selectedFilters.publisherOptions : [],"class-name":`advance-filter-hover-publishers ${
                    _vm.isAdvanceFilterLoading ? 'loading-dropdown' : ''
                  } `},on:{"input":(val) => _vm.setSelectedFilters('publisherOptions', val)}})],1),(!_vm.isAdvanceFilterLoading)?_c('div',{staticStyle:{"height":"30px"}}):_c('div',{staticStyle:{"height":"65px"}})]:_vm._e(),(_vm.showAudienceFilter)?[_c('div',{staticClass:"fltr-label"},[_vm._v(_vm._s(_vm.labels.audianceFilter))]),_c('div',{staticClass:"form-group hover-options-dropdown"},[_c('dropdown-checkbox-search',{key:"audience",attrs:{"name":"audience","label":"Search...","options":_vm.filters.audienceOptions,"loading":_vm.isAdvanceFilterLoading,"is-advance-filter-in-overview":_vm.isAdvanceFilterInOverview,"fancy":true,"multiple":true,"label-key":"value","original-component":"autoComplete","value":_vm.selectedFilters.audienceOptions ? _vm.selectedFilters.audienceOptions : [],"class-name":`advance-filter-hover-audiences ${
                    _vm.isAdvanceFilterLoading ? 'loading-dropdown' : ''
                  }`},on:{"input":(val) => _vm.setSelectedFilters('audienceOptions', val)}})],1),(!_vm.isAdvanceFilterLoading)?_c('div',{staticStyle:{"height":"30px"}}):_c('div',{staticStyle:{"height":"65px"}})]:_vm._e(),(_vm.showAdFilter)?[_c('div',{staticClass:"fltr-label"},[_vm._v("Filter by Ads")]),_c('div',{staticClass:"form-group hover-options-dropdown"},[_c('dropdown-checkbox-search',{key:"creatives",attrs:{"name":"creatives","label":"Search...","options":_vm.filters.creativeOptions,"loading":_vm.isAdvanceFilterLoading,"is-advance-filter-in-overview":_vm.isAdvanceFilterInOverview,"disabled":_vm.disabledCreatives,"fancy":true,"multiple":true,"label-key":"value","original-component":"autoComplete","value":_vm.selectedFilters.creativeOptions ? _vm.selectedFilters.creativeOptions : [],"class-name":`advance-filter-hover-creatives ${
                    _vm.isAdvanceFilterLoading ? 'loading-dropdown' : ''
                  }`},on:{"input":(val) => _vm.setSelectedFilters('creativeOptions', val)}})],1),(!_vm.isAdvanceFilterLoading)?_c('div',{staticStyle:{"height":"30px"}}):_c('div',{staticStyle:{"height":"65px"}})]:_vm._e(),(_vm.showKeywordFilter)?[_c('div',{staticClass:"fltr-label"},[_vm._v("Filter by Keywords")]),_c('div',{staticClass:"form-group hover-options-dropdown"},[_c('dropdown-checkbox-search',{key:"keywords",attrs:{"name":"keywords","label":"Search...","options":_vm.filters.keywordOptions,"loading":_vm.isAdvanceFilterLoading,"disabled":_vm.disabledKeywords,"fancy":true,"multiple":true,"label-key":"value","original-component":"autoComplete","value":_vm.selectedFilters.keywordOptions ? _vm.selectedFilters.keywordOptions : [],"class-name":`advance-filter-hover-keywords ${
                    _vm.isAdvanceFilterLoading ? 'loading-dropdown' : ''
                  }`},on:{"input":(val) => _vm.setSelectedFilters('keywordOptions', val)}})],1),(!_vm.isAdvanceFilterLoading)?_c('div',{staticStyle:{"height":"30px"}}):_c('div',{staticStyle:{"height":"65px"}})]:_vm._e(),(_vm.showConversionWindowFilter)?[_c('div',{staticClass:"fltr-label"},[_vm._v("Filter by Conversion Window (In Days)")]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filters.conversionWindow),expression:"filters.conversionWindow"}],ref:"conversionWindowInput",staticClass:"flp-input input-conv",attrs:{"type":"text","name":"conversion-window"},domProps:{"value":(_vm.filters.conversionWindow)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.filters, "conversionWindow", $event.target.value)},_vm.validateFilterData]}})]),(_vm.error)?_c('div',{staticClass:"error"},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('div',{staticStyle:{"height":"30px"}})]:_vm._e()],2),_c('div',{staticClass:"sfd_footer",class:{ disabled: _vm.isAdvanceFilterInOverview && _vm.isAdvanceFilterLoading }},[_c('div',{staticClass:"mdn-btn btn-size2 sfd-apply-btn",staticStyle:{"min-width":"80px","margin-right":"8px"},on:{"click":_vm.onClickApply}},[_vm._v(" Apply ")]),_c('div',{staticClass:"mdn-btn btn-size2 sfd-cancel-btn outline",on:{"click":_vm.handleCloseAdvanceFilter}},[_vm._v(" Cancel ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }