import { render, staticRenderFns } from "./distribution.vue?vue&type=template&id=587ee47a&scoped=true"
import script from "./distribution.vue?vue&type=script&lang=js"
export * from "./distribution.vue?vue&type=script&lang=js"
import style0 from "./distribution.vue?vue&type=style&index=0&id=587ee47a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "587ee47a",
  null
  
)

export default component.exports