import { render, staticRenderFns } from "./b-radio-tab-group.vue?vue&type=template&id=38022198&scoped=true"
import script from "./b-radio-tab-group.vue?vue&type=script&lang=js"
export * from "./b-radio-tab-group.vue?vue&type=script&lang=js"
import style0 from "./b-radio-tab-group.vue?vue&type=style&index=0&id=38022198&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38022198",
  null
  
)

export default component.exports