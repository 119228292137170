<template>
  <div :class="['toggle-switch', { active: localActive }]" @click="toggle">
    <div class="toggle-circle"></div>
  </div>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      localActive: this.isActive,
    };
  },
  watch: {
    isActive(newVal) {
      this.localActive = newVal;
    },
  },
  methods: {
    toggle() {
      this.localActive = !this.localActive;
      this.$emit('update:isActive', this.localActive);
    },
  },
};
</script>

<style scoped>
.toggle-switch {
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding: 2px;
  cursor: pointer;
  transition: background 0.3s;
}

.toggle-switch.active {
  background-color: var(--primarycolor);
}

.toggle-circle {
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s;
}

.toggle-switch.active .toggle-circle {
  transform: translateX(20px);
}
</style>
